import { useQuery } from "react-apollo";
import { isDataAvailable } from "../apollo/utils/isDataAvailable";
import { ListProjectsQuery } from "../types/graphql/ListProjectsQuery";
import { LIST_PROJECTS } from "../apollo/queries/ListProjects";

export const useFetchProjects = (platformId: string, publisherId: string) => {
  const allProjectsResult = useQuery<ListProjectsQuery>(LIST_PROJECTS, {
    variables: {
      id: {
        platformId: platformId,
        publisherId: publisherId,
      },
    },
  });

  const projectsIsAvailable = isDataAvailable(allProjectsResult);

  const projects = !projectsIsAvailable ? [] : allProjectsResult.data!.listProjects.items;

  return { projects, projectsLoading: allProjectsResult.loading, projectsResult: allProjectsResult };
};
