import { useQuery } from "react-apollo";
import { ListPublishersQuery } from "../types/graphql/ListPublishersQuery";
import { LIST_PUBLISHERS } from "../apollo/queries/ListPublishers";
import { isDataAvailable } from "../apollo/utils/isDataAvailable";

export const useFetchPublishers = () => {
  const country = localStorage.getItem("country");

  const allPublishersResult = useQuery<ListPublishersQuery>(LIST_PUBLISHERS, {
    variables: {
      country,
      offset: 0,
      limit: 20,
    },
  });

  const publishersIsAvailable = isDataAvailable(allPublishersResult);

  const publishers = !publishersIsAvailable ? [] : allPublishersResult.data!.listPublishers.items;

  return { publishers, publishersLoading: allPublishersResult.loading, publishersResult: allPublishersResult };
};
