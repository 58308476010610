import { DocumentNode } from "graphql";
import { Platform } from "../../types/Platform";
import { PlatformName } from "../../types/PlatformName";
import { LIST_FACEBOOK_CONTENTS } from "../queries/ListFacebookContents";
import { LIST_INSTAGRAM_CONTENTS } from "../queries/ListInstagramContents";
import { LIST_FACEBOOK_GROUP_CONTENTS } from "../queries/ListFacebookGroupContents";

export const getListContentQuery = (platform: Platform): DocumentNode => {
  const queries = {
    [Platform.FB]: LIST_FACEBOOK_CONTENTS,
    [Platform.IG]: LIST_INSTAGRAM_CONTENTS,
    [Platform.FBG]: LIST_FACEBOOK_GROUP_CONTENTS,
  };

  return queries[platform];
};

export const getPlatformListContentsName = (platform: Platform): string => {
  return `list${PlatformName[platform]}Contents`;
};
