import * as React from "react";
import { PostWrapper, Header, MiniChipWrapper, ArrowIcon, DetailsContainer } from "./styles";
import { SocialContent } from "../../../types/SocialContent";
import { Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { ButtonStyled } from "../../ui/ButtonStyled";
import { MiniChip } from "../../ui/MiniChip";
import { isFbGroupContent, isIgContent, buildSocialContentInfo } from "../../../utils/buildSocialContentInfo";
import { PostImg } from "../../ui/sharedStyles";
import { getPostImage } from "../../../utils/getPostImage";
import { buildWrapText } from "../../../utils/buildWrapText";

const placeholderPost = require("../../../assets/post/placeholderPost.jpg");

interface Props {
  post: SocialContent;
  wrapperId: string;
  isOpen: boolean;
  handleOpen: (open: boolean) => void;
}

const PostDetails: React.FC<Props> = ({ post, wrapperId, isOpen, handleOpen }) => {
  const isIGpost = isIgContent(post);
  const isFBGpost = isFbGroupContent(post);
  const isIgtvPost = isIGpost && post.type === "IGTV";
  const imagePost = getPostImage(post);

  const { title, caption, permalink, messageFbGroup, commentsCount } = buildSocialContentInfo(post);

  const fbgMessagePost = buildWrapText(messageFbGroup);

  return (
    <PostWrapper id={wrapperId} open={isOpen}>
      <div style={{ height: "100%" }}>
        <ArrowIcon open={isOpen} onClick={() => handleOpen(!isOpen)} iconName="arrow-up" />
        <Header style={{ paddingRight: 5, marginBottom: 0, marginTop: 10 }}>
          <Typography variantName="text" textColor={Colors.PURPLE} style={{ fontWeight: 500 }}>
            Post details
          </Typography>
          <a href={permalink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <ButtonStyled
              icon={{ iconName: "open-link", position: "left", iconColor: Colors.GRIGINO }}
              style={{ position: "relative", left: -7, alignSelf: "flex-start" }}
            >
              Open post
            </ButtonStyled>
          </a>
        </Header>
        <MiniChipWrapper>
          <MiniChip type="comments" value={commentsCount} />
        </MiniChipWrapper>
        <DetailsContainer>
          {isFBGpost && messageFbGroup && (
            <>
              {fbgMessagePost.map((m, i) => (
                <Typography key={i} variantName="text" style={{ fontSize: 12 }} textColor={Colors.GRIGINO}>
                  {m}
                </Typography>
              ))}
            </>
          )}
          {!isFBGpost && title && (
            <Typography variantName="text" style={{ fontSize: 12, marginBottom: 5 }}>
              {title}
            </Typography>
          )}
          {!isFBGpost && caption && (
            <Typography variantName="text" textColor={Colors.GRIGINO} style={{ fontSize: 12 }}>
              {caption}
            </Typography>
          )}
          {imagePost && (
            <div style={{ paddingTop: 20 }}>
              {!isFBGpost && (
                <PostImg
                  src={imagePost || placeholderPost}
                  style={{ height: isIgtvPost ? 200 : "auto", width: "100%", margin: 0 }}
                />
              )}
              {isFBGpost && (
                <PostImg
                  src={imagePost || placeholderPost}
                  style={{ height: "auto", width: "100%", margin: 0 }}
                  onError={(e) => (e.currentTarget.src = placeholderPost)}
                />
              )}
            </div>
          )}
        </DetailsContainer>
      </div>
    </PostWrapper>
  );
};

export { PostDetails };
