import * as React from "react";
import styled from "styled-components";
import { Typography } from "@freeda/react-components";
import { BackButton } from "../../../ui/BackButton";

const MobileFilterHeaderWrapper = styled.div`
  color: #ffffff;
  font-size: 20px;
  background-color: ${props => props.theme.primary};
  padding: 9px;
  display: grid;
  align-items: center;
  grid-template-columns: 33.33% 33.33% 33.33%;
  position: fixed;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
`;

interface Props {
  onClose: () => void;
}

const MobileFilterHeader: React.FC<Props> = ({ onClose }) => (
  <MobileFilterHeaderWrapper>
    <BackButton onClick={onClose} iconColor="#ffffff" />
    <Typography variantName="subtitle" style={{ justifySelf: "center" }}>
      Filter
    </Typography>
  </MobileFilterHeaderWrapper>
);

export { MobileFilterHeader };
