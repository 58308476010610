export enum Platform {
  FB = "FB",
  IG = "IG",
  FBG = "FBG",
}

export const buildPlatformName = (platform: Platform) => {
  const platformName = {
    [Platform.FB]: "Facebook",
    [Platform.FBG]: "Facebook Group",
    [Platform.IG]: "Instagram",
  };

  return platformName[platform];
};
