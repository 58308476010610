import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { getPlatformIcon } from "../../../../utils/getPlatformIcon";
import { Colors } from "@freeda/react-components/lib/theme";
import { buildSocialContentInfo, isFbGroupContent, isIgContent } from "../../../../utils/buildSocialContentInfo";
import { Details } from "../styles";
import { PlatformIcon, HeaderPanel, PostImg, Caption, LateralPreviewWrapper } from "../../../ui/sharedStyles";
import { ButtonStyled } from "../../../ui/ButtonStyled";
import { Typography, DividerSection } from "@freeda/react-components";
import { MiniChip } from "../../../ui/MiniChip";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  ListCommentsByContentQuery,
  ListCommentsByContentQueryVariables,
} from "../../../../types/graphql/ListCommentsByContentQuery";
import { LIST_COMMENTS_BY_CONTENT } from "../../../../apollo/queries/ListCommentsByContent";
import { SocialContent } from "../../../../types/SocialContent";
import { PostCommentsPreview } from "./PostCommentsPreview";
import { buildWrapText } from "../../../../utils/buildWrapText";
import { MessageWrapper } from "../../CommentsModerationProcess/styles";
import { PostsFilterContext } from "../../../../providers/PostsFilterProvider";
import { PreviewModerationPlaceholder } from "../../../ui/Placeholder/PreviewModerationPlaceholder";
import { CommentsPlaceholder } from "../../../ui/Placeholder/CommentsPlaceholder";
import { LIMIT_COMMENTS_POST_PREVIEW, LIMIT_CHILDREN_LIST_COMMENTS } from "../../../../utils/limitVariablesQueries";
import { Roles } from "../../../Permission";
import { getPostImage } from "../../../../utils/getPostImage";

const placeholderPost = require("../../../../assets/post/placeholderPost.jpg");

interface Props {
  selectedPost: SocialContent | null;
  onOpenPostPanel: () => void;
}

const PostPreview: React.FC<Props> = ({ selectedPost, onOpenPostPanel }) => {
  const roles = localStorage.getItem("roles") as Roles | null;

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 950 });

  const { publisherId: publisherIdFilter } = React.useContext(PostsFilterContext);

  const [getListComments, listCommentsResult] = useLazyQuery<
    ListCommentsByContentQuery,
    ListCommentsByContentQueryVariables
  >(LIST_COMMENTS_BY_CONTENT(publisherIdFilter.platformId));

  React.useEffect(() => {
    if (selectedPost) {
      const { platformId, contentId, publisherId } = selectedPost.id;

      getListComments({
        variables: {
          contentId: {
            platformId,
            publisherId,
            contentId,
          },
          offset: 0,
          limit: LIMIT_COMMENTS_POST_PREVIEW,
          order: "DESC",
          topLevelOnly: true,
          offsetChildren: 0,
          limitChildren: LIMIT_CHILDREN_LIST_COMMENTS,
        },
      });
    }
  }, [selectedPost, getListComments]);

  if (!selectedPost) {
    return <PreviewModerationPlaceholder width="45%" />;
  }

  const loadingFetch = listCommentsResult.loading;

  const listComments = listCommentsResult.data ? listCommentsResult.data.listCommentsByContent.items : [];

  const { title, caption, messageFbGroup, mediaUrl, permalink, id, commentsCount } = buildSocialContentInfo(
    selectedPost
  );

  const fbgMessagePost = buildWrapText(messageFbGroup);

  const isFBGpost = isFbGroupContent(selectedPost);
  const isIGpost = isIgContent(selectedPost);
  const isIgtvPost = isIGpost && selectedPost.type === "IGTV";

  const imagePost = getPostImage(selectedPost);

  const listCommentsComponent = () => {
    if (loadingFetch) {
      return (
        <div style={{ padding: "0px 20px", display: "flex", flexDirection: "column" }}>
          <CommentsPlaceholder />
        </div>
      );
    }

    if (!loadingFetch && listComments.length > 0) {
      return <PostCommentsPreview comments={listComments} />;
    }

    if (!loadingFetch && listComments.length === 0) {
      return (
        <div style={{ padding: "40px 20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography
            variantName="label"
            textColor={Colors.GRIGINO}
            style={{ userSelect: "none", whiteSpace: "normal", textAlign: "center" }}
          >
            <>
              Mmh... I'm searching the comments but not found nothing...
              <span role="img" aria-label="Sad emoji">
                🥺
              </span>
            </>
          </Typography>
        </div>
      );
    }

    return null;
  };

  const writerRole = roles === Roles.MODERATOR || roles === Roles.LABELER;

  return (
    <LateralPreviewWrapper style={{ width: isDesktopOrLaptop ? "45%" : "100%" }}>
      <div
        style={{
          background: "#ffffff",
          height: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.05)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderPanel>
          <a href={permalink} target="__blank" style={{ textDecoration: "none" }}>
            <ButtonStyled
              style={{ justifySelf: "flex-start" }}
              icon={{ iconName: "open-link", position: "left", iconColor: Colors.GRIGINO }}
            >
              Open post
            </ButtonStyled>
          </a>
          <PlatformIcon src={getPlatformIcon(id.platformId)} alt="" style={{ justifySelf: "center" }} />
          <ButtonStyled
            style={{ justifySelf: "flex-end" }}
            icon={{ iconName: writerRole ? "comments" : "expand", position: "right", iconColor: Colors.GRIGINO }}
            onClick={onOpenPostPanel}
            disabled={listComments.length === 0}
          >
            <> {writerRole ? "Categorize" : "Fullscreen mode"}</>
          </ButtonStyled>
        </HeaderPanel>
        <Details>
          {!isFBGpost && (
            <PostImg
              size="large"
              src={imagePost || placeholderPost}
              style={{ margin: 0, alignSelf: "flex-start", height: isIgtvPost ? 120 : "" }}
            />
          )}
          {isFBGpost && imagePost && (
            <PostImg
              size="large"
              src={imagePost}
              style={{ margin: 0, alignSelf: "flex-start" }}
              onError={(e) => (e.currentTarget.src = placeholderPost)}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: isFBGpost && !mediaUrl ? 0 : 15,
              width: isFBGpost && !mediaUrl ? "100%" : "calc(100% - 100px)",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "grid" }}>
              {isFBGpost && (
                <MessageWrapper style={{ maxHeight: 68, paddingRight: 10 }}>
                  {fbgMessagePost.map((m, i) => (
                    <Typography key={i} variantName="text" style={{ fontSize: 12 }} textColor={Colors.GRIGINO}>
                      {m}
                    </Typography>
                  ))}
                </MessageWrapper>
              )}
              {!isFBGpost && title && (
                <Typography
                  variantName="text"
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {title}
                </Typography>
              )}
              {!isFBGpost && caption && (
                <Caption variantName="text" textColor={Colors.GRIGINO} style={{ fontSize: 12, paddingRight: 10 }}>
                  {caption}
                </Caption>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 10 }}>
              <MiniChip type="comments" value={commentsCount} />
            </div>
          </div>
        </Details>
        <div style={{ padding: "0px 20px", height: "100%", overflow: "hidden" }}>
          <DividerSection style={{ margin: "15px 0px", marginBottom: 0, position: "relative", zIndex: 1 }} />
          {listCommentsComponent()}
        </div>
      </div>
    </LateralPreviewWrapper>
  );
};

export { PostPreview };
