import * as React from "react";
import { includes } from "lodash";

export enum Roles {
  READER = "cm_r_reader",
  MODERATOR = "cm_r_moderator",
  LABELER = "cm_r_labeler",
}

const Permission: React.FC<{ roles: Array<Roles> }> = ({ roles, children }) => {
  const rolesJWT = localStorage.getItem("roles") as Roles | null;

  let availableRoles: Array<Roles> = [];
  if (rolesJWT) {
    availableRoles = rolesJWT.split(",") as Array<Roles>;
  }

  const allowedRoles = roles.filter((r) => includes(availableRoles, r));
  if (allowedRoles.length > 0) {
    return <>{children}</>;
  }

  return null;
};

export { Permission };
