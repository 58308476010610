import styled, { css } from "styled-components";
import { Typography } from "@freeda/react-components";
import { lightScrollbarVertical, scrollbarVertical } from "./ScrollbarStyles";
import { zIndex } from "../../utils/zIndex";

const PlatformIcon = styled.img<{ inCard?: boolean }>`
  width: 25px;
  height: 25px;
  user-select: none;

  ${(props) =>
    props.inCard &&
    css`
      width: 23px;
      height: 23px;
      position: absolute;
      right: 8px;
      top: 8px;
    `}
`;

const FieldWrapper = styled.div<{ basis?: string; withoutMarginRight?: boolean }>`
  flex-basis: ${(props) => (props.basis ? props.basis : "100%")};
  margin-right: ${(props) => (props.withoutMarginRight ? "0px" : "30px")};

  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

const HorizDivider = styled.hr`
  height: 25px;
  width: 1px;
  border: none;
  border-right: 1px solid ${(props) => props.theme.secondary};
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  background: #ffffff;
  z-index: ${zIndex.LateralPanel};
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
`;

const HeaderPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 10px 15px;
  z-index: 1;
  background: white;
  min-height: 55px;
`;

const HeaderPage = styled(HeaderPanel)`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 0px;
  padding: 0px 15px;
`;

const WrapperSection = styled.div`
  height: 100%;
  position: relative;
  z-index: -1;
`;

const LeftSection = styled.div`
  height: calc(100vh - 55px);
  width: calc(100vw - 350px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RightSection = styled.div`
  background: #f3f3f3;
  bottom: 0px;
  right: 0px;
  position: absolute;
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px -1px;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${scrollbarVertical}
`;

const PostImg = styled.img<{ size?: "small" | "medium" | "large" | "x-large" }>`
  width: 50px;
  height: auto;
  margin: 0px 15px;
  user-select: none;
  object-fit: contain;

  ${(props) =>
    props.size === "small" &&
    css`
      width: 50px;
      margin: 0px 15px;
    `}
  ${(props) =>
    props.size === "medium" &&
    css`
      width: 80px;
      margin-right: 15px;
    `}
  ${(props) =>
    props.size === "large" &&
    css`
      width: 100px;
      margin-right: 15px;
    `}
  ${(props) =>
    props.size === "x-large" &&
    css`
      width: 210px;
    `}
`;

const Caption = styled(Typography)`
  max-height: 50px;
  overflow-y: overlay;
  text-overflow: ellipsis;

  ${lightScrollbarVertical};
`;

const WrapperContainer = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 768px) {
    height: calc(100vh - 55px);
  }
`;

const LateralPreviewWrapper = styled.div`
  padding-top: 75px;
  padding-left: 20px;
  height: calc(100vh - 15px);
`;

const ItemWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export {
  PlatformIcon,
  FieldWrapper,
  HorizDivider,
  HeaderPanel,
  HeaderPage,
  WrapperSection,
  LeftSection,
  RightSection,
  PostImg,
  Caption,
  PageWrapper,
  WrapperContainer,
  LateralPreviewWrapper,
  ItemWrapper,
};
