import gql from "graphql-tag";
import { FACEBOOK_COMMENT } from "../fragments/comment/facebookComment";
import { FACEBOOK_CONTENT } from "../fragments/content/facebookContent";

const LIST_FACEBOOK_CONTENTS = gql`
  query listFacebookContents(
    $publisherId: PublisherIdInput!
    $offset: Int!
    $limit: Int!
    $since: DateTime
    $until: DateTime
    $offsetComments: Int!
    $limitComments: Int!
  ) {
    listFacebookContents(publisherId: $publisherId, offset: $offset, limit: $limit, since: $since, until: $until) {
      items {
        ...facebookContent
        comments(offset: $offsetComments, limit: $limitComments, order: DESC) {
          items {
            ...facebookComment
          }
          pageInfo {
            totalCount
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${FACEBOOK_COMMENT}
  ${FACEBOOK_CONTENT}
`;

export { LIST_FACEBOOK_CONTENTS };
