import * as React from "react";
import { useWindowSize } from "./useWindowSize";

const useFilterMobile = () => {
  const [showFilterMobile, setShowFilterMobile] = React.useState(false);

  const maxWidth = useWindowSize().windowWidth;

  const toggleShowFilter = () => setShowFilterMobile(prevShowFilterMobile => !prevShowFilterMobile);

  const onCloseFilterMobile = () => setShowFilterMobile(false);

  React.useEffect(() => {
    if (maxWidth >= 768) {
      setShowFilterMobile(false);
    }
  }, [maxWidth]);

  return { showFilterMobile, toggleShowFilter, onCloseFilterMobile };
};

export { useFilterMobile };
