import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";
import { Typography, ButtonIcon, Select, DividerSection, CheckBox } from "@freeda/react-components";
import { CommentId } from "../../../types/CommentId";
import { SnackbarContext } from "../../../providers/SnackbarProvider";
import { Label } from "../../../types/Label";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { SocialComment } from "../../../types/SocialComment";
import { CLASSIFY_COMMENT } from "../../../apollo/mutations/ClassifyComment";
import { useFetchProjects } from "../../../hooks/useFetchProjects";
import { GET_COMMENT } from "../../../apollo/queries/GetComment";
import { isDataAvailable } from "../../../apollo/utils/isDataAvailable";
import { usePreviousData } from "../../../hooks/usePreviousData";
import { capitalize, difference } from "lodash";
import { labellingCommentSuccess, labellingCommentError } from "../../../messages";
import { buildDropdownLabels } from "./utils";
import { useMediaQuery } from "react-responsive";
import { LabellingPlaceholder } from "../../ui/Placeholder/LabellingPlaceholder";
import { LabellingWrapper, SentimentGrid, Header, LabellingPanelContainer, WrapperFields } from "./styles";
import { Sentiment, SentimentClassification } from "../../../types/Sentiment";
import { ClassifyCommentVariables } from "../../../types/graphql/ClassifyComment";
import { SentimentButton } from "./SentimentButton";
import { FieldWrapper } from "../../ui/sharedStyles";
import { CommentType } from "../../../types/CommentType";

interface Props {
  selectedCommentId: CommentId;
  openPostPanel: boolean;
}

const LateralLabellingPanel: React.FC<Props> = ({ selectedCommentId, openPostPanel }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { openSnackbar } = React.useContext(SnackbarContext);

  const [sentiment, setSentiment] = React.useState<Sentiment | null>(null);
  const [sentimentClassification, setSentimentClassification] = React.useState<string | null>(null);
  const [genericLabels, setGenericLabels] = React.useState<Array<Label>>([]);
  const [brandQuoted, setBrandQuoted] = React.useState<boolean | null>(null);
  const [commentType, setCommentType] = React.useState<CommentType | null>(null);

  const [disableSaveButton, setDisableSaveButton] = React.useState(true);

  const { platformId, publisherId } = selectedCommentId;

  const [classifyComment, { loading }] = useMutation<{ comment: SocialComment }, ClassifyCommentVariables>(
    CLASSIFY_COMMENT(platformId)
  );

  const { projects, projectsLoading, projectsResult } = useFetchProjects(platformId, publisherId);

  const commentResult = useQuery<{ getComment: SocialComment }>(GET_COMMENT(platformId), {
    variables: {
      id: {
        ...selectedCommentId,
      },
    },
  });

  const projectsIsAvailable = isDataAvailable(projectsResult);
  const commentIsAvailable = isDataAvailable(commentResult);
  const dataIsAvailable = !projectsLoading && projectsIsAvailable && commentIsAvailable && commentResult.data;

  const prevCommentId = usePreviousData(selectedCommentId);

  React.useEffect(() => {
    if (prevCommentId !== selectedCommentId) {
      setSentiment(null);
      setSentimentClassification(null);
      setGenericLabels([]);
      setBrandQuoted(null);
      setCommentType(null);
      setDisableSaveButton(true);
    }
  }, [prevCommentId, selectedCommentId]);

  React.useEffect(() => {
    const comment = commentResult.data?.getComment;
    if (dataIsAvailable && comment) {
      const labels = comment.labels;
      const { sentiment, sentiment_classification, brand_quoted, comment_type } = comment;

      setSentiment(sentiment);
      setSentimentClassification(sentiment_classification);
      setBrandQuoted(brand_quoted);
      setCommentType(comment_type);

      if (labels && labels.length > 0) {
        const newGenericLabels = labels.filter((l) => projects.map((p) => p.project).includes(l.project));
        setGenericLabels(newGenericLabels);
      }
    }
  }, [dataIsAvailable, projects, commentResult]);

  React.useEffect(() => {
    const stringifyProjectLabel = (l: Label) => `${l.project.replace(" ", "")}-${l.label}`;

    const comment = commentResult.data?.getComment;
    const labels = comment?.labels;

    if (comment) {
      const { sentiment: initialSentimet, sentiment_classification, brand_quoted, comment_type } = comment;
      const sentimentChanged = initialSentimet !== sentiment;
      const brandQuotedChanged = brand_quoted !== brandQuoted;
      const classificationChanged = sentiment_classification !== sentimentClassification;
      const commentTypeChanged = comment_type !== commentType;

      let labelsAreChanged: boolean = false;
      if (labels) {
        const oldLabels = labels.map(stringifyProjectLabel);
        const newLabels = genericLabels.filter((l) => l.label.length);
        const stringifyNewLabels = newLabels.map(stringifyProjectLabel);

        const removedLabels = difference(oldLabels, stringifyNewLabels);
        const addedLabels = difference(stringifyNewLabels, oldLabels);

        labelsAreChanged = [...removedLabels, ...addedLabels].length > 0;
      }

      setDisableSaveButton(
        !(labelsAreChanged || sentimentChanged || brandQuotedChanged || classificationChanged || commentTypeChanged)
      );
    }
  }, [commentResult, genericLabels, sentiment, brandQuoted, sentimentClassification, commentType]);

  const onChangeSentimentValue = (sentiment: Sentiment) => setSentiment(capitalize(sentiment) as Sentiment);

  const onChangeSentimentClassification = (classification: string | null) => setSentimentClassification(classification);

  const onChangeCommentType = (commentType: CommentType | null) => setCommentType(commentType);

  const onChangeSelectedLabel = (project: string, label: string | null) => {
    const exists = genericLabels.find((l) => l.project === project);
    const updatedGenericLabels = genericLabels.map((s) => {
      if (s.project === project) {
        return {
          ...s,
          label: label ? label : "",
        };
      }
      return s;
    });
    setGenericLabels((values) => (exists ? updatedGenericLabels : [...values, { project, label: label ? label : "" }]));
  };

  if (!dataIsAvailable) {
    return <LabellingPlaceholder />;
  }

  const projectContainsGenericLabels = projects.length > 0;

  const buildOptions = (option: string) => ({
    value: capitalize(option),
    label: capitalize(option),
  });

  const sentimentClassificationOptions = Object.values(SentimentClassification).map(buildOptions);
  const commentTypeOptions = Object.values(CommentType).map(buildOptions);

  return (
    <LabellingPanelContainer openPostPanel={openPostPanel}>
      <Header>
        <Typography variantName="text" textColor={Colors.PURPLE} style={{ fontWeight: 500 }}>
          Labelling & tagging
        </Typography>
        <ButtonIcon
          iconName="save"
          disabled={disableSaveButton}
          loading={loading}
          iconColor={Colors.ROSSINO}
          onClick={async () => {
            const variables = {
              variables: {
                id: selectedCommentId,
                labels: genericLabels.filter((s) => Boolean(s.label.length)),
                sentiment,
                classification: sentimentClassification,
                brandQuoted,
                commentType,
              },
            };

            try {
              await classifyComment(variables);
              if (!loading) {
                openSnackbar(labellingCommentSuccess, "notification");
              }
            } catch (err) {
              openSnackbar(labellingCommentError, "error");
            }
          }}
        />
      </Header>
      <LabellingWrapper>
        <SentimentGrid>
          <SentimentButton
            selectedSentiment={sentiment ? sentiment : null}
            onChangeSelectedLabel={(option) => onChangeSentimentValue(option[0].value as Sentiment)}
            onRemoveLabel={() => setSentiment(null)}
          />
          <Select
            options={sentimentClassificationOptions}
            onChange={(option: any) => onChangeSentimentClassification(option ? option.label : null)}
            value={sentimentClassification ? { value: sentimentClassification, label: sentimentClassification } : null}
            placeholder="Select Classification"
            label="Sentiment Classification"
            isClearable
          />
        </SentimentGrid>
        <>
          <DividerSection style={{ marginTop: 20, marginBottom: 15 }} />
          <WrapperFields>
            <FieldWrapper basis="50%" withoutMarginRight>
              <CheckBox
                checked={Boolean(brandQuoted)}
                onClick={() => setBrandQuoted((prevBrandQuoted) => !prevBrandQuoted)}
                label="Brand quoted"
                style={{ paddingTop: 16 }}
              />
            </FieldWrapper>
            <FieldWrapper basis="50%" withoutMarginRight>
              <Select
                options={commentTypeOptions}
                onChange={(option: any) => onChangeCommentType(option ? option.label : null)}
                value={commentType ? { value: commentType, label: commentType } : null}
                placeholder="Select comment type"
                label="Comment type"
                isClearable
              />
            </FieldWrapper>
          </WrapperFields>
        </>
        {projectContainsGenericLabels && buildDropdownLabels(projects, genericLabels, onChangeSelectedLabel, isMobile)}
      </LabellingWrapper>
    </LabellingPanelContainer>
  );
};

export { LateralLabellingPanel };
