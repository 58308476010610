import { CommentId } from "../types/CommentId";
import { Label } from "../types/Label";
import { SocialComment } from "../types/SocialComment";
import { InstagramComment } from "../types/InstagramComment";
import { FacebookComment } from "../types/FacebookComment";
import { Platform } from "../types/Platform";
import { FacebookGroupComment } from "../types/FacebookGroupComment";
import { SocialContent } from "../types/SocialContent";

export const isFbComment = (comment: SocialComment): comment is FacebookComment => {
  return comment.id.platformId === Platform.FB;
};
export const isIgComment = (comment: SocialComment): comment is InstagramComment => {
  return comment.id.platformId === Platform.IG;
};
export const isFbGroupComment = (comment: SocialComment): comment is FacebookGroupComment => {
  return comment.id.platformId === Platform.FBG;
};

export interface SocialCommentInfo {
  text: string;
  createdTime: number;
  commentId: CommentId;
  labels?: Array<Label>;
  parent: SocialComment | null;
  previous?: { items: Array<SocialComment>; pageInfo: { totalCount: number } };
  next?: { items: Array<SocialComment>; pageInfo: { totalCount: number } };
  content: SocialContent;
  children: { items: Array<SocialComment>; pageInfo: { totalCount: number } };
}

export const buildSocialCommentInfo = (comment: SocialComment): SocialCommentInfo => {
  if (isFbComment(comment)) {
    return {
      text: comment.text,
      createdTime: comment.createdTime,
      commentId: comment.id,
      labels: comment.labels,
      parent: comment.parent,
      previous: comment.previous,
      next: comment.next,
      content: comment.content,
      children: comment.children,
    };
  }
  if (isIgComment(comment)) {
    return {
      text: comment.text,
      createdTime: comment.createdTime,
      commentId: comment.id,
      labels: comment.labels,
      parent: comment.parent,
      previous: comment.previous,
      next: comment.next,
      content: comment.content,
      children: comment.children,
    };
  }

  return {
    text: comment.text,
    createdTime: comment.createdTime,
    commentId: comment.id,
    labels: comment.labels,
    parent: comment.parent,
    previous: comment.previous,
    next: comment.next,
    content: comment.content,
    children: comment.children,
  };
};
