import gql from "graphql-tag";
import { Platform } from "../../types/Platform";
import { SOCIAL_COMMENT } from "../fragments/socialCommentFragment";

const CLASSIFY_COMMENT = (platform: Platform) => gql`
  mutation classifyComment(
    $id: CommentIdInput!
    $labels: [LabelInput!]!
    $sentiment: Sentiment
    $classification: String
    $brandQuoted: Boolean
    $commentType: CommentType
  ) {
    classifyComment(
      id: $id
      labels: $labels
      sentiment: $sentiment
      classification: $classification
      brandQuoted: $brandQuoted
      commentType: $commentType
    ) {
      ...socialComment
    }
  }
  ${SOCIAL_COMMENT(platform)}
`;

export { CLASSIFY_COMMENT };
