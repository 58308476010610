import gql from "graphql-tag";

const LIST_PROJECTS = gql`
  query listProjects($id: PublisherIdInput!) {
    listProjects(id: $id) {
      items {
        project
        labels
      }
    }
  }
`;

export { LIST_PROJECTS };
