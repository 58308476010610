import * as React from "react";
import styled from "styled-components";
import { Platform } from "../../types/Platform";
import { getPlatformIcon } from "../../utils/getPlatformIcon";
import { CSSTransition } from "react-transition-group";
import { PlatformIcon, HorizDivider } from "./sharedStyles";

const WrapperActions = styled.div<{ withPlatform: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${props => (props.withPlatform ? "40px" : "0px")};
  transition: top 0.3s, opacity 0.3s;

  &.wrapper-entering {
    top: -40px;
    opacity: 0;
  }
  &.wrapper-entered {
    top: 2px;
    opacity: 1;
  }
  &.wrapper-exiting {
    top: -40px;
    opacity: 0;
  }
  &.wrapper-exited {
    top: -40px;
    opacity: 0;
  }
`;

const ActionsCard: React.FC<{ showIcon: boolean; iconSection: JSX.Element; platform?: Platform }> = ({
  showIcon,
  iconSection,
  platform
}) => {
  return (
    <>
      <CSSTransition in={showIcon} timeout={0} unmountOnExit>
        {status => (
          <WrapperActions className={`wrapper-${status}`} withPlatform={Boolean(platform)}>
            {iconSection}
            {platform && <HorizDivider style={{ marginRight: 8 }} />}
          </WrapperActions>
        )}
      </CSSTransition>
      {platform && <PlatformIcon inCard src={getPlatformIcon(platform)} alt="" />}
    </>
  );
};

export { ActionsCard };
