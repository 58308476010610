import * as React from "react";
import { FilterWrapper } from "../styles";
import { Select, ButtonIcon } from "@freeda/react-components";
import { DateFilter } from "../DateFilter";
import { useMediaQuery } from "react-responsive";
import { PostsFilterContext } from "../../../../providers/PostsFilterProvider";
import { SelectWithPublishers } from "../SelectWithPublishers";
import { FieldWrapper } from "../../../ui/sharedStyles";
import { buildPlatformName, Platform } from "../../../../types/Platform";
import { useFetchPublishers } from "../../../../hooks/useFetchPublishers";
import { Colors } from "@freeda/react-components/lib/theme";

const PostsAdvancedFilter: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { publishers } = useFetchPublishers();

  const {
    publisherId,
    platform,
    onChangePublisherId,
    dateRange,
    onChangeDateRange,
    onChangePlatform,
    onClearFilter,
  } = React.useContext(PostsFilterContext);

  React.useEffect(() => {
    const availablePublishers = publishers.filter((p) => p.id.platformId === platform);

    if (availablePublishers.length > 0) {
      onChangePublisherId(availablePublishers[0].id);
    }
  }, [publishers, platform]);

  const platformOptions = Object.values(Platform).map((p) => ({
    label: buildPlatformName(p),
    value: p,
  }));

  const selectedPlatformOption = platformOptions.find((p) => p.value === platform);

  return (
    <FilterWrapper>
      <FieldWrapper basis={!isMobile ? "190px" : ""} withoutMarginRight>
        <Select
          options={platformOptions}
          onChange={(option: any) => {
            onChangePlatform(option ? option.value : null);
          }}
          value={
            selectedPlatformOption ? { value: selectedPlatformOption.value, label: selectedPlatformOption.label } : null
          }
          placeholder="Select platform"
          label="Platform"
          isClearable={false}
        />
      </FieldWrapper>
      <FieldWrapper basis={!isMobile ? "190px" : ""} withoutMarginRight>
        <SelectWithPublishers publisherId={publisherId} onChangePublisherId={onChangePublisherId} platform={platform} />
      </FieldWrapper>
      <FieldWrapper basis={!isMobile ? "190px" : ""} withoutMarginRight>
        <DateFilter onChangeUpdateDateRange={onChangeDateRange} dateRange={dateRange} />
      </FieldWrapper>
      {!isMobile && (
        <ButtonIcon
          iconName="delete"
          onClick={onClearFilter}
          style={{ position: "absolute", top: 5, right: 5 }}
          iconColor={Colors.PURPLE}
        />
      )}
    </FilterWrapper>
  );
};

export { PostsAdvancedFilter };
