import * as React from "react";
import { WrapperListFilter, WrapperActions } from "./styles";
import { CheckboxMultiSelect } from "../../ui/CheckboxMultiSelect";
import { Colors } from "@freeda/react-components/lib/theme";
import { ButtonIcon, Paginator } from "@freeda/react-components";
import { HorizDivider } from "../../ui/sharedStyles";
import { LIMIT_POSTS_PER_PAGE } from "../../../utils/limitVariablesQueries";
import { PostsFilterContext } from "../../../providers/PostsFilterProvider";

interface Props {
  onClickCheck: () => void;
  onOpenLink: () => void;
  onOpenShareDialog: () => void;
  checked: boolean;
  selectAll: boolean;
  dataLoading: boolean;
  totalPages: number;
}

const PostsListFilter: React.FC<Props> = ({
  onClickCheck,
  checked,
  selectAll,
  onOpenLink,
  onOpenShareDialog,
  dataLoading,
  totalPages,
}) => {
  const { currentPage, onChangePage, onNextPage, onBackPage } = React.useContext(PostsFilterContext);

  return (
    <WrapperListFilter>
      <WrapperActions>
        <CheckboxMultiSelect checked={checked} onClick={onClickCheck} selectAll={selectAll} disabled={dataLoading} />
        {checked && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <HorizDivider style={{ margin: "0px 10px", marginLeft: 15 }} />
            <ButtonIcon
              iconName="open-link"
              style={{ marginRight: 5 }}
              iconColor={Colors.GRIGINO}
              onClick={onOpenLink}
            />
          </div>
        )}
      </WrapperActions>
      {dataLoading && (
        <div
          style={{ position: "absolute", height: 65, width: "100%", background: "#f9f9f9", zIndex: 2, opacity: 0.5 }}
        />
      )}
      <Paginator
        currentPage={currentPage}
        totalPage={totalPages}
        pageSize={LIMIT_POSTS_PER_PAGE}
        onChangePage={onChangePage}
        onBack={onBackPage}
        onNext={() => onNextPage(totalPages)}
        style={{ opacity: dataLoading ? 0.8 : 1, zIndex: 1 }}
      />
    </WrapperListFilter>
  );
};

export { PostsListFilter };
