import * as React from "react";
import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  PageWrapper,
  HeaderPage,
  PlatformIcon,
  WrapperSection,
  RightSection,
  LeftSection,
} from "../../ui/sharedStyles";
import { getPlatformIcon } from "../../../utils/getPlatformIcon";
import { Platform } from "../../../types/Platform";
import { SocialComment } from "../../../types/SocialComment";
import { GET_COMMENT } from "../../../apollo/queries/GetComment";
import { useLocation, useHistory } from "react-router-dom";
import { parse } from "query-string";
import { CommentPagePlaceholder } from "../../ui/Placeholder/CommentPagePlaceholder";
import { buildSocialCommentInfo } from "../../../utils/buildSocialCommentInfo";
import { Moderation } from "./Moderation";
import { BackButton } from "../../ui/BackButton";
import { getSnackbarErrorHandling } from "../../../utils/getSnackbarErrorHandling";
import { Roles } from "../../Permission";
import { SnackbarContext } from "../../../providers/SnackbarProvider";
import { ModerationAction } from "../../../types/ModerationAction";
import { CommentId } from "../../../types/CommentId";
import { MODERATE_COMMENT } from "../../../apollo/mutations/ModerateComment";
import { getCorrectModerationMessages } from "../../../messages";
import { updateCachedCommentFragment } from "../../../apollo/utils/updateCache";
import { LabellingPostDetailsPanel } from "../LabellingPostDetailsPanel";

interface Props {
  publisherId: string;
}

const CommentPage: React.FC<Props> = ({ publisherId }) => {
  const roles = localStorage.getItem("roles") as Roles | null;

  const { openSnackbar } = React.useContext(SnackbarContext);

  const history = useHistory();
  const { search } = useLocation();
  const queryString = parse(search);
  const isShareLink = history.action === "POP";

  const platformId = queryString.platformId as Platform;
  const contentId = queryString.contentId as string;
  const commentId = queryString.commentId as string;

  const mainCommentId = {
    platformId,
    contentId,
    commentId,
    publisherId,
  };

  const [selectedCommentId, setSelectedCommentId] = React.useState<CommentId>(mainCommentId);
  const [selectedAction, setSelectedAction] = React.useState<ModerationAction | null>(null);

  const [moderateComment, { loading }] = useMutation<
    { moderateComment: { success: Boolean } },
    { id: CommentId; action: ModerationAction }
  >(MODERATE_COMMENT, {
    update: (cache: DataProxy, result: FetchResult<{ moderateComment: { success: Boolean } }>) => {
      updateCachedCommentFragment(cache, result, selectedAction, selectedCommentId);
    },
  });

  React.useEffect(() => {
    // when change selectedAction - moderate selected comment
    if (selectedAction && selectedCommentId) {
      const messages = getCorrectModerationMessages(selectedAction);

      const moderate = async () => {
        try {
          await moderateComment({
            variables: {
              id: selectedCommentId,
              action: selectedAction,
            },
          });
          if (!loading) {
            openSnackbar(messages.success, "notification");
          }
          setSelectedAction(null);
        } catch (err) {
          openSnackbar(messages.error, "error");
        }
      };

      moderate();
    }
  }, [selectedAction]);

  const commentResult = useQuery<{ getComment: SocialComment }>(GET_COMMENT(platformId), {
    variables: {
      id: {
        ...mainCommentId,
      },
    },
  });

  if (commentResult.loading || !commentResult.data) {
    return (
      <>
        {getSnackbarErrorHandling(commentResult)}
        <CommentPagePlaceholder />
      </>
    );
  }

  const comment = commentResult.data.getComment;

  const { content } = buildSocialCommentInfo(comment);

  const writerRole = roles === Roles.MODERATOR || roles === Roles.LABELER;

  return (
    <PageWrapper>
      <HeaderPage>
        {isShareLink ? <div /> : <BackButton onClick={() => history.push("/")} />}
        <PlatformIcon src={getPlatformIcon(comment.id.platformId)} alt="" style={{ justifySelf: "center" }} />
      </HeaderPage>
      <WrapperSection>
        <LeftSection style={{ justifyContent: !writerRole ? "start" : "" }}>
          <Moderation
            mainComment={comment}
            selectedCommentId={selectedCommentId || comment.id}
            onChangeSelectedComment={(comment) => setSelectedCommentId(comment.id)}
            onChangeSelectedAction={(action) => setSelectedAction(action)}
            loadingDelete={loading}
          />
        </LeftSection>
        <RightSection>
          <LabellingPostDetailsPanel selectedCommentId={selectedCommentId} post={content} />
        </RightSection>
      </WrapperSection>
    </PageWrapper>
  );
};

export { CommentPage };
