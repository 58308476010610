import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { CommentCard } from "../layout/CommentCard/CommentCard";
import { WrapperList } from "../layout/CommentCard/styles";
import { CommentsListFilter } from "../layout/ListFilter/CommentsListFilter";
import { CSSTransition } from "react-transition-group";
import { useQuery } from "@apollo/react-hooks";
import { LIST_COMMENTS_BY_PUBLISHER } from "../../apollo/queries/ListCommentsByPublisher";
import {
  ListCommentsByPublisherQuery,
  ListCommentsByPublisherQueryVariables,
} from "../../types/graphql/ListCommentsByPublisherQuery";
import { isDataAvailable } from "../../apollo/utils/isDataAvailable";
import { ButtonStyled } from "../ui/ButtonStyled";
import { CommentsFilterContext } from "../../providers/CommentsFilterProvider";
import { ShareCommentDialog } from "../layout/Dialog/ShareCommentDialog";
import { includes } from "lodash";
import { StartCategorizeMobileButton } from "../layout/StartCategorizeMobileButton";
import { CommentModeration } from "../layout/CommentsModerationProcess/CommentModeration";
import { WrapperContainer, ItemWrapper } from "../ui/sharedStyles";
import { useHistory } from "react-router-dom";
import { CommentsFilter } from "../layout/PageFilter/comments/CommentsFilter";
import { v4 } from "uuid";
import { usePreviousData } from "../../hooks/usePreviousData";
import { useScrollToSelectedItem } from "../../hooks/useScrollToSelectedItem";
import { ListCardsPlaceholder } from "../ui/Placeholder/ListCardsPlaceholder";
import { ItemNotFound } from "../ui/Placeholder/ItemNotFound";
import { getSnackbarErrorHandling } from "../../utils/getSnackbarErrorHandling";
import { LIMIT_COMMENTS_PER_PAGE } from "../../utils/limitVariablesQueries";
import { Permission, Roles } from "../Permission";
import { useFetchPublishers } from "../../hooks/useFetchPublishers";
import { generatePathLinkComment } from "../../utils/generatePathLinkComment";

const CommentsListPage: React.FC = () => {
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 950 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { publishers } = useFetchPublishers();

  const wrapperListId = React.useRef<string>(v4());

  const [selectedCommentIds, setSelectedCommentIds] = React.useState<Array<string>>([]);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState<boolean>(false);

  const {
    publisherId,
    selectedComment,
    onChangeSelectedComment,
    currentPage,
    onChangeCurrentPage,
    onChangePublisherId,
    classified,
  } = React.useContext(CommentsFilterContext);

  useScrollToSelectedItem(isDesktopOrLaptop, selectedComment, onChangeSelectedComment, wrapperListId.current);

  React.useEffect(() => {
    const availablePublishers = publishers.filter((p) => p.id.platformId === publisherId.platformId);

    if (availablePublishers.length > 0) {
      onChangePublisherId(availablePublishers[0].id);
    }
  }, [publishers]);

  const prevPublisherId = usePreviousData(publisherId);

  React.useEffect(() => {
    if (publisherId.publisherId !== prevPublisherId.publisherId) {
      setSelectedCommentIds([]);
      onChangeSelectedComment(null);
      const wrapperList = document.getElementById(wrapperListId.current);
      wrapperList?.scrollTo({ left: 0, top: 0 });
    }
  }, [publisherId]);

  const listCommentsResult = useQuery<ListCommentsByPublisherQuery, ListCommentsByPublisherQueryVariables>(
    LIST_COMMENTS_BY_PUBLISHER(publisherId.platformId),
    {
      variables: {
        publisherId,
        offset: 0,
        limit: LIMIT_COMMENTS_PER_PAGE,
        order: "DESC",
        classified,
      },
    }
  );

  const commentsIsAvailable = isDataAvailable(listCommentsResult);

  const listComments = !commentsIsAvailable ? [] : listCommentsResult.data!.listCommentsByPublisher.items;
  const totalCount = !commentsIsAvailable ? 0 : listCommentsResult.data!.listCommentsByPublisher.pageInfo.totalCount;
  const totalPages = Math.ceil(totalCount / LIMIT_COMMENTS_PER_PAGE);

  const onToggleSelectedComment = (id: string) => {
    const deselectedComment = selectedCommentIds.find((cId) => cId === id);
    if (deselectedComment) {
      setSelectedCommentIds((ids) => ids.filter((cId) => cId !== id));
    } else {
      setSelectedCommentIds((ids) => [...ids, id]);
    }
  };

  const onSelectMultiComments = () => {
    if (selectedCommentIds.length > 0) {
      setSelectedCommentIds([]);
    } else {
      setSelectedCommentIds(listComments.map((c) => c.id.commentId));
    }
  };

  const getCommentIdsByClassified = (classified: boolean) => {
    return listComments.filter((c) => Boolean(c.labels && c.labels.length) === classified).map((c) => c.id.commentId);
  };

  const onSelectCommentsByStatus = (categorized: boolean | null) => {
    const allCommentIds = listComments.map((c) => c.id.commentId);

    if (categorized === null) {
      setSelectedCommentIds(allCommentIds);
    } else {
      const selectedCommentIds = getCommentIdsByClassified(categorized);
      setSelectedCommentIds(selectedCommentIds);
    }
  };

  const selectedComments = listComments.filter((comment) => includes(selectedCommentIds, comment.id.commentId));

  const prevCurrentPage = usePreviousData(currentPage);

  React.useEffect(() => {
    if (currentPage !== prevCurrentPage) {
      const fetchMoreComments = async () => {
        try {
          setLoadingButton(true);
          await listCommentsResult.fetchMore({
            variables: {
              offset: currentPage * LIMIT_COMMENTS_PER_PAGE,
              limit: LIMIT_COMMENTS_PER_PAGE,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev;
              }

              const newCommentsList = Object.assign({}, prev, {
                listCommentsByPublisher: {
                  ...fetchMoreResult.listCommentsByPublisher,
                  items: [...prev.listCommentsByPublisher.items, ...fetchMoreResult.listCommentsByPublisher.items],
                },
              });

              return newCommentsList;
            },
          });
        } catch (err) {
          console.log(err);
        } finally {
          setLoadingButton(false);
        }
      };
      fetchMoreComments();
    }
  }, [currentPage, prevCurrentPage, listCommentsResult]);

  const disabledFetchMoreButton = currentPage === totalPages - 1;

  const allPathLinkComment = selectedComments.map((cId) => generatePathLinkComment(cId.id));

  return (
    <>
      {openShareDialog && selectedComments.length > 0 && (
        <ShareCommentDialog
          open={openShareDialog}
          onClose={() => setOpenShareDialog(false)}
          comments={selectedComments}
        />
      )}
      <WrapperContainer>
        <ItemWrapper style={{ width: isDesktopOrLaptop ? "45%" : "100%" }}>
          <CommentsFilter />
          <CommentsListFilter
            checked={selectedCommentIds.length > 0}
            onClickCheck={onSelectMultiComments}
            selectAll={selectedCommentIds.length === listComments.length}
            onSelectClassifiedComments={onSelectCommentsByStatus}
            onOpenShareDialog={() => setOpenShareDialog(true)}
            onOpenModerationProcess={() => history.push("/moderation")}
            loadingFetchComments={!commentsIsAvailable || listComments.length === 0}
            onOpenLink={() => allPathLinkComment.map((link) => window.open(link))}
          />
          {getSnackbarErrorHandling(listCommentsResult)}
          {!commentsIsAvailable && <ListCardsPlaceholder />}
          {commentsIsAvailable && listComments.length === 0 && <ItemNotFound context="comments" />}
          {commentsIsAvailable && listComments.length > 0 && (
            <WrapperList id={wrapperListId.current}>
              {listComments.map((comment) => {
                const openMobileBoolean = selectedComment
                  ? selectedComment.id.commentId === comment.id.commentId
                  : false;

                const openDesktopBoolean = selectedComment
                  ? selectedComment.id.commentId === comment.id.commentId
                  : listComments[0].id.commentId === comment.id.commentId;

                return (
                  <CommentCard
                    key={comment.id.commentId}
                    open={isDesktopOrLaptop ? openDesktopBoolean : openMobileBoolean}
                    comment={comment}
                    selected={Boolean(selectedCommentIds.find((id) => id === comment.id.commentId))}
                    onToggleSelected={() => onToggleSelectedComment(comment.id.commentId)}
                    onModerateComment={() => {
                      onChangeSelectedComment(comment);
                      if (!isDesktopOrLaptop) {
                        history.push(
                          `comment/${comment.id.publisherId}?platformId=${comment.id.platformId}&contentId=${comment.id.contentId}&commentId=${comment.id.commentId}`
                        );
                      }
                    }}
                  />
                );
              })}

              <ButtonStyled
                disabled={disabledFetchMoreButton}
                style={{ margin: "0 auto", marginBottom: isMobile ? 55 : 0 }}
                loading={loadingButton}
                onClick={() => onChangeCurrentPage(totalPages)}
              >
                {!disabledFetchMoreButton ? `See ${LIMIT_COMMENTS_PER_PAGE} more comments` : "No more comments"}
              </ButtonStyled>
            </WrapperList>
          )}
        </ItemWrapper>
        <Permission roles={[Roles.LABELER, Roles.MODERATOR]}>
          <CSSTransition in={isMobile} timeout={0} unmountOnExit>
            {(status) => (
              <StartCategorizeMobileButton
                transitionStatus={status}
                onOpenModerationProcess={() => history.push("/moderation")}
              />
            )}
          </CSSTransition>
        </Permission>
        {isDesktopOrLaptop && (
          <CommentModeration
            id={
              commentsIsAvailable && listComments.length > 0
                ? selectedComment
                  ? selectedComment.id
                  : listComments[0].id
                : null
            }
          />
        )}
      </WrapperContainer>
    </>
  );
};

export { CommentsListPage };
