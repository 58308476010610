import * as React from "react";
import styled from "styled-components";
import { NavLink, NavLinkProps } from "react-router-dom";

const StyledNavLink = styled(NavLink)<NavLinkProps>`
  && {
    text-decoration: none;
    width: 100%;
  }
`;

const RouterLink: React.FC<NavLinkProps> = props => <StyledNavLink {...(props as any)} />;

export { RouterLink };
