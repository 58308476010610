import * as React from "react";
import { Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { StepButtonWrapper } from "./styles";

const leftArrowKey = require("../../../assets/icon/left-arrow-keyboard.svg");
const rightArrowKey = require("../../../assets/icon/right-arrow-keyboard.svg");

type StepButton = "prev" | "next";

interface Props {
  step: StepButton;
  onChangeStep: () => void;
  disabled?: boolean;
}

const StepButton: React.FC<Props> = ({ step, onChangeStep, disabled, children }) => {
  return (
    <StepButtonWrapper onClick={() => !disabled && onChangeStep()} disabled={Boolean(disabled)}>
      {step === "prev" && <img src={leftArrowKey} alt="prev-arrow-button" style={{ marginRight: 10, width: 35 }} />}
      <Typography
        variantName="card-title"
        style={{ cursor: disabled ? "default" : "pointer", userSelect: "none" }}
        textColor={Colors.GRIGINO}
      >
        <>{children}</>
      </Typography>
      {step === "next" && <img src={rightArrowKey} alt="next-arrow-button" style={{ marginLeft: 10, width: 35 }} />}
    </StepButtonWrapper>
  );
};

export { StepButton };
