import * as React from "react";

const RefreshSpan: React.FC = ({ children }) => (
  <span style={{ display: "flex", flexDirection: "column", userSelect: "text" }}>
    {children}
    <a onClick={() => window.location.reload()} style={{ paddingTop: 10, color: "#fff" }} href="">
      <span
        style={{
          userSelect: "none",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        Click here to refresh!
      </span>
    </a>
  </span>
);

export { RefreshSpan };
