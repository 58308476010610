import styled, { css } from "styled-components";
import { lightScrollbarVertical } from "../../ui/ScrollbarStyles";
import { CommentType } from "../../ui/CommentBox";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0px 20px;
`;

const StepButtonWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  transition: opacity 0.3s;
  user-select: none;
`;

const PostSection = styled.section`
  display: flex;
  margin-bottom: 20px;
`;

const PostDetails = styled.div`
  padding: 0px 20px;
  display: flex;
`;

const CommentWrapper = styled.div<{ commentType: CommentType }>`
  display: flex;
  align-items: center;
  position: relative;

  ${(props) =>
    (props.commentType === "selectedChild" || props.commentType === "child") &&
    css`
      margin-left: 50px;
    `}

  ${(props) =>
    props.commentType === "freeda" &&
    css`
      justify-content: flex-end;
    `}
`;

const Comment = styled.div<{ commentType: CommentType; isVisible: boolean }>`
  position: relative;
  padding: 15px;
  background: #f3f3f3;
  border-radius: 12px;
  max-width: 450px;
  min-width: 150px;
  display: inline-flex;
  transition: opacity 0.3s;

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0.7;
    `}

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0px;
    left: -8px;
    border-bottom: 40px solid #f3f3f3;
    border-left: 20px solid transparent;
    border-right: 0px solid transparent;
  }

  ${(props) =>
    props.commentType === "child" &&
    css`
      max-width: 250px;
      margin-top: 20px;
      background: #f3f3f3;
      &::after {
        border-bottom: 20px solid #f3f3f3;
        border-left: 0px solid transparent;
        border-right: 40px solid transparent;
        top: -8px;
        left: 0px;
      }
    `}

  ${(props) =>
    props.commentType === "selectedChild" &&
    css`
      max-width: 250px;
      margin-top: 20px;
      background: #dffff6;
      &::after {
        border-bottom: 20px solid #dffff6;
        border-left: 0px solid transparent;
        border-right: 40px solid transparent;
        top: -8px;
        left: 0px;
      }
    `}

  ${(props) =>
    props.commentType === "selected" &&
    css`
      align-self: flex-start;
      background: #dffff6;
      &::after {
        border-bottom: 40px solid #dffff6;
      }
    `}

  ${(props) =>
    props.commentType === "freeda" &&
    css`
      margin-left: 0px;
      margin-right: 20px;
      &::after {
        display: none;
      }
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0px;
        right: -8px;
        border-bottom: 40px solid #f3f3f3;
        border-right: 20px solid transparent;
        border-left: 0px solid transparent;
      }
    `}
`;

const ModerationWrapper = styled.div<{ maxWidth?: number }>`
  padding: 15px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 750)}px;

  & > div:last-child {
    padding-bottom: 20px;
  }
  & > div:first-child {
    padding-top: 20px;
  }
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ModerationTransition = styled.div`
  overflow-y: overlay;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  height: 100%;

  ${lightScrollbarVertical}
`;

const MessageWrapper = styled.div`
  max-height: 180px;
  overflow-y: overlay;
  text-overflow: ellipsis;

  ${lightScrollbarVertical};
`;

export {
  ButtonWrapper,
  StepButtonWrapper,
  PostSection,
  Comment,
  CommentWrapper,
  ModerationWrapper,
  ModerationTransition,
  PostDetails,
  MessageWrapper,
};
