import * as React from "react";
import { CommentWrapper } from "../../layout/CommentsModerationProcess/styles";
import { CommentType } from "../CommentBox";
import { CommentSkeleton, Label } from "./styles";

const CommentPlaceholder: React.FC<{ commentType?: CommentType; labelWidth?: number }> = ({
  commentType,
  labelWidth,
  children,
}) => {
  return (
    <CommentWrapper commentType={commentType ? commentType : "default"}>
      <div style={{ marginRight: 10, width: 35, height: 35 }} />
      <CommentSkeleton isVisible={true} commentType={commentType ? commentType : "default"} style={{ padding: 20 }}>
        {children ? children : <Label style={{ width: labelWidth ? labelWidth : 400, marginBottom: 0 }} />}
      </CommentSkeleton>
    </CommentWrapper>
  );
};

export { CommentPlaceholder };
