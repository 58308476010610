import * as React from "react";
import { LocationContext } from "../providers/LocationProvider";
import { usePreviousData } from "./usePreviousData";
import { SocialComment } from "../types/SocialComment";
import { SocialContent } from "../types/SocialContent";

export const isComment = (item: SocialComment | SocialContent): item is SocialComment => {
  return "text" in item;
};
export const isPost = (item: SocialComment | SocialContent): item is SocialContent => {
  return "title" in item;
};

export const buildPropertyItem = <T extends SocialComment | SocialContent>(
  item: T
): { id: string; pathname: string } => {
  if (isComment(item)) {
    return {
      id: item.id.commentId,
      pathname: "",
    };
  }
  return {
    id: item.id.contentId,
    pathname: "posts",
  };
};

export const useScrollToSelectedItem = <T extends SocialComment | SocialContent>(
  mediaQuery: boolean,
  selectedItem: T | null,
  onChangeSelectedItem: (item: T | null) => void,
  listId: string
) => {
  const { updatedLocation } = React.useContext(LocationContext);
  const prevLocation = usePreviousData(updatedLocation);

  const propertyItem = selectedItem && buildPropertyItem(selectedItem);

  React.useEffect(() => {
    if (selectedItem && !mediaQuery) {
      onChangeSelectedItem(null);
    }
  }, [mediaQuery, onChangeSelectedItem, selectedItem]);

  React.useEffect(() => {
    if (prevLocation && propertyItem) {
      if (selectedItem && prevLocation.pathname !== `/${propertyItem.pathname}`) {
        const wrapperList = document.getElementById(listId);

        const itemCard = document.getElementById(propertyItem.id);

        if (wrapperList && itemCard) {
          wrapperList.scroll({ top: itemCard.offsetTop });
        }
      }
    }
  }, [prevLocation, selectedItem]);
};
