import * as React from "react";
import { getFormatDate } from "../../../utils/getFormatDate";
import { buildSocialContentInfo, isFbGroupContent } from "../../../utils/buildSocialContentInfo";
import { Colors } from "@freeda/react-components/lib/theme";
import { SocialContent } from "../../../types/SocialContent";
import { WrapperCard, WrapperText } from "./styles";
import { ActionsCard } from "../../ui/ActionsCard";
import { ButtonIcon, CheckBox, Typography } from "@freeda/react-components";
import { MiniChip } from "../../ui/MiniChip";
import { SharePostDialog } from "../Dialog/SharePostDialog";
import { PostImg } from "../../ui/sharedStyles";
import { getPostImage } from "../../../utils/getPostImage";

const placeholderPost = require("../../../assets/post/placeholderPost.jpg");

interface Props {
  post: SocialContent;
  selected: boolean;
  open: boolean;
  onToggleSelected: () => void;
  onSelectedPost: () => void;
}

const PostCard: React.FC<Props> = ({ post, selected, open, onToggleSelected, onSelectedPost }) => {
  const [hover, setHover] = React.useState<boolean>(false);
  const [openShareDialog, setOpenShareDialog] = React.useState<boolean>(false);

  const {
    title,
    caption,
    permalink,
    mediaUrl,
    publicationDate,
    messageFbGroup,
    commentsCount,
  } = buildSocialContentInfo(post);

  const isFBGpost = isFbGroupContent(post);

  const imagePost = getPostImage(post);

  return (
    <>
      {openShareDialog && (
        <SharePostDialog open={openShareDialog} onClose={() => setOpenShareDialog(false)} posts={[post]} />
      )}
      <WrapperCard
        open={open}
        selected={selected}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onSelectedPost}
        id={post.id.contentId}
      >
        <ActionsCard
          showIcon={hover}
          iconSection={
            <a href={permalink} style={{ textDecoration: "none" }} target="__blank">
              <ButtonIcon
                iconName="open-link"
                style={{ marginRight: 8 }}
                iconColor={Colors.GRIGINO}
                onClick={(e) => e.stopPropagation()}
              />
            </a>
          }
        />
        <div
          onClick={(e) => {
            e.stopPropagation();
            onToggleSelected();
          }}
          style={{ padding: 15, display: "flex", alignItems: "center" }}
        >
          <CheckBox checked={selected} onClick={onToggleSelected} stopPropagation={false} />
        </div>
        {isFBGpost && imagePost && (
          <PostImg
            size="medium"
            src={imagePost}
            style={{ marginLeft: 0 }}
            onError={(e) => (e.currentTarget.src = placeholderPost)}
          />
        )}
        {!isFBGpost && <PostImg size="medium" src={imagePost || placeholderPost} style={{ marginLeft: 0 }} />}
        <div
          style={{
            display: "grid",
            alignItems: isFBGpost && !mediaUrl ? "flex-start" : "flex-end",
            marginTop: isFBGpost && !mediaUrl ? 20 : "",
            gridTemplateRows: "65% 35%",
            width: "100%",
          }}
        >
          <WrapperText isFBGpost={isFBGpost}>
            {isFBGpost && messageFbGroup && (
              <Typography
                variantName="text"
                textColor={Colors.PURPLEmedium}
                style={{ fontWeight: 500, cursor: "pointer" }}
              >
                {messageFbGroup}
              </Typography>
            )}
            {!isFBGpost && title && (
              <Typography
                variantName="text"
                textColor={Colors.PURPLEmedium}
                style={{ fontWeight: 500, marginBottom: 5, cursor: "pointer" }}
              >
                {title}
              </Typography>
            )}
            {!isFBGpost && caption && (
              <Typography variantName="text" textColor={Colors.GRIGINO} style={{ fontSize: 12, cursor: "pointer" }}>
                {caption}
              </Typography>
            )}
          </WrapperText>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer" }}>
            <MiniChip type="comments" value={commentsCount} />
          </div>
        </div>
        <Typography
          variantName="caption"
          style={{ position: "absolute", bottom: 8, right: 12, cursor: "pointer", letterSpacing: 0.5 }}
        >
          {getFormatDate(publicationDate)}
        </Typography>
      </WrapperCard>
    </>
  );
};

export { PostCard };
