import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";
import { Typography } from "@freeda/react-components";

const ItemNotFound: React.FC<{ context: "comments" | "posts"; styles?: React.CSSProperties }> = ({
  context,
  styles,
}) => {
  return (
    <div style={{ padding: "10px 20px", display: "flex", flexDirection: "column", alignItems: "center", ...styles }}>
      <Typography
        variantName="label"
        textColor={Colors.GRIGINO}
        style={{ userSelect: "none", whiteSpace: "normal", textAlign: "center" }}
      >
        {`Mmh... I'm searching the ${context} but not found nothing... 🥺`}
      </Typography>
    </div>
  );
};

export { ItemNotFound };
