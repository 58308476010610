import * as React from "react";
import { Label } from "../../../types/Label";
import { DividerSection, Select } from "@freeda/react-components";
import { WrapperFields } from "./styles";
import { FieldWrapper } from "../../ui/sharedStyles";
import { Project } from "../../../types/Project";

const buildDropdownLabels = (
  genericProjects: Array<Project>,
  genericLabelsValues: Array<Label>,
  onChangeSelectedLabel: (project: string, label: string) => void,
  isMobile: boolean
) => {
  return (
    <>
      <DividerSection style={{ marginTop: 20, marginBottom: 15 }} />
      <WrapperFields>
        {genericProjects.map(({ project, labels }, i) => {
          const options = labels.map((v) => ({ value: v, label: v }));

          const valuesWithoutEmptyString = genericLabelsValues.filter((v) => Boolean(v.label));

          const selectedOption = valuesWithoutEmptyString.find((o) => o.project === project);

          return (
            <FieldWrapper key={i} basis={isMobile ? "50%" : "100%"} withoutMarginRight>
              <Select
                options={options}
                onChange={(option: any) => onChangeSelectedLabel(project, option ? option.label : null)}
                value={selectedOption ? { value: selectedOption.label, label: selectedOption.label } : null}
                placeholder={`Select ${project}`}
                label={project}
                isClearable
              />
            </FieldWrapper>
          );
        })}
      </WrapperFields>
    </>
  );
};

export { buildDropdownLabels };
