import * as React from "react";
import { Snackbar } from "@freeda/react-components";
import { RefreshSpan } from "../components/ui/RefreshSpan";
import { buildWrapText } from "./buildWrapText";
import { networkStatus8 } from "../messages";
import { QueryResult } from "react-apollo";

export const getSnackbarErrorHandling = <T extends any>(queryResult: QueryResult<T, Record<string, any>>) => {
  const error = queryResult.error;

  if (error) {
    if (error.networkError && error.networkError.name === "ServerParseError") {
      // Failed to load resource: the server responded with a status of 403 ()
      // error.networkError.statusCode === 403
      return (
        <Snackbar
          typology="error"
          open={true}
          label={
            <RefreshSpan>
              {buildWrapText(networkStatus8).map((t, i) => (
                <span key={i}>{t}</span>
              ))}
            </RefreshSpan>
          }
        />
      );
    }
  }

  return null;
};
