import * as React from "react";
import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import { StepButton } from "./StepButton";
import { SocialComment } from "../../../types/SocialComment";
import { useMediaQuery } from "react-responsive";
import { getPlatformIcon } from "../../../utils/getPlatformIcon";
import { useKeyArrow } from "../../../hooks/useKeyArrow";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Moderation } from "./Moderation";
import { BackButton } from "../../ui/BackButton";
import { ButtonIcon, DialogCard, Select, Typography } from "@freeda/react-components";
import { ButtonWrapper } from "./styles";
import { ModerationPagePlaceholder } from "../../ui/Placeholder/ModerationPagePlaceholder";
import {
  PlatformIcon,
  HeaderPage,
  PageWrapper,
  WrapperSection,
  LeftSection,
  RightSection,
} from "../../ui/sharedStyles";
import { buildSocialCommentInfo } from "../../../utils/buildSocialCommentInfo";
import { Publisher } from "../../../types/Publisher";
import { getSnackbarErrorHandling } from "../../../utils/getSnackbarErrorHandling";
import { ModerationAction } from "../../../types/ModerationAction";
import { CommentId } from "../../../types/CommentId";
import { MODERATE_COMMENT } from "../../../apollo/mutations/ModerateComment";
import { SnackbarContext } from "../../../providers/SnackbarProvider";
import { getCorrectModerationMessages } from "../../../messages";
import { updateCachedCommentFragment } from "../../../apollo/utils/updateCache";
import { useHistory } from "react-router-dom";
import { Platform, buildPlatformName } from "../../../types/Platform";
import { usePreviousData } from "../../../hooks/usePreviousData";
import {
  ListCommentsByPublisherQuery,
  ListCommentsByPublisherQueryVariables,
} from "../../../types/graphql/ListCommentsByPublisherQuery";
import { LIST_COMMENTS_BY_PUBLISHER } from "../../../apollo/queries/ListCommentsByPublisher";
import { LIMIT_COMMENTS_PER_PAGE_MODERATION } from "../../../utils/limitVariablesQueries";
import { isDataAvailable } from "../../../apollo/utils/isDataAvailable";
import { LabellingPostDetailsPanel } from "../LabellingPostDetailsPanel";

interface Props {
  publishers: Array<Publisher>;
  selectedPublisherId: string;
  selectedPlatform: Platform;
  onChangePublisherId: (publisherId: string) => void;
  onChangePlatform: (platform: Platform) => void;
}

const CommentsModerationProcess: React.FC<Props> = ({
  publishers,
  selectedPublisherId,
  selectedPlatform,
  onChangePublisherId,
  onChangePlatform,
}) => {
  const history = useHistory();

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { openSnackbar } = React.useContext(SnackbarContext);

  const [dataLoading, setDataLoading] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [totalPageCount, setTotalPageCount] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  const [selectedComment, setSelectedComment] = React.useState<SocialComment | null>(null);
  const [selectedAction, setSelectedAction] = React.useState<ModerationAction | null>(null);

  const prevButtonDisabled = (totalPageCount === 0 && activeStep === 0) || totalPageCount < 0;

  React.useEffect(() => {
    setActiveStep(0);
    setTotalPageCount(0);
    setCurrentPage(0);
    setSelectedComment(null);
  }, [selectedPublisherId, selectedPlatform]);

  const onPrevComment = () => {
    if (!dataLoading) {
      if (!prevButtonDisabled) {
        setSelectedComment(null);
        setTotalPageCount((counter) => counter - 1);

        if (activeStep === 0) {
          setCurrentPage((counter) => counter - 1);
          setActiveStep(listComments.length - 1);
        } else {
          setActiveStep((initialStep) => initialStep - 1);
        }
      }
    }
  };

  const onNextComment = () => {
    if (!dataLoading) {
      if (!nextButtonDisabled) {
        setSelectedComment(null);
        setTotalPageCount((counter) => counter + 1);

        if (activeStep === listComments.length - 1) {
          setCurrentPage((counter) => counter + 1);
          setActiveStep(0);
        } else {
          setActiveStep((initialStep) => initialStep + 1);
        }
      }
    }
  };

  useKeyArrow(onPrevComment, onNextComment);

  const [moderateComment, { loading }] = useMutation<
    { moderateComment: { success: Boolean } },
    { id: CommentId; action: ModerationAction }
  >(MODERATE_COMMENT, {
    update: (cache: DataProxy, result: FetchResult<{ moderateComment: { success: Boolean } }>) => {
      if (selectedComment) {
        updateCachedCommentFragment(cache, result, selectedAction, selectedComment.id);
      }
    },
  });

  React.useEffect(() => {
    // when change selectedAction - moderate selected comment
    if (selectedAction && selectedComment) {
      const messages = getCorrectModerationMessages(selectedAction);

      const { id } = selectedComment;

      const moderate = async () => {
        try {
          await moderateComment({
            variables: {
              id,
              action: selectedAction,
            },
          });
          if (!loading) {
            openSnackbar(messages.success, "notification");
          }
          setSelectedAction(null);
        } catch (err) {
          openSnackbar(messages.error, "error");
        }
      };

      moderate();
    }
  }, [selectedAction]);

  const listCommentsResult = useQuery<ListCommentsByPublisherQuery, ListCommentsByPublisherQueryVariables>(
    LIST_COMMENTS_BY_PUBLISHER(selectedPlatform),
    {
      variables: {
        publisherId: { platformId: selectedPlatform, publisherId: selectedPublisherId },
        offset: 0,
        limit: LIMIT_COMMENTS_PER_PAGE_MODERATION,
        order: "DESC",
        classified: false,
      },
    }
  );

  const commentsIsAvailable = isDataAvailable(listCommentsResult);

  const fetchMoreComment = React.useCallback(async () => {
    try {
      setDataLoading(true);

      await listCommentsResult.fetchMore({
        variables: {
          offset: currentPage * LIMIT_COMMENTS_PER_PAGE_MODERATION,
          limit: LIMIT_COMMENTS_PER_PAGE_MODERATION,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return fetchMoreResult;
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  }, [listCommentsResult, currentPage]);

  const prevCurrentPage = usePreviousData(currentPage);

  React.useEffect(() => {
    if (prevCurrentPage !== currentPage) {
      fetchMoreComment();
    }
  }, [currentPage, prevCurrentPage, fetchMoreComment]);

  if (dataLoading || listCommentsResult.loading || !listCommentsResult.data) {
    return (
      <>
        {getSnackbarErrorHandling(listCommentsResult)}
        <ModerationPagePlaceholder />
      </>
    );
  }

  const listComments = !commentsIsAvailable ? [] : listCommentsResult.data!.listCommentsByPublisher.items;
  const totalCommentsCount = !commentsIsAvailable
    ? 0
    : listCommentsResult.data!.listCommentsByPublisher.pageInfo.totalCount;

  const nextButtonDisabled = totalCommentsCount - 1 === totalPageCount;

  const publisherOptions = publishers.map((p) => ({
    value: p.id.publisherId,
    label: p.description,
  }));

  const selectedPublisherOption = publisherOptions.find((p) => p.value === selectedPublisherId);

  const platformOptions = Object.values(Platform).map((p) => ({
    label: buildPlatformName(p),
    value: p,
  }));

  const selectedPlatformOption = platformOptions.find((p) => p.value === selectedPlatform);

  const headerWithFilter = (
    <HeaderPage>
      <BackButton onClick={() => history.push("/")} />
      <PlatformIcon src={getPlatformIcon(selectedPlatform)} alt="" style={{ justifySelf: "center" }} />
      <div style={{ display: "flex", alignItems: "center", justifySelf: "end", marginRight: 10 }}>
        {!isMobile && selectedPublisherOption && (
          <Typography variantName="text" style={{ marginRight: 10, fontWeight: 500 }}>
            {selectedPublisherOption.label}
          </Typography>
        )}
        <DialogCard
          tooltipStyle={{ width: 200 }}
          referenceChildren={<ButtonIcon iconName="filter-list" />}
          tooltipChildren={(closeCard) => {
            return (
              <>
                <Select
                  isClearable={false}
                  options={platformOptions}
                  onChange={(option: any) => {
                    onChangePlatform(option.value);
                    closeCard();
                  }}
                  value={
                    selectedPlatformOption
                      ? { value: selectedPlatformOption.value, label: selectedPlatformOption.label }
                      : null
                  }
                  placeholder="Select platform"
                  label="Platform"
                />
                <Select
                  isClearable={false}
                  options={publisherOptions}
                  onChange={(option: any) => {
                    onChangePublisherId(option.value);
                    closeCard();
                  }}
                  value={
                    selectedPublisherOption
                      ? { value: selectedPublisherOption.value, label: selectedPublisherOption.label }
                      : null
                  }
                  placeholder="Select publisher"
                  label="Publisher"
                />
              </>
            );
          }}
          position="bottom-start"
        />
      </div>
    </HeaderPage>
  );

  if (listComments.length === 0) {
    return <ModerationPagePlaceholder itemNotFound headerWithFilter={headerWithFilter} />;
  }

  const activeComment = listComments[activeStep];

  const { content } = buildSocialCommentInfo(activeComment);

  return (
    <PageWrapper>
      {headerWithFilter}
      <WrapperSection>
        <LeftSection style={{ justifyContent: "start" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <Moderation
              mainComment={activeComment}
              selectedCommentId={selectedComment ? selectedComment.id : activeComment.id}
              onChangeSelectedComment={(comment) => setSelectedComment(comment)}
              onChangeSelectedAction={(action) => setSelectedAction(action)}
              loadingDelete={loading}
            />
            <ButtonWrapper>
              <StepButton step="prev" disabled={prevButtonDisabled} onChangeStep={onPrevComment}>
                {isMobile ? "" : "Prev"}
              </StepButton>
              <StepButton step="next" disabled={nextButtonDisabled} onChangeStep={onNextComment}>
                {isMobile ? "" : "Next"}
              </StepButton>
            </ButtonWrapper>
          </div>
        </LeftSection>
        <RightSection>
          <LabellingPostDetailsPanel
            selectedCommentId={selectedComment ? selectedComment.id : activeComment.id}
            post={content}
          />
        </RightSection>
      </WrapperSection>
    </PageWrapper>
  );
};

export { CommentsModerationProcess };
