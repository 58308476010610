import styled from "styled-components";

const ComponentsWrapper = styled.div`
  height: 100vh;
  overflow: hidden;

  @media (min-width: 769px) {
    padding-left: 90px;
    padding-right: 15px;
  }

  @media (max-width: 768px) {
    padding-top: 55px;
  }
`;

export { ComponentsWrapper };
