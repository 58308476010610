import * as React from "react";
import { Select } from "@freeda/react-components";
import { PublisherId } from "../../../types/PublisherId";
import { useFetchPublishers } from "../../../hooks/useFetchPublishers";
import { Platform } from "../../../types/Platform";

interface Props {
  publisherId: PublisherId | null;
  onChangePublisherId: (publisher: PublisherId) => void;
  platform?: Platform;
}

const SelectWithPublishers: React.FC<Props> = ({ publisherId, onChangePublisherId, platform }) => {
  const { publishers } = useFetchPublishers();

  let availablePublishers = publishers;
  if (platform) {
    availablePublishers = publishers.filter((p) => p.id.platformId === platform);
  }

  const publisherOptions = availablePublishers.map((p) => ({
    value: p.id.publisherId,
    label: p.description,
  }));

  const selectedPublisher = publisherId
    ? publishers.find((p) => p.id.publisherId === publisherId.publisherId)
    : publishers[0];

  return (
    <Select
      options={publisherOptions}
      onChange={(option: any) => {
        const publisher = publishers.find((p) => p.id.publisherId === option.value);

        if (publisher) {
          onChangePublisherId(publisher.id);
        }
      }}
      value={
        selectedPublisher ? { value: selectedPublisher.id.publisherId, label: selectedPublisher.description } : null
      }
      placeholder="Select publisher"
      label="Publishers"
      isClearable={false}
      isDisabled={publishers.length === 0}
    />
  );
};

export { SelectWithPublishers };
