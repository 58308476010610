import { css } from "styled-components";

const transparentScrollbar = css`
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }
`;

const scrollbarHorizontal = css`
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #999999;
    height: 3px;
  }
  ::-webkit-scrollbar-corner {
    display: none;
    height: 0px;
    width: 0px;
  }
`;

const lightScrollbarHorizontal = css`
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #dbdbdb;
    height: 3px;
  }
  ::-webkit-scrollbar-corner {
    display: none;
    height: 0px;
    width: 0px;
  }
`;

const scrollbarVertical = css`
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #999999;
  }
  ::-webkit-scrollbar-corner {
    display: none;
    height: 0px;
    width: 0px;
  }
`;

const lightScrollbarVertical = css`
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #dbdbdb;
  }
  ::-webkit-scrollbar-corner {
    display: none;
    height: 0px;
    width: 0px;
  }
`;

export {
  scrollbarHorizontal,
  lightScrollbarHorizontal,
  scrollbarVertical,
  lightScrollbarVertical,
  transparentScrollbar
};
