export enum Sentiment {
  POSITIVE = "POSITIVE",
  NEUTRAL = "NEUTRAL",
  NEGATIVE = "NEGATIVE",
}

export enum SentimentClassification {
  FREEDA = "FREEDA",
  TALENT = "TALENT",
  CONTENT = "CONTENT",
  BRAND = "BRAND",
  PRODUCT = "PRODUCT",
  USER_COMMENT = "USER/USER COMMENT",
}

export enum SentimentCategories {
  FREEDA = "FREEDA",
  CONTENT = "CONTENT",
  BRAND = "BRAND",
  TALENT = "TALENT",
}
