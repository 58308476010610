import styled from "styled-components";
import { lightScrollbarVertical, transparentScrollbar } from "../../ui/ScrollbarStyles";
import { ButtonIcon } from "@freeda/react-components";

const Details = styled.div`
  padding: 0px 20px;
  display: flex;
`;

const WrapperListComments = styled.div`
  overflow-y: overlay;
  overflow-x: hidden;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;

  ${lightScrollbarVertical};
`;

const ListComments = styled.div`
  height: 100%;
  max-width: 750px;
  padding: 15px;

  & > div:first-child {
    padding-top: 20px;
  }

  & > div {
    margin-bottom: 15px;
  }
`;

const SentimentWrapper = styled.div`
  display: flex;
  overflow-x: overlay;
  overflow-y: hidden;
  padding-bottom: 15px;

  ${transparentScrollbar};

  & > div {
    margin: 5px;
  }
`;

const ArrowUp = styled(ButtonIcon)`
  position: absolute;
  right: 30px;
  bottom: 20px;
  background: ${(props) => props.theme.primary};

  & > i {
    font-size: 25px !important;
  }

  transition: opacity 0.3s;

  &.entering {
    opacity: 0;
  }
  &.entered {
    opacity: 1;
  }
  &.exiting {
    opacity: 0;
  }
  &.exited {
    opacity: 0;
  }
`;

export { Details, ListComments, SentimentWrapper, ArrowUp, WrapperListComments };
