import styled, { keyframes, css } from "styled-components";
import { CommentType } from "../CommentBox";

const BackgroundAnimation = keyframes`
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: -135% 0%;
	}	
`;

const SkeletonBox = styled.div`
  animation: ${BackgroundAnimation} 1.2s ease-in-out infinite;
  background: linear-gradient(90deg, #f3f3f3 0%, #e8e8e8 50%, #f3f3f3 100%);
  background-size: 400% 400%;
`;

const SkeletonText = styled.div`
  animation: ${BackgroundAnimation} 1.2s ease-in-out infinite;
  background: linear-gradient(90deg, #f3f3f3 0%, #e8e8e8 50%, #f3f3f3 100%);
  background-size: 400% 400%;
`;

const PlatformSkeleton = styled(SkeletonBox)`
  width: 25px;
  height: 25px;
  justify-self: center;
  border-radius: 50%;
`;

const PostSkeleton = styled(SkeletonBox)`
  width: 100px;
  height: 100px;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const CommentSkeleton = styled(SkeletonBox)<{ commentType: CommentType; isVisible: boolean }>`
  position: relative;
  padding: 15px;
  border-radius: 12px;
  max-width: 450px;
  min-width: 150px;
  display: inline-flex;
  transition: opacity 0.3s;
  transform-style: preserve-3d;

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0.5;
    `};

  &::after {
    content: "";
    transform: translateZ(-1px);
    position: absolute;
    bottom: 0px;
    left: -28px;
    height: 55px;
    width: 40px;
    animation: ${BackgroundAnimation} 1.2s ease-in-out infinite;
    background-image: linear-gradient(90deg, #f3f3f3 0%, #e8e8e8 50%, #f3f3f3 100%);
    background-attachment: fixed;
    background-position-x: center;
    background-size: 500% 500%;
    clip-path: polygon(48% 100%, 100% 100%, 100% 34%);
  }

  ${(props) =>
    props.commentType === "child" &&
    css`
      max-width: 250px;
      margin-top: 5px;
      &::after {
        bottom: 10px;
        left: -20px;
        width: 50px;
        clip-path: polygon(40% 0, 40% 40%, 100% 40%);
      }
    `}
`;

const Label = styled.div`
  width: 70%;
  height: 14px;
  border-radius: 10px;
  margin-bottom: 7px;
  background: ${(props) => props.theme.griginoTransparentLight};
  opacity: 0.5;
`;

const SkeletonLabel = styled(SkeletonText)`
  width: 70%;
  height: 14px;
  border-radius: 10px;
  margin-bottom: 7px;
`;

const SkeletonSelect = styled(SkeletonBox)<{ width: string }>`
  width: ${(props) => props.width};
  height: 32px;
`;

export {
  SkeletonBox,
  SkeletonText,
  PlatformSkeleton,
  PostSkeleton,
  CommentSkeleton,
  Label,
  SkeletonLabel,
  SkeletonSelect,
};
