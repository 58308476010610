import gql from "graphql-tag";

const MODERATE_COMMENT = gql`
  mutation moderateComment($id: CommentIdInput!, $action: ModerationAction!) {
    moderateComment(id: $id, action: $action) {
      success
    }
  }
`;

export { MODERATE_COMMENT };
