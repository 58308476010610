import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import auth from "./utils/auth";

if (process.env.NODE_ENV === "production") {
  Sentry.init({ environment: process.env.NODE_ENV, dsn: process.env.REACT_APP_SENTRY_DSN });
}

(async () => {
  await auth.renewToken();
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById("root")
  );
})();
