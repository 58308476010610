import * as React from "react";
import styled from "styled-components";
import { Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { capitalize } from "lodash";

const Chip = styled.div<{ background: string; textColor: string }>`
  padding: 3px 6px;
  background: ${(props) => props.background};
  border-radius: 15px;
  font-size: 11px;
  letter-spacing: 0.3px;
  color: ${(props) => props.textColor};
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
`;

type TypeChip = "comments" | "topic" | "reply" | "reactions" | "noValue";

interface Props {
  type: TypeChip;
  value: string | number;
  withLabel?: boolean;
  styleWrapper?: React.CSSProperties;
}

const getChipColor = (type: TypeChip) => {
  const colors = {
    comments: {
      bkg: Colors.GRIGINOlight,
      text: Colors.GRIGIO,
    },
    topic: { bkg: Colors.GREEN, text: Colors.WHITE },
    reply: { bkg: Colors.GRIGINOmedium, text: Colors.WHITE },
    reactions: { bkg: Colors.VIOLETmedium, text: Colors.WHITE },
    noValue: { bkg: Colors.GRIGINOlight, text: Colors.WHITE },
  };
  return colors[type];
};

const MiniChip: React.FC<Props> = ({ type, value, withLabel, styleWrapper }) => {
  const labelStyles: React.CSSProperties = {
    color: Colors.GRIGINOmedium,
    marginRight: 5,
    fontWeight: 500,
    fontSize: 12,
  };

  return (
    <div style={{ display: "flex", alignItems: "center", ...styleWrapper }}>
      {withLabel && (
        <Typography variantName="tooltip" style={labelStyles}>
          <>{capitalize(type)}:</>
        </Typography>
      )}
      <Chip background={getChipColor(type).bkg} textColor={getChipColor(type).text}>
        {typeof value === "string" ? capitalize(value) : value}
      </Chip>
    </div>
  );
};

MiniChip.defaultProps = {
  withLabel: true,
};

export { MiniChip };
