import * as React from "react";

type SnackbarContext = {
  open: boolean;
  message: string | React.ReactElement<any>;
  messageType: MessageType;
  openSnackbar: (message: string | React.ReactElement<any>, type: MessageType) => void;
  onClose: () => void;
};

const SnackbarContext = React.createContext<SnackbarContext>({} as SnackbarContext);

export type MessageType = "notification" | "error" | "download";

interface State {
  open: boolean;
  message: string | React.ReactElement<any>;
  messageType: MessageType;
}

const SnackbarProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<State>({
    open: false,
    message: "",
    messageType: "notification"
  });

  const openSnackbar = (message: string | React.ReactElement<any>, type: MessageType) => {
    setState({ open: true, message, messageType: type });
  };

  const onClose = () => {
    setState({ open: false, message: "", messageType: "notification" });
  };

  return (
    <SnackbarContext.Provider
      value={{
        ...state,
        openSnackbar,
        onClose
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, SnackbarContext };
