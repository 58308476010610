import styled, { keyframes } from "styled-components";
import { scrollbarVertical } from "../../ui/ScrollbarStyles";
import { ButtonIcon } from "@freeda/react-components";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 20px;
`;

const SentimentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;

  & > div:nth-child(even) {
    justify-self: flex-end;
  }
`;

const WrapperFields = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    & > div:nth-child(odd) {
      padding-right: 10px;
    }
    & > div:nth-child(even) {
      padding-left: 10px;
    }
  }
`;

const PostWrapper = styled.div<{ open: boolean }>`
  background: #ffffff;
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 10;
  border-radius: 15px 15px 0 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px 0px;
  height: ${(props) => (props.open ? "70%" : "30%")};
  transition: height 0.3s;

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    opacity: ${(props) => (props.open ? 0 : 1)};
    transition: opacity 0.5s;
  }
`;

const DetailsContainer = styled.div`
  height: calc(100% - 95px);
  overflow-y: overlay;
  padding: 0px 20px 20px;

  ${scrollbarVertical};
`;

const LabellingPanelContainer = styled.div<{ openPostPanel: boolean }>`
  height: ${(props) => (props.openPostPanel ? "30%" : "70%")};
  display: flex;
  flex-direction: column;
  transition: height 0.3s;
`;

const LabellingWrapper = styled.div`
  height: 100%;
  overflow-y: overlay;
  padding: 0px 20px 10px;

  ${scrollbarVertical};
`;

const MiniChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 20px;
  & > div {
    margin-bottom: 10px;
  }
`;

const bounceClose = keyframes`
  0%   { transform: translateX(-50%) translateY(5px); }
  50%  { transform: translateX(-50%) translateY(0px); }
  100% { transform: translateX(-50%) translateY(5px) }
`;
const bounceOpen = keyframes`
  0%   { transform: translateX(-50%) translateY(5px) rotate(180deg); }
  50%  { transform: translateX(-50%) translateY(10px) rotate(180deg); }
  100% { transform: translateX(-50%) translateY(5px) rotate(180deg) }
`;

const ArrowIcon = styled(ButtonIcon)<{ open: boolean }>`
  padding: 30px;
  top: -10px;
  position: absolute;
  left: 50%;
  transform: ${(props) =>
    props.open ? "translateX(-50%) translateY(5px) rotate(180deg)" : "translateX(-50%) translateY(5px)"};
  transition: transform 0.3s;

  &:hover {
    animation: 0.6s ${(props) => (props.open ? bounceOpen : bounceClose)} infinite;
  }
`;

export {
  Header,
  SentimentGrid,
  WrapperFields,
  PostWrapper,
  LabellingWrapper,
  MiniChipWrapper,
  ArrowIcon,
  LabellingPanelContainer,
  DetailsContainer,
};
