import gql from "graphql-tag";
import { INSTAGRAM_COMMENT } from "../fragments/comment/instagramComment";
import { INSTAGRAM_CONTENT } from "../fragments/content/instagramContent";

const GET_INSTAGRAM_CONTENT = gql`
  query getInstagramContent($id: ContentIdInput!, $offsetComments: Int!, $limitComments: Int!) {
    getInstagramContent(id: $id) {
      ...instagramContent
      comments(offset: $offsetComments, limit: $limitComments, order: DESC) {
        items {
          ...instagramComment
        }
        pageInfo {
          totalCount
        }
      }
    }
  }
  ${INSTAGRAM_COMMENT}
  ${INSTAGRAM_CONTENT}
`;

export { GET_INSTAGRAM_CONTENT };
