export const emojiList = [
  "🧝‍♀️",
  "🧝‍♂️",
  "🧟‍♀️",
  "🧟‍♂️",
  "🧜‍♀️",
  "🧜‍♂️",
  "🧛‍♀️",
  "🧛‍♂️",
  "👩🏻‍🎤",
  "👩🏾‍🎤",
  "👨🏼‍🎤",
  "👨🏿‍🎤",
  "🧘🏻‍♀️",
  "🧘🏿‍♀️",
  "🧘🏻‍♂️",
  "🧘🏿‍♂️",
  "👩🏼‍🌾",
  "👩🏾‍🌾",
  "👨🏻‍🌾",
  "👨🏾‍🌾",
  "👩🏼‍🚀",
  "👩🏽‍🚀",
  "👨🏼‍🚀",
  "👨🏾‍🚀",
  "👨🏼‍💻",
  "👨🏽‍💻",
  "👩🏾‍💻",
  "👩🏻‍💻",
  "👨🏼‍🎨",
  "👨🏾‍🎨",
  "👩🏼‍🎨",
  "👩🏿‍🎨",
  "👩🏻‍🔧",
  "👩🏾‍🔧",
  "👨🏻‍🔧",
  "👨🏾‍🔧",
  "🕵🏼‍♀️",
  "🕵🏾‍♀️",
  "🕵🏻‍♂️",
  "🕵🏽‍♂️",
  "👩🏻‍🍳",
  "👩🏽‍🍳",
  "👨🏼‍🍳",
  "👨🏿‍🍳",
  "👩🏼‍🦱",
  "👩🏾‍🦱",
  "👨🏻‍🦱",
  "👨🏿‍🦱",
  "👩🏻‍🦰",
  "👩🏾‍🦰",
  "👨🏻‍🦰",
  "👨🏾‍🦰",
  "🧖🏻‍♀️",
  "🧖🏾‍♀️",
  "🧖🏻‍♂️",
  "🧖🏾‍♂️",
  "🦄",
  "🐵",
  "🐻",
  "🐰",
  "🦊",
  "🐱",
  "🐶",
  "🐯",
  "🐨",
  "🦁",
  "🐙",
  "🦖",
  "🐝",
  "🐹"
];

export const randomNumber = () => {
  const maxNum = emojiList.length - 1;
  return Math.floor(Math.random() * (maxNum - 0 + 1) + 0);
};
