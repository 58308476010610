import * as React from "react";
import {
  ButtonIcon,
  DrawerTemporary,
  Typography,
  ListItemText,
  DividerSection,
  List,
  Icon
} from "@freeda/react-components";
import { AppBar, SidebarListItem, FirstPartMobile } from "./styles";
import { zIndex } from "../../../utils/zIndex";
import { Colors } from "@freeda/react-components/lib/theme";
import { emojiList } from "./emoji";
import { LogoutButton } from "../../ui/LogoutButton";
import { RouterLink } from "../../ui/RouterLink";
import { getLabelColor } from ".";
import { useLocation } from "react-router-dom";

interface Props {
  open: boolean;
  onChangeOpen: (open?: boolean) => void;
  indexEmojiList: number;
}

const SidebarMobile: React.FC<Props> = ({ open, onChangeOpen, indexEmojiList }) => {
  const { pathname } = useLocation();
  const isModerationClicked = pathname === "/";
  const isPostClicked = pathname === "/posts";

  const mobileLabelColor = "rgba(255, 255, 255, 0.5)";

  return (
    <>
      <AppBar>
        <ButtonIcon
          iconName="menu"
          aria-label="open drawer"
          onClick={() => onChangeOpen()}
          style={{ width: 48, height: 48 }}
        />
      </AppBar>
      <DrawerTemporary
        isOpen={open}
        openWidth={300}
        closeWidth={0}
        zIndex={zIndex.SidebarMobile}
        changeOpenSidebar={isOpen => onChangeOpen(isOpen)}
      >
        <FirstPartMobile>
          <SidebarListItem
            backgroundHover={Colors.PURPLE}
            open={open}
            style={{ height: 80, marginTop: 25, userSelect: "none" }}
          >
            <Typography variantName="title" style={{ fontSize: 30 }}>
              {emojiList[indexEmojiList]}
            </Typography>
            <ListItemText
              primary={
                <>
                  <Typography variantName="text" textColor={mobileLabelColor} style={{ paddingBottom: 2 }}>
                    Welcome,
                  </Typography>
                  <Typography
                    variantName="text"
                    textColor={mobileLabelColor}
                    style={{
                      marginRight: 15,
                      fontSize: 16,
                      fontWeight: 600,
                      paddingTop: 2
                    }}
                  >
                    {localStorage.getItem("name")!}
                  </Typography>
                </>
              }
            />
          </SidebarListItem>
          <SidebarListItem open={open} style={{ marginBottom: 10 }}>
            <LogoutButton>
              <ButtonIcon
                iconName="logout"
                iconColor={mobileLabelColor}
                style={{ cursor: "pointer", margin: 0, padding: 0 }}
              />
              <ListItemText
                primary={
                  <Typography variantName="text" textColor={mobileLabelColor} style={{ cursor: "pointer" }}>
                    Logout
                  </Typography>
                }
              />
            </LogoutButton>
          </SidebarListItem>
          <DividerSection dividerColor={Colors.WHITE} style={{ width: "calc(100% - 35px)" }} />
        </FirstPartMobile>
        <div style={{ overflowY: "scroll", height: "calc(100% - 190px)" }}>
          <List>
            <RouterLink to="/" onClick={() => onChangeOpen(false)}>
              <SidebarListItem open={open} style={{ zIndex: 1 }}>
                <Icon
                  iconName="sidebar-moderation"
                  style={{ fontSize: 38 }}
                  iconColor={getLabelColor(isModerationClicked, mobileLabelColor)}
                />
                <ListItemText
                  primary={
                    <Typography
                      variantName="text"
                      style={{ cursor: "pointer" }}
                      textColor={getLabelColor(isModerationClicked, mobileLabelColor)}
                    >
                      Comments moderation
                    </Typography>
                  }
                />
              </SidebarListItem>
            </RouterLink>
            <RouterLink to="/posts" onClick={() => onChangeOpen(false)}>
              <SidebarListItem open={open} style={{ zIndex: 1 }}>
                <Icon
                  iconName="sidebar-posts"
                  style={{ fontSize: 38 }}
                  iconColor={getLabelColor(isPostClicked, mobileLabelColor)}
                />
                <ListItemText
                  primary={
                    <Typography
                      variantName="text"
                      style={{ cursor: "pointer" }}
                      textColor={getLabelColor(isPostClicked, mobileLabelColor)}
                    >
                      Posts moderation
                    </Typography>
                  }
                />
              </SidebarListItem>
            </RouterLink>
          </List>
        </div>
      </DrawerTemporary>
    </>
  );
};

export { SidebarMobile };
