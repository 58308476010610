import * as React from "react";
import styled from "styled-components";
import { Icon } from "@freeda/react-components";
import { zIndex } from "../../utils/zIndex";
import { useMediaQuery } from "react-responsive";
const freedaLogo = require("../../assets/freeda_logo.png");

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 10px;
`;

const TechQuote = styled.div`
  font-size: 12px;
  color: #000000;
`;

const FreedaLogo = styled.img`
  height: auto;
  width: 125px;
  top: 18px;
  right: 3px;

  @media (max-width: 768px) {
    position: fixed;
    width: 100px;
    top: 5px;
    right: 5px;
    z-index: ${zIndex.LogoMobile};
  }
`;

const HeaderLogoQuote: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 950 });

  return (
    <Wrapper style={style}>
      {isDesktopOrLaptop && (
        <TechQuote>
          Designed with
          <Icon iconName="favorite" iconSize="xsmall" iconColor="#FF0A43" style={{ marginLeft: 2 }} /> by Tech Team
        </TechQuote>
      )}
      <FreedaLogo src={freedaLogo} />
    </Wrapper>
  );
};

export { HeaderLogoQuote };
