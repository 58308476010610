import * as React from "react";
import { HeaderPage, PageWrapper, LeftSection, RightSection } from "../sharedStyles";
import { CommentsPlaceholder } from "./CommentsPlaceholder";
import { PlatformSkeleton } from "./styles";
import { LabellingPlaceholder } from "./LabellingPlaceholder";
import { PostDetailsPlaceholder } from "./PostDetailsPlaceholder";

const CommentPagePlaceholder: React.FC<{ itemNotFound?: boolean }> = ({ itemNotFound }) => {
  return (
    <PageWrapper>
      <HeaderPage style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <PlatformSkeleton />
      </HeaderPage>
      <div style={{ height: "calc(100vh - 55px)", position: "relative", zIndex: -1 }}>
        <LeftSection style={{ display: "flex", justifyContent: "start" }}>
          <CommentsPlaceholder itemNotFound={itemNotFound} />
        </LeftSection>
        <RightSection>
          <LabellingPlaceholder />
          <PostDetailsPlaceholder />
        </RightSection>
      </div>
    </PageWrapper>
  );
};

export { CommentPagePlaceholder };
