import * as React from "react";
import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import { useMutation } from "@apollo/react-hooks";
import { SocialComment } from "../../../types/SocialComment";
import { CommentBox } from "../../ui/CommentBox";
import { Typography } from "@freeda/react-components";
import { useLazyQuery } from "react-apollo";
import { GET_COMMENT_WITH_CHILDREN } from "../../../apollo/queries/GetCommentWithChildren";
import { isDataAvailable } from "../../../apollo/utils/isDataAvailable";
import { CommentPlaceholder } from "../../ui/Placeholder/CommentPlaceholder";
import { PostsFilterContext } from "../../../providers/PostsFilterProvider";
import { Label } from "../../ui/Placeholder/styles";
import { ModerationAction } from "../../../types/ModerationAction";
import { getCorrectModerationMessages } from "../../../messages";
import { SnackbarContext } from "../../../providers/SnackbarProvider";
import { CommentId } from "../../../types/CommentId";
import { MODERATE_COMMENT } from "../../../apollo/mutations/ModerateComment";
import { LIMIT_NEXT_CHILDREN } from "../../../utils/limitVariablesQueries";
import { updateCachedCommentFragment } from "../../../apollo/utils/updateCache";
import { usePreviousData } from "../../../hooks/usePreviousData";

interface Props {
  parent: SocialComment;
  children: Array<SocialComment>;
  childrenCount: number;
  selectedComment: SocialComment;
  onChangeSelectedComment: (comment: SocialComment) => void;
}

const CommentsChildren: React.FC<Props> = ({
  parent,
  children,
  selectedComment,
  onChangeSelectedComment,
  childrenCount,
}) => {
  const { openSnackbar } = React.useContext(SnackbarContext);
  const { selectedCommentIdByPreview } = React.useContext(PostsFilterContext);

  const [selectedAction, setSelectedAction] = React.useState<ModerationAction | null>(null);
  const [pageCounter, setPageCounter] = React.useState<number>(0);

  React.useEffect(() => {
    // when change selectedAction - moderate selected child comment
    if (selectedAction && selectedComment) {
      const messages = getCorrectModerationMessages(selectedAction);

      const moderate = async () => {
        try {
          await moderateComment({
            variables: {
              id: selectedComment.id,
              action: selectedAction,
            },
          });
          if (!loading) {
            openSnackbar(messages.success, "notification");
          }
          setSelectedAction(null);
        } catch (err) {
          openSnackbar(messages.error, "error");
        }
      };

      moderate();
    }
  }, [selectedAction]);

  const [moderateComment, { loading }] = useMutation<
    { moderateComment: { success: Boolean } },
    { id: CommentId; action: ModerationAction }
  >(MODERATE_COMMENT, {
    update: (cache: DataProxy, result: FetchResult<{ moderateComment: { success: Boolean } }>) => {
      updateCachedCommentFragment(cache, result, selectedAction, selectedComment.id);
    },
  });

  const [getChildrenComment, commentResult] = useLazyQuery<{ getComment: SocialComment }>(
    GET_COMMENT_WITH_CHILDREN(parent.id.platformId),
    {
      fetchPolicy: "network-only",
    }
  );

  const commentResultAvailable = isDataAvailable(commentResult);

  const childrenResult: Array<SocialComment> = commentResultAvailable
    ? commentResult.data!.getComment.children.items
    : [];

  const allChildren =
    commentResultAvailable && parent.id.commentId === commentResult.data!.getComment.id.commentId
      ? [...children, ...childrenResult]
      : children;

  const disabledLoadMoreButton = childrenCount === 0 || allChildren.length === childrenCount;

  const fetchMoreChildren = async () => {
    const variables = {
      id: {
        platformId: parent.id.platformId,
        contentId: parent.id.contentId,
        commentId: parent.id.commentId,
        publisherId: parent.id.publisherId,
      },
      offsetChildren: 1,
      limitChildren: LIMIT_NEXT_CHILDREN,
    };

    // only first call
    if (!commentResult.called) {
      getChildrenComment({
        variables,
      });
    }

    if (commentResult.called) {
      try {
        await commentResult.fetchMore({
          variables: {
            ...variables,
            offsetChildren: pageCounter * LIMIT_NEXT_CHILDREN,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const newChildrenList = Object.assign({}, prev, {
              getComment: {
                ...fetchMoreResult.getComment,
                children: {
                  ...fetchMoreResult.getComment.children,
                  items: [...prev.getComment.children.items, ...fetchMoreResult.getComment.children.items],
                },
              },
            });

            return newChildrenList;
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const prevPageCounter = usePreviousData(pageCounter);

  React.useEffect(() => {
    if (prevPageCounter !== pageCounter) {
      fetchMoreChildren();
    }
  }, [pageCounter]);

  return (
    <>
      {allChildren.map((commentChild) => {
        const selectedCommentByPreview = selectedCommentIdByPreview === commentChild.id.commentId;

        const commentType =
          selectedCommentByPreview || selectedComment?.id.commentId === commentChild.id.commentId
            ? "selectedChild"
            : "child";

        return (
          <CommentBox
            key={commentChild.id.commentId}
            commentType={commentType}
            comment={commentChild}
            onSelectedComment={() => onChangeSelectedComment(commentChild)}
            loadingDelete={loading}
            onModerationComment={(action) => {
              setSelectedAction(action);
              onChangeSelectedComment(commentChild);
            }}
          />
        );
      })}

      {commentResult.loading && (
        <CommentPlaceholder commentType="child">
          <div style={{ display: "flex", flexDirection: "column", width: " 100%" }}>
            <Label style={{ width: 210 }} />
            <Label style={{ width: 150, marginBottom: 0 }} />
          </div>
        </CommentPlaceholder>
      )}
      {!disabledLoadMoreButton && (
        <div style={{ marginLeft: 95, marginTop: 8 }} onClick={() => setPageCounter((count) => count + 1)}>
          <Typography variantName="underline" style={{ cursor: "pointer" }}>
            Load other replies...
          </Typography>
        </div>
      )}
    </>
  );
};

export { CommentsChildren };
