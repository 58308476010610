import gql from "graphql-tag";
import { FACEBOOK_CONTENT } from "../fragments/content/facebookContent";
import { FACEBOOK_COMMENT } from "../fragments/comment/facebookComment";

const GET_FACEBOOK_CONTENT = gql`
  query getFacebookContent($id: ContentIdInput!, $offsetComments: Int!, $limitComments: Int!) {
    getFacebookContent(id: $id) {
      ...facebookContent
      comments(offset: $offsetComments, limit: $limitComments, order: DESC) {
        items {
          ...facebookComment
        }
        pageInfo {
          totalCount
        }
      }
    }
  }
  ${FACEBOOK_COMMENT}
  ${FACEBOOK_CONTENT}
`;

export { GET_FACEBOOK_CONTENT };
