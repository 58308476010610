import * as React from "react";
import { HeaderPanel, LateralPreviewWrapper } from "../sharedStyles";
import { useMediaQuery } from "react-responsive";
import { PlatformSkeleton, SkeletonLabel } from "./styles";
import { CommentsPlaceholder } from "./CommentsPlaceholder";
import { TopPostDetailsPlaceholder } from "./TopPostDetailsPlaceholder";

export const PreviewModerationPlaceholder: React.FC<{ width: string }> = ({ width }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 950 });

  return (
    <LateralPreviewWrapper style={{ width: isDesktopOrLaptop ? width : "100%" }}>
      <div
        style={{
          background: "#ffffff",
          height: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.05)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <HeaderPanel style={{ padding: 20 }}>
          <SkeletonLabel style={{ marginBottom: 0 }} />
          <PlatformSkeleton />
          <SkeletonLabel style={{ marginBottom: 0, justifySelf: "flex-end", width: "90%" }} />
        </HeaderPanel>
        <TopPostDetailsPlaceholder styles={{ padding: "0px 20px" }} />
        <div style={{ padding: "0px 20px", display: "flex", flexDirection: "column" }}>
          <CommentsPlaceholder />
        </div>
      </div>
    </LateralPreviewWrapper>
  );
};
