import gql from "graphql-tag";
import { Platform } from "../../types/Platform";
import { FACEBOOK_COMMENT } from "./comment/facebookComment";
import { FACEBOOK_GROUP_COMMENT } from "./comment/facebookGroupComment";
import { INSTAGRAM_COMMENT } from "./comment/instagramComment";

const SOCIAL_COMMENT = (platform: Platform) => {
  const allPlatformQueries = {
    [Platform.FB]: gql`
      fragment socialComment on FacebookComment {
        ...facebookComment
        sentiment
        sentiment_classification
        brand_quoted
        comment_type
        parent {
          ...facebookComment
        }
        previous(offset: 0, limit: 1) {
          items {
            ...facebookComment
          }
        }
        next(offset: 0, limit: 1) {
          items {
            ...facebookComment
          }
        }
      }
      ${FACEBOOK_COMMENT}
    `,
    [Platform.IG]: gql`
      fragment socialComment on InstagramComment {
        ...instagramComment
        sentiment
        sentiment_classification
        brand_quoted
        comment_type
        parent {
          ...instagramComment
        }
        previous(offset: 0, limit: 1) {
          items {
            ...instagramComment
          }
        }
        next(offset: 0, limit: 1) {
          items {
            ...instagramComment
          }
        }
      }
      ${INSTAGRAM_COMMENT}
    `,
    [Platform.FBG]: gql`
      fragment socialComment on FacebookGroupComment {
        ...facebookGroupComment
        sentiment
        sentiment_classification
        brand_quoted
        comment_type
        parent {
          ...facebookGroupComment
        }
        previous(offset: 0, limit: 2) {
          items {
            ...facebookGroupComment
          }
        }
        next(offset: 0, limit: 2) {
          items {
            ...facebookGroupComment
          }
        }
      }
      ${FACEBOOK_GROUP_COMMENT}
    `,
  };

  return allPlatformQueries[platform];
};

export { SOCIAL_COMMENT };
