import * as React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../apollo/client";
import { FreedaThemeProvider } from "@freeda/react-components";
import { CommentsFilterProvider } from "./CommentsFilterProvider";
import { PostsFilterProvider } from "./PostsFilterProvider";
import { LocationProvider } from "./LocationProvider";
import { SnackbarProvider } from "./SnackbarProvider";

const ProvidersWrapper: React.FC = ({ children }) => {
  return (
    <FreedaThemeProvider>
      <SnackbarProvider>
        <ApolloProvider client={client}>
          <CommentsFilterProvider>
            <PostsFilterProvider>
              <LocationProvider>{children}</LocationProvider>
            </PostsFilterProvider>
          </CommentsFilterProvider>
        </ApolloProvider>
      </SnackbarProvider>
    </FreedaThemeProvider>
  );
};

export { ProvidersWrapper };
