import styled from "styled-components";
import { lightScrollbarVertical } from "../../ui/ScrollbarStyles";

const WrapperList = styled.div`
  transition: all 0.3s;
  height: 100%;
  padding-right: 15px;
  padding-bottom: 5px;
  position: relative;
  overflow-y: overlay;
  overflow-x: hidden;
  ${lightScrollbarVertical};

  & > div {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    height: calc(100% - 185px);
    padding-left: 10px;
  }
`;

const WrapperCard = styled.div<{ open: boolean; selected: boolean }>`
  padding: 30px 20px;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  max-height: 140px;
  width: 100%;
  cursor: pointer;
  transition: background 0.5s;

  background: ${(props) => (props.open ? "#F3EDFF" : props.selected ? `${props.theme.azzurrinoTransparent}` : "white")};

  &:hover {
    background: ${(props) =>
      props.open ? "#F3EDFF" : props.selected ? `${props.theme.azzurrinoTransparent}` : "#dffff6"};
  }
`;

const WrapperText = styled.div<{ isFBGpost: boolean }>`
  padding-right: 40px;
  padding-bottom: ${(props) => (props.isFBGpost ? "0px" : "5px")};
  display: grid;
  align-items: center;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export { WrapperList, WrapperCard, WrapperText };
