import * as React from "react";
import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import { SocialComment } from "../../../types/SocialComment";
import { Moderation } from "./Moderation";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { GET_COMMENT } from "../../../apollo/queries/GetComment";
import { CommentId } from "../../../types/CommentId";
import { buildSocialCommentInfo } from "../../../utils/buildSocialCommentInfo";
import { useMediaQuery } from "react-responsive";
import { HeaderPanel, PlatformIcon, Caption, PostImg, LateralPreviewWrapper } from "../../ui/sharedStyles";
import { ButtonStyled } from "../../ui/ButtonStyled";
import { Colors } from "@freeda/react-components/lib/theme";
import { getPlatformIcon } from "../../../utils/getPlatformIcon";
import { isFbGroupContent, buildSocialContentInfo } from "../../../utils/buildSocialContentInfo";
import { PostDetails, MessageWrapper } from "./styles";
import { MiniChip } from "../../ui/MiniChip";
import { Typography, DividerSection } from "@freeda/react-components";
import { buildWrapText } from "../../../utils/buildWrapText";
import { useHistory } from "react-router-dom";
import { PreviewModerationPlaceholder } from "../../ui/Placeholder/PreviewModerationPlaceholder";
import { CommentsFilterContext } from "../../../providers/CommentsFilterProvider";
import { SnackbarContext } from "../../../providers/SnackbarProvider";
import { ModerationAction } from "../../../types/ModerationAction";
import { MODERATE_COMMENT } from "../../../apollo/mutations/ModerateComment";
import { getCorrectModerationMessages } from "../../../messages";
import { usePreviousData } from "../../../hooks/usePreviousData";
import { updateCachedCommentFragment } from "../../../apollo/utils/updateCache";
import { getPostImage } from "../../../utils/getPostImage";

const placeholderPost = require("../../../assets/post/placeholderPost.jpg");

interface Props {
  id: CommentId | null;
}

const CommentModeration: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 950 });

  const { openSnackbar } = React.useContext(SnackbarContext);
  const { platform } = React.useContext(CommentsFilterContext);

  const [selectedCommentId, setSelectedCommentId] = React.useState<CommentId | null>(id);
  const [selectedAction, setSelectedAction] = React.useState<ModerationAction | null>(null);

  const [moderateComment, { loading }] = useMutation<
    { moderateComment: { success: Boolean } },
    { id: CommentId; action: ModerationAction }
  >(MODERATE_COMMENT, {
    update: (cache: DataProxy, result: FetchResult<{ moderateComment: { success: Boolean } }>) => {
      if (selectedCommentId) {
        updateCachedCommentFragment(cache, result, selectedAction, selectedCommentId);
      }
    },
  });

  React.useEffect(() => {
    // when change selectedAction - moderate selected comment
    if (selectedAction && selectedCommentId) {
      const messages = getCorrectModerationMessages(selectedAction);

      const moderate = async () => {
        try {
          await moderateComment({
            variables: {
              id: selectedCommentId,
              action: selectedAction,
            },
          });
          if (!loading) {
            openSnackbar(messages.success, "notification");
          }
          setSelectedAction(null);
        } catch (err) {
          openSnackbar(messages.error, "error");
        }
      };

      moderate();
    }
  }, [selectedAction]);

  const [getComment, commentResult] = useLazyQuery<{ getComment: SocialComment }>(GET_COMMENT(platform));

  const prevCommentId = usePreviousData(id);

  React.useEffect(() => {
    if (id) {
      const { platformId, contentId, commentId, publisherId } = id;

      getComment({
        variables: {
          id: {
            platformId,
            contentId,
            commentId,
            publisherId,
          },
        },
      });

      if (id.commentId !== prevCommentId?.commentId) {
        setSelectedCommentId(id);
      }
    }
  }, [id, getComment, prevCommentId]);

  if (!id || commentResult.loading || !commentResult.data) {
    return <PreviewModerationPlaceholder width="55%" />;
  }

  const { platformId, contentId, commentId, publisherId } = id;

  const comment = commentResult.data.getComment;

  const { content } = buildSocialCommentInfo(comment);

  const { permalink, caption, title, mediaUrl, messageFbGroup, commentsCount } = buildSocialContentInfo(content);

  const fbgMessagePost = buildWrapText(messageFbGroup);

  const isFBGpost = isFbGroupContent(content);

  const imagePost = getPostImage(content);

  return (
    <LateralPreviewWrapper style={{ width: isDesktopOrLaptop ? "55%" : "100%" }}>
      <div
        style={{
          background: "#ffffff",
          height: "100%",
          boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.05)",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderPanel>
          <a href={permalink} target="__blank" style={{ textDecoration: "none" }}>
            <ButtonStyled
              style={{ justifySelf: "flex-start" }}
              icon={{ iconName: "open-link", position: "left", iconColor: Colors.GRIGINO }}
            >
              Open post
            </ButtonStyled>
          </a>
          <PlatformIcon src={getPlatformIcon(platformId)} alt="" style={{ justifySelf: "center" }} />
          <ButtonStyled
            style={{ justifySelf: "flex-end" }}
            icon={{ iconName: "expand", position: "right", iconColor: Colors.GRIGINO }}
            onClick={() => {
              history.push(
                `comment/${publisherId}?platformId=${platformId}&contentId=${contentId}&commentId=${commentId}`
              );
            }}
          >
            Fullscreen mode
          </ButtonStyled>
        </HeaderPanel>
        <PostDetails>
          {isFBGpost && imagePost && (
            <PostImg
              size="large"
              src={imagePost}
              style={{ margin: 0, alignSelf: "flex-start" }}
              onError={(e) => (e.currentTarget.src = placeholderPost)}
            />
          )}
          {!isFBGpost && (
            <PostImg size="large" src={imagePost || placeholderPost} style={{ margin: 0, alignSelf: "flex-start" }} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: isFBGpost && !mediaUrl ? 0 : 15,
              width: isFBGpost && !mediaUrl ? "100%" : "calc(100% - 100px)",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "grid" }}>
              {isFBGpost && (
                <MessageWrapper style={{ maxHeight: 68, paddingRight: 10 }}>
                  {fbgMessagePost.map((m, i) => (
                    <Typography key={i} variantName="text" style={{ fontSize: 12 }} textColor={Colors.GRIGINO}>
                      {m}
                    </Typography>
                  ))}
                </MessageWrapper>
              )}
              {!isFBGpost && title && (
                <Typography
                  variantName="text"
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {title}
                </Typography>
              )}
              {!isFBGpost && caption && (
                <Caption variantName="text" textColor={Colors.GRIGINO} style={{ fontSize: 12, paddingRight: 10 }}>
                  {caption}
                </Caption>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 10 }}>
              <MiniChip type="comments" value={commentsCount} />
            </div>
          </div>
        </PostDetails>
        <DividerSection
          style={{ margin: "15px 0px", marginBottom: 0, position: "relative", zIndex: 1, padding: "0px 20px" }}
        />
        <Moderation
          maxWidth={500}
          mainComment={comment}
          selectedCommentId={selectedCommentId || comment.id}
          onChangeSelectedComment={(comment) => setSelectedCommentId(comment.id)}
          onChangeSelectedAction={(action) => setSelectedAction(action)}
          loadingDelete={loading}
        />
      </div>
    </LateralPreviewWrapper>
  );
};

export { CommentModeration };
