import gql from "graphql-tag";
import { FACEBOOK_GROUP_COMMENT } from "../fragments/comment/facebookGroupComment";
import { FACEBOOK_GROUP_CONTENT } from "../fragments/content/facebookGroupContent";

const LIST_FACEBOOK_GROUP_CONTENTS = gql`
  query listFacebookGroupContents(
    $publisherId: PublisherIdInput!
    $offset: Int!
    $limit: Int!
    $since: DateTime
    $until: DateTime
    $offsetComments: Int!
    $limitComments: Int!
  ) {
    listFacebookGroupContents(publisherId: $publisherId, offset: $offset, limit: $limit, since: $since, until: $until) {
      items {
        ...facebookGroupContent
        comments(offset: $offsetComments, limit: $limitComments, order: DESC) {
          items {
            ...facebookGroupComment
          }
          pageInfo {
            totalCount
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${FACEBOOK_GROUP_COMMENT}
  ${FACEBOOK_GROUP_CONTENT}
`;

export { LIST_FACEBOOK_GROUP_CONTENTS };
