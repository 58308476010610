import * as React from "react";
import { Route, Redirect, RouteComponentProps } from "react-router";

const PrivateRoute: React.FC<{
  Component: ((props: RouteComponentProps<any>) => React.ReactElement<any> | null) | React.ReactElement<any>;
  isAuthenticated: Function;
  exact?: boolean;
  path: string;
}> = ({ Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props: any) => {
      return isAuthenticated() ? (
        Component instanceof Function ? (
          <Component {...props} />
        ) : (
          Component
        )
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
