import * as React from "react";
import { ModerationWrapper, ModerationTransition } from "./styles";
import { CommentBox, getCommentType } from "../../ui/CommentBox";
import { SocialComment } from "../../../types/SocialComment";
import { buildSocialCommentInfo } from "../../../utils/buildSocialCommentInfo";
import { v4 } from "uuid";
import { ModerationAction } from "../../../types/ModerationAction";
import { CommentId } from "../../../types/CommentId";

interface Props {
  mainComment: SocialComment;
  selectedCommentId: CommentId;
  onChangeSelectedComment: (comment: SocialComment) => void;
  onChangeSelectedAction: (action: ModerationAction) => void;
  loadingDelete: boolean;
  style?: React.CSSProperties;
  maxWidth?: number;
}

const Moderation: React.FC<Props> = ({
  mainComment,
  selectedCommentId,
  onChangeSelectedComment,
  onChangeSelectedAction,
  loadingDelete,
  style,
  maxWidth,
}) => {
  const wrapperId = React.useRef(v4());

  React.useEffect(() => {
    const wrapperDiv = document.getElementById(wrapperId.current);
    const primaryComment = document.getElementById(mainComment.id.commentId);

    if (primaryComment && wrapperDiv) {
      const top = primaryComment.offsetTop - 15;
      wrapperDiv.scroll({ top, behavior: "smooth" });
    }
  }, [mainComment]);

  const { previous, next, parent, children } = buildSocialCommentInfo(mainComment);

  const commentIsChild = Boolean(mainComment.parent);
  const commentHasReply = children && children.items.length > 0;

  return (
    <ModerationTransition style={style} id={wrapperId.current}>
      <ModerationWrapper maxWidth={maxWidth}>
        {!commentIsChild &&
          previous &&
          previous.items.length > 0 &&
          previous.items.map((c) => (
            <CommentBox
              key={c.id.commentId}
              commentType={selectedCommentId.commentId === c.id.commentId ? "selected" : "default"}
              comment={c}
              onSelectedComment={() => onChangeSelectedComment(c)}
              onModerationComment={(action) => {
                onChangeSelectedComment(c);
                onChangeSelectedAction(action);
              }}
              loadingDelete={loadingDelete}
            />
          ))}

        {parent && (
          <CommentBox
            commentType={selectedCommentId.commentId === parent.id.commentId ? "selected" : "parent"}
            comment={parent}
            style={{ marginBottom: 0 }}
            onSelectedComment={() => onChangeSelectedComment(parent)}
            onModerationComment={(action) => {
              onChangeSelectedComment(parent);
              onChangeSelectedAction(action);
            }}
            loadingDelete={loadingDelete}
          />
        )}

        {commentIsChild &&
          previous &&
          previous.items.length > 0 &&
          previous.items.map((c) => (
            <CommentBox
              key={c.id.commentId}
              commentType={selectedCommentId.commentId === c.id.commentId ? "selectedChild" : "child"}
              style={{ marginBottom: commentHasReply || commentIsChild ? 0 : "" }}
              comment={c}
              onSelectedComment={() => onChangeSelectedComment(c)}
              onModerationComment={(action) => {
                onChangeSelectedComment(c);
                onChangeSelectedAction(action);
              }}
              loadingDelete={loadingDelete}
            />
          ))}

        <CommentBox
          commentType={getCommentType(mainComment, selectedCommentId)}
          comment={mainComment}
          style={{ marginBottom: commentHasReply || commentIsChild ? 0 : "" }}
          onSelectedComment={() => onChangeSelectedComment(mainComment)}
          onModerationComment={(action) => {
            onChangeSelectedComment(mainComment);
            onChangeSelectedAction(action);
          }}
          loadingDelete={loadingDelete}
        />

        {commentIsChild &&
          next &&
          next.items.length > 0 &&
          next.items.map((c) => (
            <CommentBox
              key={c.id.commentId}
              commentType={selectedCommentId.commentId === c.id.commentId ? "selectedChild" : "child"}
              comment={c}
              style={{ marginBottom: 0 }}
              onSelectedComment={() => onChangeSelectedComment(c)}
              onModerationComment={(action) => {
                onChangeSelectedComment(c);
                onChangeSelectedAction(action);
              }}
              loadingDelete={loadingDelete}
            />
          ))}

        {commentHasReply &&
          children.items.map((c) => (
            <CommentBox
              key={c.id.commentId}
              commentType={selectedCommentId.commentId === c.id.commentId ? "selectedChild" : "child"}
              comment={c}
              onSelectedComment={() => onChangeSelectedComment(c)}
              onModerationComment={(action) => {
                onChangeSelectedComment(c);
                onChangeSelectedAction(action);
              }}
              loadingDelete={loadingDelete}
            />
          ))}

        {!commentIsChild &&
          next &&
          next.items.length > 0 &&
          next.items.map((c) => (
            <CommentBox
              key={c.id.commentId}
              commentType={selectedCommentId.commentId === c.id.commentId ? "selected" : "default"}
              comment={c}
              onSelectedComment={() => onChangeSelectedComment(c)}
              onModerationComment={(action) => {
                onChangeSelectedComment(c);
                onChangeSelectedAction(action);
              }}
              loadingDelete={loadingDelete}
            />
          ))}
      </ModerationWrapper>
    </ModerationTransition>
  );
};

export { Moderation };
