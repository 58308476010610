import gql from "graphql-tag";
import { SOCIAL_COMMENT } from "../fragments/socialCommentFragment";
import { Platform } from "../../types/Platform";

const GET_COMMENT = (platform: Platform) => gql`
  query getComment($id: CommentIdInput!) {
    getComment(id: $id) {
      ...socialComment
    }
  }
  ${SOCIAL_COMMENT(platform)}
`;

export { GET_COMMENT };
