import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import { SOCIAL_COMMENT } from "../fragments/socialCommentFragment";
import { SocialComment } from "../../types/SocialComment";
import { ModerationAction } from "../../types/ModerationAction";
import { CommentId } from "../../types/CommentId";
import { lowerFirst } from "lodash";
import { buildSocialFragmentName } from "./buildSocialFragmentName";

export const updateCachedCommentFragment = (
  cache: DataProxy,
  resultModeration: FetchResult<{ moderateComment: { success: Boolean } }>,
  selectedAction: ModerationAction | null,
  selectedCommentId: CommentId
) => {
  const platform = selectedCommentId.platformId;

  const fragmentName = buildSocialFragmentName(platform, "comment");

  const fragment = {
    // `id` is any id that could be returned by `dataIdFromObject`.
    id: `${fragmentName}:${selectedCommentId.commentId}`,
    fragment: SOCIAL_COMMENT(platform),
    fragmentName: lowerFirst(fragmentName),
  };

  const cachedComment = cache.readFragment<SocialComment>(fragment);

  if (resultModeration.data && cachedComment) {
    if (resultModeration.data.moderateComment.success && selectedAction) {
      let updatedComment: SocialComment = cachedComment;

      if (selectedAction === ModerationAction.DELETE) {
        updatedComment = {
          ...cachedComment,
          user_deleted: true,
        };
      } else {
        updatedComment = {
          ...cachedComment,
          user_hidden: !cachedComment.user_hidden,
        };
      }

      cache.writeFragment({
        ...fragment,
        data: updatedComment,
      });
    }
  }
};
