import * as React from "react";
import styled, { css } from "styled-components";
import { Button, Icon } from "@freeda/react-components";
import { ModerationAction } from "../../types/ModerationAction";
import { CommentType } from "./CommentBox";

const ButtonStyled = styled(Button)<{ selected: boolean }>`
  text-transform: capitalize;
  font-weight: 100;
  transition: background 0.2s;
  height: 32px;
  font-size: 12px;
  min-width: 45px;
  padding: 0px;

  & > span {
    margin: 0px !important;
  }

  &:hover {
    background: ${(props) => !props.disabled && (props.selected ? props.theme.primary : "rgb(186, 140, 255)")};
    & > span > i {
      color: ${(props) => !props.disabled && props.theme.fontColors.white};
    }
  }

  ${(props) =>
    props.selected &&
    css`
      background: ${props.theme.primary};
    `}

  ${(props) =>
    !props.selected &&
    css`
      background: #ffffff;
      & > span > i {
        color: ${(props) => props.theme.primary};
      }
    `}
`;

const IconStyled = styled(Icon)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? props.theme.fontColors.white : props.theme.primary)};
  position: relative;
  top: -1px;

  &:hover {
    color: ${(props) => props.theme.fontColors.white};
  }
`;

const WrapperButton = styled.div<{ commentType: CommentType }>`
  margin-left: 15px;
  display: flex;
  align-items: center;
  & button {
    border: 1px solid #8e8e8e;
  }
  & button:not(:last-child) {
    border-right: none;
  }

  ${(props) =>
    (props.commentType === "child" || props.commentType === "selectedChild") &&
    css`
      position: relative;
      top: 8px;
    `};
`;

interface Props {
  isHidden: boolean;
  onChangeHideComment: (action: ModerationAction) => void;
  style?: React.CSSProperties;
  commentType: CommentType;
}

const ModerationButton: React.FC<Props> = ({ isHidden, onChangeHideComment, style, commentType }) => {
  return (
    <WrapperButton style={style} commentType={commentType}>
      <ButtonStyled selected={!isHidden} onClick={() => onChangeHideComment(ModerationAction.UNHIDE)}>
        <IconStyled iconName="show" selected={!isHidden} style={{ fontSize: 30, height: 30 }} />
      </ButtonStyled>
      <ButtonStyled selected={isHidden} onClick={() => onChangeHideComment(ModerationAction.HIDE)}>
        <IconStyled iconName="hidden" selected={isHidden} style={{ fontSize: 30, height: 30 }} />
      </ButtonStyled>
    </WrapperButton>
  );
};

export { ModerationButton };
