import * as React from "react";
import { CheckBox } from "@freeda/react-components";
import { Checkbox } from "@freeda/react-components/lib/Checkbox";

type Props = Checkbox.Props & {
  selectAll: boolean;
};

const CheckboxMultiSelect: React.FC<Props> = ({ checked, onClick, selectAll, disabled }) => {
  return (
    <CheckBox checked={checked} onClick={onClick} iconName={!selectAll ? "remove" : "check"} disabled={disabled} />
  );
};

export { CheckboxMultiSelect };
