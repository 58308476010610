import * as React from "react";
import { Button } from "@freeda/react-components";
import styled, { css } from "styled-components";
import { Button as ButtonP } from "@freeda/react-components/lib/Button";

const BStyled = styled(Button)`
  color: ${(props) => props.theme.secondary};
  padding: 5px;
  user-select: none;
  white-space: nowrap;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${(props) =>
    props.loading &&
    css`
      & > div > div {
        border-color: ${props.theme.primary};
        border-right-color: transparent;
      }
    `};
`;

const ButtonStyled: React.FC<ButtonP.Props> = ({ children, action, loading, onClick, icon, style, disabled }) => {
  return (
    <BStyled
      flat={true}
      action={action}
      loading={loading}
      onClick={onClick}
      icon={icon}
      style={style}
      disabled={disabled}
    >
      {children}
    </BStyled>
  );
};

export { ButtonStyled };
