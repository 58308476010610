import { SocialContent } from "../types/SocialContent";
import { FacebookContent } from "../types/FacebookContent";
import { Platform } from "../types/Platform";
import { InstagramContent } from "../types/InstagramContent";
import { FacebookGroupContent } from "../types/FacebookGroupContent";
import { ContentId } from "../types/ContentId";

export const isFbContent = (content: SocialContent): content is FacebookContent => {
  return content.id.platformId === Platform.FB;
};
export const isIgContent = (content: SocialContent): content is InstagramContent => {
  return content.id.platformId === Platform.IG;
};
export const isFbGroupContent = (content: SocialContent): content is FacebookGroupContent => {
  return content.id.platformId === Platform.FBG;
};

export interface SocialContentInfo {
  id: ContentId;
  title?: string | null;
  caption?: string;
  messageFbGroup?: string;
  publicationDate: number;
  mediaUrl: string | null;
  permalink: string;
  commentsCount: number;
}

export const buildSocialContentInfo = (selectedPost: SocialContent): SocialContentInfo => {
  if (isFbContent(selectedPost)) {
    return {
      id: selectedPost.id,
      title: selectedPost.title,
      caption: selectedPost.caption,
      publicationDate: selectedPost.publicationDate,
      mediaUrl: selectedPost.mediaUrl,
      permalink: selectedPost.permalink,
      commentsCount: selectedPost.comments.pageInfo.totalCount,
    };
  }
  if (isIgContent(selectedPost)) {
    return {
      id: selectedPost.id,
      caption: selectedPost.caption,
      publicationDate: selectedPost.publicationDate,
      mediaUrl: selectedPost.mediaUrl || selectedPost.thumbnailUrl || selectedPost.thumbnailUrlBackup,
      permalink: selectedPost.permalink,
      commentsCount: selectedPost.comments.pageInfo.totalCount,
    };
  }

  return {
    id: selectedPost.id,
    title: selectedPost.title,
    messageFbGroup: selectedPost.message,
    publicationDate: selectedPost.publicationDate,
    permalink: selectedPost.permalink,
    mediaUrl: selectedPost.mediaUrl,
    commentsCount: selectedPost.comments.pageInfo.totalCount,
  };
};
