import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { PostsAdvancedFilter } from "./PostsAdvancedFilter";
import { AdvancedMobileFilter } from "../Mobile/AdvancedMobileFilter";
import { CSSTransition } from "react-transition-group";
import { useFilterMobile } from "../../../../hooks/useFilterMobile";
import { PostsFilterContext } from "../../../../providers/PostsFilterProvider";
import { ButtonIcon } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

const PostsFilter: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { onCloseFilterMobile, toggleShowFilter, showFilterMobile } = useFilterMobile();

  const { onClearFilter } = React.useContext(PostsFilterContext);

  const desktopFilter = <PostsAdvancedFilter />;

  const mobileFilter = (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "10px 15px" }}>
        <ButtonIcon
          iconName="filter-list"
          style={{ top: 15, right: 20, zIndex: 1 }}
          onClick={toggleShowFilter}
          iconColor={Colors.PURPLE}
        />
      </div>
      <CSSTransition in={showFilterMobile} timeout={{ enter: 200, exit: 500 }} unmountOnExit>
        {(status) => (
          <AdvancedMobileFilter
            onClearFilter={onClearFilter}
            showFilterMobile={showFilterMobile}
            onCloseFilter={onCloseFilterMobile}
            transitionStatus={status}
            filter={desktopFilter}
          />
        )}
      </CSSTransition>
    </>
  );

  if (!isMobile) {
    return desktopFilter;
  } else {
    return mobileFilter;
  }
};

export { PostsFilter };
