import styled, { css } from "styled-components";
import { Input } from "@freeda/react-components";

const FilterWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.azzurrino};
  box-sizing: border-box;
  margin-top: 75px;
  position: relative;

  & > div {
    padding: 0px 15px;
  }

  @media (max-width: 768px) {
    margin-top: 55px;
  }
`;

const InputSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 15px 20px;
    background: ${(props) => props.theme.azzurrino};
    margin: 0px;
    z-index: 21;
    position: relative;
  }
`;

const InputStyled = styled(Input)`
  && {
    & > div > input {
      border-color: ${(props) => props.theme.primary};
    }
  }
`;

const TransparentDiv = styled.div<{ position: "right" | "left" }>`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 20px;
  z-index: 100;
  pointer-events: none;

  ${(props) =>
    props.position === "left" &&
    css`
      left: 0px;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(${props.theme.azzurrino}),
        to(rgba(255, 255, 255, 0))
      );
      background: linear-gradient(90deg, ${props.theme.azzurrino} 0, rgba(255, 255, 255, 0) 100%);
    `}

  ${(props) =>
    props.position === "right" &&
    css`
      right: 0px;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(255, 255, 255, 0)),
        to(${props.theme.azzurrino})
      );
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, ${props.theme.azzurrino} 100%);
    `}
`;

export { FilterWrapper, InputStyled, InputSearchWrapper, TransparentDiv };
