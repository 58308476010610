import gql from "graphql-tag";
import { COMMENT_ID_FRAGMENT } from "../commentIdFragment";
import { INSTAGRAM_CONTENT } from "../content/instagramContent";

const INSTAGRAM_COMMENT = gql`
  fragment instagramComment on InstagramComment {
    id {
      ...commentId
    }
    createdTime
    text
    removed
    user_deleted
    user_hidden
    reactions {
      like
    }
    labels {
      project
      label
    }
    content {
      ...instagramContent
    }
  }
  ${COMMENT_ID_FRAGMENT}
  ${INSTAGRAM_CONTENT}
`;

export { INSTAGRAM_COMMENT };
