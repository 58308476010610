import * as React from "react";
import styled from "styled-components";
import { CommentPlaceholder } from "./CommentPlaceholder";
import { Label } from "./styles";
import { Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

const WrapperComments = styled.div`
  padding-top: 35px;
  margin: 0 auto;
  & > div {
    margin-bottom: 15px;
  }
`;

const CommentsPlaceholder: React.FC<{ itemNotFound?: boolean }> = ({ itemNotFound }) => {
  const label = itemNotFound ? (
    <>
      Mmh... I'm searching the comments but not found nothing... <span>🥺</span>
    </>
  ) : (
    <>
      I'm searching the comments... <span>😎</span>
    </>
  );

  return (
    <WrapperComments>
      {!itemNotFound && (
        <>
          <CommentPlaceholder labelWidth={200} />
          <CommentPlaceholder labelWidth={300} commentType="child" />
          <CommentPlaceholder>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Label style={{ width: 210 }} />
              <Label style={{ marginBottom: 0, width: 150 }} />
            </div>
          </CommentPlaceholder>
        </>
      )}
      <Typography
        variantName="label"
        textColor={Colors.GRIGINO}
        style={{ marginLeft: itemNotFound ? 0 : 50, userSelect: "none", whiteSpace: "normal", textAlign: "center" }}
      >
        {label}
      </Typography>
    </WrapperComments>
  );
};

export { CommentsPlaceholder };
