import * as React from "react";
import { Dialog as FreedaDialog } from "@freeda/react-components";
import { Dialogable } from "../../../types/Dialogable";
import { Header } from "./Header";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
  width: 100%;

  ${(props) =>
    props.isMobile &&
    css`
      position: absolute;
      bottom: 20px;
      right: 20px;
    `}
`;

type Props = Dialogable & {
  title: string;
  style?: React.CSSProperties;
  buttonSection: JSX.Element;
};

const Dialog: React.FC<Props> = ({ open, onClose, title, style, buttonSection, children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <FreedaDialog
      openDialog={open}
      onClose={onClose}
      rootIdAttributes="root"
      fullScreen={isMobile}
      style={{
        width: !isMobile ? 400 : "",
        display: "inline-flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        ...style,
      }}
    >
      <Header title={title} onClose={onClose} />
      {children}
      <ButtonWrapper isMobile={isMobile}>{buttonSection}</ButtonWrapper>
    </FreedaDialog>
  );
};

export { Dialog };
