import gql from "graphql-tag";
import { Platform } from "../../types/Platform";
import { SOCIAL_COMMENT } from "../fragments/socialCommentFragment";

const LIST_COMMENTS_BY_PUBLISHER = (platform: Platform) => {
  return gql`
    query listCommentsByPublisher(
      $publisherId: PublisherIdInput!
      $offset: Int!
      $limit: Int!
      $order: Order!
      $classified: Boolean
    ) {
      listCommentsByPublisher(
        publisherId: $publisherId
        offset: $offset
        limit: $limit
        order: $order
        classified: $classified
      ) {
        items {
          ...socialComment
        }
        pageInfo {
          totalCount
        }
      }
    }
    ${SOCIAL_COMMENT(platform)}
  `;
};

export { LIST_COMMENTS_BY_PUBLISHER };
