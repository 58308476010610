import * as React from "react";
import { PublisherId } from "../types/PublisherId";
import { Platform } from "../types/Platform";
import { SocialContent } from "../types/SocialContent";
import { DateRange } from "../types/DataRange";
import { FixedDateRange } from "../types/FixedDateRange";
import { usePreviousData } from "../hooks/usePreviousData";

type PostsFilterContext = {
  publisherId: PublisherId;
  platform: Platform;
  selectedPost: SocialContent | null;
  dateRange: DateRange;
  selectedCommentIdByPreview: string | null;
  currentPage: number;
  onChangePublisherId: (publisherId: PublisherId) => void;
  onChangePlatform: (platform: Platform) => void;
  onChangeSelectedPost: (post: SocialContent | null) => void;
  onChangeDateRange: (dateRange: DateRange) => void;
  onChangeSelectedCommentIdByPreview: (commentId: string | null) => void;
  onClearFilter: () => void;
  onChangePage: (totalPages: number) => void;
  onBackPage: () => void;
  onNextPage: (totalPages: number) => void;
};

const initialState = {
  publisherId: { platformId: Platform.FBG, publisherId: "" },
  platform: Platform.FBG,
  selectedPost: null,
  dateRange: FixedDateRange.LAST_24H,
  selectedCommentIdByPreview: null,
  currentPage: 0,
};

const PostsFilterContext = React.createContext<PostsFilterContext>({} as PostsFilterContext);

const PostsFilterProvider: React.FC = ({ children }) => {
  const [publisherId, setPublisherId] = React.useState<PublisherId>(initialState.publisherId);
  const [platform, setPlatform] = React.useState<Platform>(initialState.platform);
  const [selectedPost, setSelectedPost] = React.useState<SocialContent | null>(initialState.selectedPost);
  const [dateRange, setDateRange] = React.useState<DateRange>(initialState.dateRange);
  const [selectedCommentIdByPreview, setSelectedCommentIdByPreview] = React.useState<string | null>(
    initialState.selectedCommentIdByPreview
  );
  const [currentPage, setCurrentPage] = React.useState<number>(initialState.currentPage);

  const prevPublisherId = usePreviousData(publisherId);

  React.useEffect(() => {
    if (prevPublisherId.publisherId !== publisherId.publisherId) {
      setSelectedPost(initialState.selectedPost);
      setDateRange(initialState.dateRange);
    }
  }, [publisherId]);

  const onChangePublisherId = (publisherId: PublisherId) => {
    setPublisherId(publisherId);
  };

  const onChangePlatform = (platform: Platform) => {
    setPlatform(platform);
    setSelectedPost(initialState.selectedPost);
    setDateRange(initialState.dateRange);
  };

  const onChangeSelectedPost = (post: SocialContent | null) => setSelectedPost(post);

  const onChangeDateRange = (dateRange: DateRange) => {
    setDateRange(dateRange);
    setSelectedPost(initialState.selectedPost);
  };

  const onChangeSelectedCommentIdByPreview = (commentId: string | null) => setSelectedCommentIdByPreview(commentId);

  const onClearFilter = () => {
    setPublisherId(initialState.publisherId);
    setSelectedPost(initialState.selectedPost);
    setDateRange(initialState.dateRange);
    setSelectedCommentIdByPreview(initialState.selectedCommentIdByPreview);
    setCurrentPage(initialState.currentPage);
  };

  const onChangePage = (page: number) => setCurrentPage(page);

  const onBackPage = () => setCurrentPage((page) => (page === 0 ? 0 : page - 1));

  const onNextPage = (totalPages: number) =>
    setCurrentPage((page) => (totalPages - 1 === totalPages ? page : page + 1));

  return (
    <PostsFilterContext.Provider
      value={{
        publisherId,
        platform,
        selectedPost,
        dateRange,
        selectedCommentIdByPreview,
        currentPage,
        onChangePublisherId,
        onChangePlatform,
        onChangeSelectedPost,
        onChangeDateRange,
        onChangeSelectedCommentIdByPreview,
        onClearFilter,
        onChangePage,
        onBackPage,
        onNextPage,
      }}
    >
      {children}
    </PostsFilterContext.Provider>
  );
};

export { PostsFilterContext, PostsFilterProvider };
