import styled from "styled-components";

const WrapperListFilter = styled.div`
  position: relative;
  height: 65px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding 0.3s;

  @media (max-width: 768px) {
    padding-right: 5px;
    padding-left: 30px;
  }
`;

const WrapperActions = styled.div`
  display: flex;
  align-items: center;
`;

export { WrapperListFilter, WrapperActions };
