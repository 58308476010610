import gql from "graphql-tag";
import { CONTENT_ID_FRAGMENT } from "../contentIdFragment";

const FACEBOOK_CONTENT = gql`
  fragment facebookContent on FacebookContent {
    id {
      ...contentId
    }
    type
    publicationDate
    title
    caption
    permalink
    mediaUrl
    comments(offset: 0, limit: 1) {
      pageInfo {
        totalCount
      }
    }
  }
  ${CONTENT_ID_FRAGMENT}
`;

export { FACEBOOK_CONTENT };
