import styled, { css } from "styled-components";
import { lightScrollbarVertical } from "../../ui/ScrollbarStyles";

const WrapperList = styled.div`
  transition: all 0.3s;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 10px;
  padding-right: 15px;
  position: relative;

  ${lightScrollbarVertical};

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    height: calc(100% - 185px);
    padding-left: 10px;
  }
`;

const WrapperCard = styled.div<{ isCategorize: boolean; selected: boolean; open: boolean }>`
  padding: 30px 20px;
  padding-left: 5px;
  border-radius: 8px;
  background: white;
  display: flex;
  position: relative;
  overflow: hidden;
  max-height: 110px;
  width: 100%;
  transition: background 0.5s;
  cursor: pointer;

  ${(props) =>
    !props.isCategorize &&
    css`
      background: ${props.theme.griginoTransparentLight};
    `};

  background: ${(props) => (props.open ? "#F3EDFF" : props.selected ? `${props.theme.azzurrinoTransparent}` : "white")};

  &:hover {
    background: ${(props) =>
      props.open ? "#F3EDFF" : props.selected ? `${props.theme.azzurrinoTransparent}` : "#dffff6"};
  }
`;

const WrapperText = styled.div`
  padding-right: 40px;
  display: grid;
  align-items: center;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export { WrapperList, WrapperCard, WrapperText };
