import { CONTENT_ID_FRAGMENT } from "../contentIdFragment";
import gql from "graphql-tag";

const INSTAGRAM_CONTENT = gql`
  fragment instagramContent on InstagramContent {
    id {
      ...contentId
    }
    type
    publicationDate
    isStory
    caption
    permalink
    mediaUrl
    thumbnailUrl
    thumbnailUrlBackup
    comments(offset: 0, limit: 1) {
      pageInfo {
        totalCount
      }
    }
  }
  ${CONTENT_ID_FRAGMENT}
`;

export { INSTAGRAM_CONTENT };
