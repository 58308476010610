import * as React from "react";
import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import { ListComments, ArrowUp, WrapperListComments } from "./styles";
import { CommentBox } from "../../ui/CommentBox";
import { SocialComment } from "../../../types/SocialComment";
import { ButtonStyled } from "../../ui/ButtonStyled";
import { CommentsChildren } from "./CommentsChildren";
import { CSSTransition } from "react-transition-group";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { v4 } from "uuid";
import { PostsFilterContext } from "../../../providers/PostsFilterProvider";
import { useMutation } from "@apollo/react-hooks";
import { CommentId } from "../../../types/CommentId";
import { ModerationAction } from "../../../types/ModerationAction";
import { MODERATE_COMMENT } from "../../../apollo/mutations/ModerateComment";
import { SnackbarContext } from "../../../providers/SnackbarProvider";
import { getCorrectModerationMessages } from "../../../messages";
import { updateCachedCommentFragment } from "../../../apollo/utils/updateCache";

interface Props {
  comments: Array<SocialComment>;
  commentsPerPage: number;
  onFetchMoreNextComments: () => void;
  loadingFetchComments: boolean;
  disabledNextButton: boolean;
  selectedComment: SocialComment;
  onChangeSelectedComment: (comment: SocialComment) => void;
}

const PostCommentsModeration: React.FC<Props> = ({
  comments,
  commentsPerPage,
  onFetchMoreNextComments,
  loadingFetchComments,
  disabledNextButton,
  selectedComment,
  onChangeSelectedComment,
}) => {
  const { windowHeight } = useWindowSize();

  const listCommentsId = React.useRef(v4());

  const { openSnackbar } = React.useContext(SnackbarContext);
  const { selectedCommentIdByPreview, onChangeSelectedCommentIdByPreview } = React.useContext(PostsFilterContext);

  const selectedCommentByPreview = comments.find((c) => c.id.commentId === selectedCommentIdByPreview);

  const [showScrollTopButton, setShowScrollTopButton] = React.useState<boolean>(false);

  const [selectedAction, setSelectedAction] = React.useState<ModerationAction | null>(null);

  React.useEffect(() => {
    if (selectedCommentByPreview) {
      onChangeSelectedComment(selectedCommentByPreview);
    }
  }, [selectedCommentByPreview, onChangeSelectedComment]);

  const [moderateComment, { loading }] = useMutation<
    { moderateComment: { success: Boolean } },
    { id: CommentId; action: ModerationAction }
  >(MODERATE_COMMENT, {
    update: (cache: DataProxy, result: FetchResult<{ moderateComment: { success: Boolean } }>) => {
      updateCachedCommentFragment(cache, result, selectedAction, selectedComment.id);
    },
  });

  React.useEffect(() => {
    if (selectedCommentIdByPreview) {
      const wrapperDiv = document.getElementById(listCommentsId.current);
      const selectedComment = document.getElementById(selectedCommentIdByPreview);

      if (wrapperDiv && selectedComment) {
        const top = selectedComment.offsetTop - 15;
        wrapperDiv.scroll({ top, behavior: "smooth" });
      }
    }

    const listComments = document.getElementById(listCommentsId.current);

    const onListScroll = (_: Event) => {
      if (listComments) {
        setShowScrollTopButton(listComments.scrollTop > (windowHeight / 3) * 2);
      }
    };

    listComments?.addEventListener("scroll", onListScroll);
    return () => listComments?.removeEventListener("scroll", onListScroll);
  }, []);

  React.useEffect(() => {
    // when change selectedAction - moderate selected comment
    if (selectedAction && selectedComment) {
      const messages = getCorrectModerationMessages(selectedAction);

      const moderate = async () => {
        try {
          await moderateComment({
            variables: {
              id: selectedComment.id,
              action: selectedAction,
            },
          });
          if (!loading) {
            openSnackbar(messages.success, "notification");
          }
          setSelectedAction(null);
        } catch (err) {
          openSnackbar(messages.error, "error");
        }
      };

      moderate();
    }
  }, [selectedAction]);

  const onScrollUp = () => {
    const listComments = document.getElementById(listCommentsId.current);

    listComments?.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <WrapperListComments id={listCommentsId.current}>
          <ListComments>
            {comments.length > 0 &&
              comments.map((comment) => {
                const commentType = selectedComment.id.commentId === comment.id.commentId ? "selected" : "default";
                const childrenList: Array<SocialComment> = comment.children.items;
                const childrenCount = comment.children.pageInfo.totalCount;

                return (
                  <div key={comment.id.commentId} style={{ marginBottom: childrenList.length > 0 ? 35 : "" }}>
                    <CommentBox
                      commentType={commentType}
                      comment={comment}
                      loadingDelete={loading}
                      onModerationComment={(action) => {
                        onChangeSelectedComment(comment);
                        setSelectedAction(action);
                      }}
                      onSelectedComment={() => {
                        onChangeSelectedComment(comment);
                        onChangeSelectedCommentIdByPreview(null);
                      }}
                    />
                    <CommentsChildren
                      children={childrenList}
                      childrenCount={childrenCount}
                      selectedComment={selectedComment}
                      onChangeSelectedComment={(comment) => {
                        onChangeSelectedComment(comment);
                        onChangeSelectedCommentIdByPreview(null);
                      }}
                      parent={comment}
                    />
                  </div>
                );
              })}

            <ButtonStyled
              style={{ margin: "0 auto", marginBottom: 15 }}
              disabled={disabledNextButton}
              loading={loadingFetchComments}
              onClick={onFetchMoreNextComments}
            >
              {!disabledNextButton ? `See ${commentsPerPage} more comments` : "No more comments"}
            </ButtonStyled>
          </ListComments>
        </WrapperListComments>
        <CSSTransition in={showScrollTopButton} timeout={{ enter: 100, exit: 400 }} unmountOnExit>
          {(status) => (
            <ArrowUp className={status} iconName="arrow-up" iconColor="white" iconSize="small" onClick={onScrollUp} />
          )}
        </CSSTransition>
      </div>
    </>
  );
};

export { PostCommentsModeration };
