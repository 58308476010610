import gql from "graphql-tag";

export const COMMENT_ID_FRAGMENT = gql`
  fragment commentId on CommentId {
    platformId
    publisherId
    contentId
    commentId
  }
`;
