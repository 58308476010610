import * as React from "react";
import { Dialog } from "./Dialog";
import { Dialogable } from "../../../types/Dialogable";
import { Button, Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { ModerationAction } from "../../../types/ModerationAction";

type Props = Dialogable & {
  onDeleteComment: (action: ModerationAction) => void;
  loadingDelete: boolean;
};

const DeleteCommentDialog: React.FC<Props> = ({ open, onClose, onDeleteComment, loadingDelete }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Delete comment"
      buttonSection={
        <>
          <Button action="undo" style={{ marginRight: 15 }} onClick={onClose}>
            Don't do it
          </Button>
          <Button
            onClick={() => {
              onDeleteComment(ModerationAction.DELETE);
              if (!loadingDelete) {
                onClose();
              }
            }}
            loading={loadingDelete}
          >
            I'm sure, delete it
          </Button>
        </>
      }
    >
      <Typography variantName="table-header" textColor={Colors.GRIGIO}>
        You are deleting this comment, you will not be able to recover this.
      </Typography>
    </Dialog>
  );
};

export { DeleteCommentDialog };
