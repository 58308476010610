import gql from "graphql-tag";
import { INSTAGRAM_COMMENT } from "../fragments/comment/instagramComment";
import { INSTAGRAM_CONTENT } from "../fragments/content/instagramContent";

const LIST_INSTAGRAM_CONTENTS = gql`
  query listInstagramContents(
    $publisherId: PublisherIdInput!
    $offset: Int!
    $limit: Int!
    $since: DateTime
    $until: DateTime
    $offsetComments: Int!
    $limitComments: Int!
  ) {
    listInstagramContents(publisherId: $publisherId, offset: $offset, limit: $limit, since: $since, until: $until) {
      items {
        ...instagramContent
        comments(offset: $offsetComments, limit: $limitComments, order: DESC) {
          items {
            ...instagramComment
          }
          pageInfo {
            totalCount
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${INSTAGRAM_COMMENT}
  ${INSTAGRAM_CONTENT}
`;

export { LIST_INSTAGRAM_CONTENTS };
