import * as React from "react";
import { Button } from "@freeda/react-components";
import { SnackbarContext } from "../../providers/SnackbarProvider";
import { copyPathSuccess } from "../../messages";

interface Props {
  path: string;
  onClose?: () => void;
}

const ButtonCopyPath: React.FC<Props> = ({ path, onClose }) => {
  const el = React.useRef<HTMLTextAreaElement>(document.createElement("textarea"));

  const { openSnackbar } = React.useContext(SnackbarContext);

  const copyStringToClipboard = () => {
    el.current.value = path;

    document.body.appendChild(el.current);
    // Select text inside element
    el.current.select();
    // Copy text to clipboard
    document.execCommand("copy");

    document.body.removeChild(el.current);

    openSnackbar(copyPathSuccess, "notification");
  };

  return (
    <Button
      style={{ marginRight: 15 }}
      flat
      onClick={() => {
        copyStringToClipboard();
        onClose && onClose();
      }}
    >
      Copy link
    </Button>
  );
};

export { ButtonCopyPath };
