import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { includes } from "lodash";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { isDataAvailable } from "../../apollo/utils/isDataAvailable";
import { getListContentQuery, getPlatformListContentsName } from "../../apollo/utils/getListContentQuery";
import { PostCard } from "../layout/PostCard/PostCard";
import { WrapperList } from "../layout/PostCard/styles";
import { PostsListFilter } from "../layout/ListFilter/PostsListFilter";
import { PostPreview } from "../layout/PostDetails/LateralPreview/PostPreview";
import { PostsFilterContext } from "../../providers/PostsFilterProvider";
import { ListContentsQuery, ListContentQueryVariables } from "../../types/graphql/ListContentsQuery";
import { usePreviousData } from "../../hooks/usePreviousData";
import { getCustomDateRange } from "../../utils/getDateRange";
import { SharePostDialog } from "../layout/Dialog/SharePostDialog";
import { v4 } from "uuid";
import { WrapperContainer, ItemWrapper } from "../ui/sharedStyles";
import { PostsFilter } from "../layout/PageFilter/posts/PostsFilter";
import { useScrollToSelectedItem } from "../../hooks/useScrollToSelectedItem";
import { ListCardsPlaceholder } from "../ui/Placeholder/ListCardsPlaceholder";
import { ItemNotFound } from "../ui/Placeholder/ItemNotFound";
import { getSnackbarErrorHandling } from "../../utils/getSnackbarErrorHandling";
import { LIMIT_POSTS_PER_PAGE } from "../../utils/limitVariablesQueries";

const PostsListPage: React.FC = () => {
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1130 });

  const wrapperListId = React.useRef<string>(v4());

  const [selectedPostIds, setSelectedPostIds] = React.useState<Array<string>>([]);
  const [openShareDialog, setOpenShareDialog] = React.useState<boolean>(false);

  const { selectedPost, onChangeSelectedPost, publisherId, dateRange, currentPage } = React.useContext(
    PostsFilterContext
  );

  useScrollToSelectedItem(isDesktopOrLaptop, selectedPost, onChangeSelectedPost, wrapperListId.current);

  const prevPublisherId = usePreviousData(publisherId);
  const prevCurrentPage = usePreviousData(currentPage);

  React.useEffect(() => {
    if (publisherId.publisherId !== prevPublisherId.publisherId || prevCurrentPage !== currentPage) {
      setSelectedPostIds([]);
      onChangeSelectedPost(null);
      const wrapperList = document.getElementById(wrapperListId.current);
      wrapperList?.scrollTo({ left: 0, top: 0 });
    }
  }, [publisherId, currentPage]);

  const sinceUntilDateRange = getCustomDateRange(dateRange);

  const { since, until } = sinceUntilDateRange;

  const listPostsResult = useQuery<ListContentsQuery, ListContentQueryVariables>(
    getListContentQuery(publisherId.platformId),
    {
      variables: {
        publisherId,
        offset: currentPage * LIMIT_POSTS_PER_PAGE,
        limit: LIMIT_POSTS_PER_PAGE,
        since,
        until,
        offsetComments: 0,
        limitComments: 10,
      },
    }
  );

  const postsIsAvailable = isDataAvailable(listPostsResult);

  const listContentsName = getPlatformListContentsName(publisherId.platformId);

  const listPosts = !postsIsAvailable ? [] : listPostsResult.data![listContentsName].items;
  const totalCount = !postsIsAvailable ? 0 : listPostsResult.data![listContentsName].pageInfo.totalCount;
  const totalPages = Math.ceil(totalCount / LIMIT_POSTS_PER_PAGE);

  const onToggleSelectedPost = (id: string) => {
    const deselectedPost = selectedPostIds.find((cId) => cId === id);
    if (deselectedPost) {
      setSelectedPostIds((ids) => ids.filter((cId) => cId !== id));
    } else {
      setSelectedPostIds((ids) => [...ids, id]);
    }
  };

  const onSelectMultiPosts = () => {
    if (selectedPostIds.length > 0) {
      setSelectedPostIds([]);
    } else {
      setSelectedPostIds(listPosts.map((p) => p.id.contentId));
    }
  };

  const allPermalinks = listPosts
    .filter((p) =>
      includes(
        selectedPostIds.map((id) => id),
        p.id.contentId
      )
    )
    .map((p) => p.permalink);

  const selectedPosts = listPosts.filter((post) => includes(selectedPostIds, post.id.contentId));

  return (
    <>
      {openShareDialog && selectedPosts.length > 0 && (
        <SharePostDialog open={openShareDialog} onClose={() => setOpenShareDialog(false)} posts={selectedPosts} />
      )}
      <WrapperContainer>
        <ItemWrapper style={{ width: isDesktopOrLaptop ? "55%" : "100%" }}>
          <PostsFilter />
          <PostsListFilter
            checked={selectedPostIds.length > 0}
            onClickCheck={onSelectMultiPosts}
            selectAll={selectedPostIds.length === listPosts.length}
            onOpenLink={() => allPermalinks.map((link) => window.open(link))}
            onOpenShareDialog={() => setOpenShareDialog(true)}
            dataLoading={!postsIsAvailable || listPosts.length === 0}
            totalPages={totalPages}
          />
          {getSnackbarErrorHandling(listPostsResult)}
          {!postsIsAvailable && <ListCardsPlaceholder />}
          {postsIsAvailable && listPosts.length === 0 && <ItemNotFound context="posts" />}
          {postsIsAvailable && listPosts.length > 0 && (
            <WrapperList id={wrapperListId.current}>
              {listPosts.map((p) => {
                const openMobileBoolean = selectedPost ? selectedPost.id.contentId === p.id.contentId : false;

                const openDesktopBoolean = selectedPost
                  ? selectedPost.id.contentId === p.id.contentId
                  : listPosts[0].id.contentId === p.id.contentId;

                return (
                  <PostCard
                    key={p.id.contentId}
                    post={p}
                    open={isDesktopOrLaptop ? openDesktopBoolean : openMobileBoolean}
                    selected={Boolean(selectedPostIds.find((id) => id === p.id.contentId))}
                    onSelectedPost={() => {
                      onChangeSelectedPost(p);
                      if (!isDesktopOrLaptop) {
                        history.push(
                          `post/${p.id.publisherId}?platformId=${p.id.platformId}&contentId=${p.id.contentId}`
                        );
                      }
                    }}
                    onToggleSelected={() => onToggleSelectedPost(p.id.contentId)}
                  />
                );
              })}
            </WrapperList>
          )}
        </ItemWrapper>
        {isDesktopOrLaptop && (
          <PostPreview
            selectedPost={postsIsAvailable ? selectedPost || listPosts[0] : null}
            onOpenPostPanel={() => {
              if (!selectedPost) {
                onChangeSelectedPost(listPosts[0]);
                history.push(
                  `post/${listPosts[0].id.publisherId}?platformId=${listPosts[0].id.platformId}&contentId=${listPosts[0].id.contentId}`
                );
              } else {
                history.push(
                  `post/${selectedPost.id.publisherId}?platformId=${selectedPost.id.platformId}&contentId=${selectedPost.id.contentId}`
                );
              }
            }}
          />
        )}
      </WrapperContainer>
    </>
  );
};

export { PostsListPage };
