import * as React from "react";
import { SkeletonLabel, SkeletonSelect } from "./styles";
import {
  Header,
  SentimentGrid,
  WrapperFields,
  LabellingWrapper,
  LabellingPanelContainer,
} from "../../layout/LabellingPostDetailsPanel/styles";
import { ButtonIcon, DividerSection } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { FieldWrapper } from "../sharedStyles";

const buildFormPlaceholder = (index: number, widthLabel: string, widthSelect?: string) => {
  return (
    <div key={index} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <SkeletonLabel style={{ width: widthLabel }} />
      <SkeletonSelect width={widthSelect || "100%"} />
    </div>
  );
};

const LabellingPlaceholder: React.FC = () => {
  return (
    <LabellingPanelContainer openPostPanel={false}>
      <Header>
        <SkeletonLabel style={{ width: "140px" }} />
        <ButtonIcon iconName="save" disabled={true} iconColor={Colors.GRIGINO} />
      </Header>
      <LabellingWrapper>
        <SentimentGrid>
          {Array(2)
            .fill("")
            .map((el, i) => buildFormPlaceholder(i, "80px", "93%"))}
        </SentimentGrid>
        <DividerSection style={{ marginTop: 20, marginBottom: 15 }} dividerColor={Colors.GRIGINOtransparentLight} />
        <SentimentGrid>
          {Array(2)
            .fill("")
            .map((el, i) => buildFormPlaceholder(i, "80px", "93%"))}
        </SentimentGrid>
        <DividerSection style={{ marginTop: 20, marginBottom: 15 }} dividerColor={Colors.GRIGINOtransparentLight} />
        <WrapperFields>
          {Array(3)
            .fill("")
            .map((el, i) => (
              <FieldWrapper key={i} basis="100%" withoutMarginRight>
                {buildFormPlaceholder(i, "85px")}
              </FieldWrapper>
            ))}
        </WrapperFields>
      </LabellingWrapper>
    </LabellingPanelContainer>
  );
};

export { LabellingPlaceholder };
