import * as React from "react";
import { PostWrapper, Header } from "../../layout/LabellingPostDetailsPanel/styles";
import { SkeletonLabel, PostSkeleton } from "./styles";
import { ButtonIcon } from "@freeda/react-components";

const PostDetailsPlaceholder: React.FC = () => {
  return (
    <PostWrapper open={false} style={{ bottom: 0 }}>
      <ButtonIcon
        disabled
        iconName="arrow-up"
        style={{ position: "absolute", left: "50%", transform: "translateX(-50%) translateY(5px)" }}
      />
      <Header style={{ marginBottom: 10, marginTop: 20 }}>
        <SkeletonLabel style={{ width: "30%" }} />
        <SkeletonLabel style={{ width: "30%" }} />
      </Header>
      <div style={{ padding: "0px 20px" }}>
        <SkeletonLabel style={{ width: "45%", height: 8, marginBottom: 15 }} />
        <PostSkeleton style={{ width: "auto", height: "auto" }} />
        <SkeletonLabel style={{ width: "45%", marginTop: 25 }} />
        <SkeletonLabel style={{ width: "45%", marginTop: 7 }} />
        <SkeletonLabel />
      </div>
    </PostWrapper>
  );
};

export { PostDetailsPlaceholder };
