import * as React from "react";
import { useFetchPublishers } from "../../hooks/useFetchPublishers";
import { getSnackbarErrorHandling } from "../../utils/getSnackbarErrorHandling";
import { ModerationPagePlaceholder } from "../ui/Placeholder/ModerationPagePlaceholder";
import { CommentsModerationProcess } from "../layout/CommentsModerationProcess/CommentsModerationProcess";
import { Platform } from "../../types/Platform";

const ModerationPage: React.FC = () => {
  const { publishers, publishersResult } = useFetchPublishers();

  const [selectedPlatform, setPlatform] = React.useState<Platform>(Platform.FBG);
  const [selectedPublisherId, setPublisherId] = React.useState<string>("");

  React.useEffect(() => {
    const availablePublishers = publishers.filter((p) => p.id.platformId === selectedPlatform);

    if (availablePublishers.length > 0) {
      setPublisherId(availablePublishers[0].id.publisherId);
    }
  }, [publishers, selectedPlatform]);

  if (
    publishers.length === 0 ||
    !publishersResult.data ||
    publishersResult.loading ||
    !Boolean(selectedPublisherId.length)
  ) {
    return (
      <>
        {getSnackbarErrorHandling(publishersResult)}
        <ModerationPagePlaceholder />
      </>
    );
  }

  const availablePublishers = publishers.filter((p) => p.id.platformId === selectedPlatform);

  return (
    <CommentsModerationProcess
      publishers={availablePublishers}
      selectedPublisherId={selectedPublisherId}
      selectedPlatform={selectedPlatform}
      onChangePublisherId={(publisherId) => setPublisherId(publisherId)}
      onChangePlatform={(platform) => setPlatform(platform)}
    />
  );
};

export { ModerationPage };
