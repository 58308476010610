import { Platform } from "../types/Platform";

const fbIcon = require("../assets/platform/FB.svg");
const igIcon = require("../assets/platform/IG.svg");
const fbgIcon = require("../assets/platform/FBG.svg");

export const getPlatformIcon = (platform: Platform) => {
  const icon = {
    [Platform.FB]: fbIcon,
    [Platform.FBG]: fbgIcon,
    [Platform.IG]: igIcon
  };

  return icon[platform];
};
