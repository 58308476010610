import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";
import { Comment, CommentWrapper } from "../layout/CommentsModerationProcess/styles";
import { Typography, DialogCard, ButtonIcon, DividerSection } from "@freeda/react-components";
import { ModerationButton } from "./ModerationButton";
import { DeleteCommentDialog } from "../layout/Dialog/DeleteCommentDialog";
import { ModerationAction } from "../../types/ModerationAction";
import { ButtonStyled } from "./ButtonStyled";
import { getFormatDate } from "../../utils/getFormatDate";
import { SocialComment } from "../../types/SocialComment";
import { ShareCommentDialog } from "../layout/Dialog/ShareCommentDialog";
import { ReactionsTooltip } from "./ReactionsTooltip";
import { useWindowSize } from "../../hooks/useWindowSize";
import { buildWrapText } from "../../utils/buildWrapText";
import { Permission, Roles } from "../Permission";
import { CommentId } from "../../types/CommentId";
import { generatePathLinkComment } from "../../utils/generatePathLinkComment";

export type CommentType = "default" | "selected" | "child" | "selectedChild" | "freeda" | "parent";

const getColorMessageByType = (commentType: CommentType) => {
  const textColors = {
    primary: Colors.GRIGIO,
    primaryReply: Colors.GRIGIO,
    secondary: Colors.GRIGINO,
    parent: Colors.GRIGINO,
    freeda: Colors.GRIGINO,
  };

  return textColors[commentType];
};

export const getCommentType = (comment: SocialComment, selectedCommentId: CommentId): CommentType => {
  const commentIsChild = Boolean(comment.parent);

  if (comment.id.commentId === selectedCommentId.commentId) {
    if (commentIsChild) {
      return "selectedChild";
    } else {
      return "selected";
    }
  } else {
    if (commentIsChild) {
      return "child";
    } else {
      return "default";
    }
  }
};

type Props = React.HTMLAttributes<HTMLDivElement> & {
  commentType: CommentType;
  comment: SocialComment;
  onModerationComment: (action: ModerationAction) => void;
  loadingDelete: boolean;
  onSelectedComment?: () => void;
  style?: React.CSSProperties;
};

const getCommentStyles = (
  commentIsVisible: boolean,
  commentIsClassified: boolean,
  commentType: CommentType,
  onSelectedComment?: () => void
): React.CSSProperties => {
  let genericStyles = {
    letterSpacing: 0.2,
    lineHeight: "16px",
    fontSize: 12,
    color: getColorMessageByType(commentType),
    cursor: onSelectedComment && commentIsVisible ? "pointer" : "default",
  };
  if (!commentIsClassified) {
    return { ...genericStyles, fontWeight: 700, letterSpacing: 0.1, fontSize: 11.8 };
  }
  return genericStyles;
};

const CommentBox: React.FC<Props> = ({
  commentType,
  comment,
  onModerationComment,
  loadingDelete,
  onSelectedComment,
  style,
}) => {
  const { windowWidth } = useWindowSize();

  const { id, text, reactions, createdTime, user_hidden, user_deleted, removed, labels } = comment;
  const { platformId, commentId } = id;

  const wrapCommentText = buildWrapText(text);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const [reducedVersion, setReducedVersion] = React.useState(false);

  React.useEffect(() => {
    const commentContainer = document.getElementById(commentId);
    if (commentContainer) {
      const width = Math.round(commentContainer.getBoundingClientRect().width);
      const minWidth = commentType === "child" || commentType === "selectedChild" ? 400 : 450;
      setReducedVersion(width <= minWidth);
    }
  }, [windowWidth, commentId, commentType]);

  const isPrimaryComment = commentType === "selected" || commentType === "selectedChild";

  const commentIsHidden = user_hidden;
  const commentIsRemoved = user_deleted || removed;

  const commentIsVisible = !(commentIsHidden || commentIsRemoved);

  const commentIsClassified = Boolean(labels && labels.length > 0);

  return (
    <>
      {openDeleteDialog && (
        <DeleteCommentDialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          loadingDelete={loadingDelete}
          onDeleteComment={(action) => onModerationComment(action)}
        />
      )}
      {openShareDialog && (
        <ShareCommentDialog open={openShareDialog} onClose={() => setOpenShareDialog(false)} comments={[comment]} />
      )}
      <CommentWrapper
        commentType={commentType}
        id={commentId}
        onClick={onSelectedComment}
        style={{ cursor: onSelectedComment && commentIsVisible ? "pointer" : "default", ...style }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {isPrimaryComment || hover ? (
          <DialogCard
            referenceStyle={{ marginRight: 10 }}
            referenceChildren={
              <ButtonIcon iconName="more-horiz" iconColor={Colors.GRIGINO} onClick={onSelectedComment} />
            }
            tooltipChildren={(closeCard) => {
              const hideUnhideIsVisible = !commentIsRemoved && reducedVersion;

              const pathLinkComment = generatePathLinkComment(comment.id);

              return (
                <div>
                  {hideUnhideIsVisible && (
                    <Permission roles={[Roles.MODERATOR]}>
                      <div style={{ marginBottom: 20 }}>
                        <ButtonStyled
                          icon={{
                            iconName: commentIsHidden ? "show" : "hidden",
                            position: "left",
                            iconColor: Colors.GRIGINO,
                          }}
                          style={{ marginBottom: 10 }}
                          onClick={() => {
                            const action = commentIsHidden ? ModerationAction.UNHIDE : ModerationAction.HIDE;
                            onModerationComment(action);
                            closeCard();
                          }}
                        >
                          {commentIsHidden ? "Unhide" : "Hide"}
                        </ButtonStyled>
                        <DividerSection />
                      </div>
                    </Permission>
                  )}
                  {!commentIsRemoved && (
                    <Permission roles={[Roles.MODERATOR]}>
                      <ButtonStyled
                        icon={{ iconName: "delete", position: "left", iconColor: Colors.GRIGINO }}
                        style={{ marginBottom: 10 }}
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          closeCard();
                        }}
                      >
                        Delete
                      </ButtonStyled>
                    </Permission>
                  )}
                  <a href={pathLinkComment} style={{ textDecoration: "none" }} target="__blank">
                    <ButtonStyled icon={{ iconName: "open-link", position: "left", iconColor: Colors.GRIGINO }}>
                      Open external link
                    </ButtonStyled>
                  </a>
                </div>
              );
            }}
          />
        ) : (
          <div style={{ marginRight: 10, minWidth: 35, height: 5 }} />
        )}
        <Comment commentType={commentType} isVisible={commentIsVisible}>
          <div style={{ marginBottom: 10 }}>
            {!commentIsVisible && (
              <Typography
                variantName="caption"
                style={{ position: "relative", top: -6, letterSpacing: 0.5, userSelect: "none" }}
                textColor={commentIsRemoved ? Colors.ROSSINO : ""}
              >
                <>
                  <span style={{ fontSize: 14 }}>{commentIsRemoved ? "⚠️" : "👻"}</span>
                  {commentIsRemoved ? "This comment has been removed" : "This comment has been hidden"}
                </>
              </Typography>
            )}
            <div>
              {wrapCommentText.map((t, i) => (
                <Typography
                  key={i}
                  variantName="text"
                  style={{
                    ...getCommentStyles(commentIsVisible, commentIsClassified, commentType, onSelectedComment),
                    wordBreak: "break-word",
                  }}
                >
                  {t}
                </Typography>
              ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
              borderRadius: 15,
              background: Colors.WHITE,
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px -1px",
              position: "absolute",
              right: 15,
              top: -8,
              zIndex: 1,
              transition: "background 0.3s",
            }}
          >
            <ReactionsTooltip reactions={reactions} platform={platformId} />
          </div>
          <Typography variantName="caption" style={{ position: "absolute", bottom: 8, right: 8, letterSpacing: 0.5 }}>
            {getFormatDate(createdTime)}
          </Typography>
        </Comment>
        <Permission roles={[Roles.MODERATOR]}>
          {!isPrimaryComment && !reducedVersion && <div style={{ marginLeft: 15, minWidth: 90, height: 5 }} />}
          {!reducedVersion && isPrimaryComment && !commentIsRemoved && (
            <ModerationButton
              commentType={commentType}
              isHidden={commentIsHidden}
              onChangeHideComment={(action: ModerationAction) => {
                const enableMutation =
                  (action === ModerationAction.HIDE && !comment.user_hidden) ||
                  (action === ModerationAction.UNHIDE && comment.user_hidden);

                if (enableMutation) {
                  onModerationComment(action);
                }
              }}
            />
          )}
        </Permission>
      </CommentWrapper>
    </>
  );
};

export { CommentBox };
