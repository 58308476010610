import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { SidebarMobile } from "./SidebarMobile";
import { SidebarDesktop } from "./SidebarDesktop";
import { randomNumber } from "./emoji";
import { Colors } from "@freeda/react-components/lib/theme";

export const getLabelColor = (isClicked: boolean, mobileColor?: string) => {
  if (mobileColor) {
    return isClicked ? "#fff" : mobileColor;
  } else {
    return isClicked ? "#fff" : Colors.DARKpurple;
  }
};

const Sidebar: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [open, setOpen] = React.useState(false);

  const indexEmojiList = React.useRef<number>(randomNumber());

  if (!isMobile) {
    return <SidebarDesktop open={open} onChangeOpen={open => setOpen(open)} indexEmojiList={indexEmojiList.current} />;
  } else {
    return (
      <SidebarMobile
        open={open}
        onChangeOpen={isOpen => setOpen(open => !open)}
        indexEmojiList={indexEmojiList.current}
      />
    );
  }
};

export { Sidebar };
