import gql from "graphql-tag";
import { Platform } from "../../types/Platform";
import { SOCIAL_COMMENT_LIST } from "../fragments/socialCommentListFragment";

const GET_COMMENT_WITH_CHILDREN = (platform: Platform) => gql`
  query getComment($id: CommentIdInput!, $offsetChildren: Int!, $limitChildren: Int!) {
    getComment(id: $id) {
      ...socialComment
    }
  }
  ${SOCIAL_COMMENT_LIST(platform)}
`;

export { GET_COMMENT_WITH_CHILDREN };
