import * as React from "react";
import { SnackbarContext } from "../../providers/SnackbarProvider";

import { Snackbar as FreedaSnackbar } from "@freeda/react-components";

const Snackbar: React.FC = () => {
  const { message, messageType, open, onClose } = React.useContext(SnackbarContext);
  return <FreedaSnackbar typology={messageType} open={open} onClose={onClose} label={<span>{message}</span>} />;
};

export { Snackbar };
