const LIMIT_CHILDREN_LIST_COMMENTS = 1;
const LIMIT_NEXT_CHILDREN = 5;

const LIMIT_COMMENTS_PER_PAGE = 20;
const LIMIT_COMMENTS_PER_PAGE_MODERATION = 15;

const LIMIT_COMMENTS_POST_PREVIEW = 10;

const LIMIT_POSTS_PER_PAGE = 25;

export {
  LIMIT_NEXT_CHILDREN,
  LIMIT_CHILDREN_LIST_COMMENTS,
  LIMIT_COMMENTS_PER_PAGE_MODERATION,
  LIMIT_COMMENTS_PER_PAGE,
  LIMIT_COMMENTS_POST_PREVIEW,
  LIMIT_POSTS_PER_PAGE,
};
