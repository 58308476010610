import * as React from "react";
import styled from "styled-components";
import { ButtonGroup, ButtonIcon } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { Sentiment } from "../../../types/Sentiment";

const StyledButtonGroup = styled(ButtonGroup)<{ disabled?: boolean }>`
  transition: filter 0.3s;
  filter: ${(props) => (props.disabled ? "blur(1px)" : "blur(0px)")};

  && > div > button {
    padding: 5px;
    min-width: 48px;
    & > span {
      font-size: 20px;
      position: relative;
      top: 0px;
      left: 1px;
      margin: 0px !important;
    }
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

interface Props {
  selectedSentiment: Sentiment | null;
  onChangeSelectedLabel: (option: Array<{ value: string; label: string }>) => void;
  onRemoveLabel: () => void;
}

const SentimentButton: React.FC<Props> = ({ selectedSentiment, onChangeSelectedLabel, onRemoveLabel }) => {
  const sentimentOptions = [
    { value: Sentiment.POSITIVE, label: "😁" },
    { value: Sentiment.NEUTRAL, label: "😐" },
    { value: Sentiment.NEGATIVE, label: "😡" },
  ];

  const selectedOption = sentimentOptions.filter((o) => o.value === selectedSentiment?.toUpperCase());

  return (
    <Wrapper>
      <ButtonIcon
        iconSize="small"
        iconName="close"
        iconColor={Colors.GRIGINOtransparent}
        style={{ position: "absolute", zIndex: 1, right: 10, top: 0, width: 25, height: 25 }}
        onClick={onRemoveLabel}
      />
      <StyledButtonGroup
        label="Sentiment"
        options={sentimentOptions}
        selectedOptions={selectedOption}
        onSelectedOption={onChangeSelectedLabel}
        styleWrapper={{ userSelect: "none", width: "100%" }}
      />
    </Wrapper>
  );
};

export { SentimentButton };
