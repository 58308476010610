import styled, { css } from "styled-components";
import { ListItem } from "@freeda/react-components";
import { zIndex } from "../../../utils/zIndex";

const SidebarListItem = styled(ListItem)<{
  open?: boolean;
  backgroundHover?: string;
}>`
  && {
    transition: all 0.3s;
    padding-left: 18px;
    padding-right: 18px;
    background: ${props => props.theme.primary};
    &:hover {
      background: ${props => (props.backgroundHover ? props.backgroundHover : "rgb(97, 32, 195)")};
    }
    ${props =>
      !props.open &&
      css`
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
      `}
  }
`;

const WrapperLabel = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.5s;
  &.wrapper-entering {
    opacity: 0;
  }
  &.wrapper-entered {
    opacity: 1;
  }
  &.wrapper-exiting {
    opacity: 0;
  }
  &.wrapper-exited {
    opacity: 0;
  }
`;

const FirstPart = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
`;

const AppBar = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  background: #ffffff;
  z-index: ${zIndex.AppBar};
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
`;

const FirstPartMobile = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > button {
    margin: 0px;
  }
`;

export { SidebarListItem, WrapperLabel, FirstPart, AppBar, FirstPartMobile };
