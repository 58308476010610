import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { v4 } from "uuid";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      return console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    return console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  fetch: (uri: string, options: any) => {
    if (options) {
      const token = localStorage.getItem("access_token");
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return fetch(uri, options);
  },
});

const link = ApolloLink.from([errorLink, httpLink]);

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case "Publisher":
        return `${object.__typename}:${object.id.publisherId}`;
      case "Project":
        return `${object.__typename}:${object.project}`;
      case "PaginationComment":
        return `${object.__typename}:${v4()}`;
      case "FacebookComment":
      case "FacebookGroupComment":
      case "InstagramComment":
        return `${object.__typename}:${object.id.commentId}`;
      case "FacebookContent":
      case "FacebookGroupContent":
      case "InstagramContent":
        return `${object.__typename}:${object.id.platformId}-${object.id.publisherId}-${object.id.contentId}`;

      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const client = new ApolloClient({
  link,
  cache,
});

export default client;
