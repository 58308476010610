import gql from "graphql-tag";
import { Platform } from "../../types/Platform";
import { FACEBOOK_COMMENT } from "./comment/facebookComment";
import { INSTAGRAM_COMMENT } from "./comment/instagramComment";
import { FACEBOOK_GROUP_COMMENT } from "./comment/facebookGroupComment";

const SOCIAL_COMMENT_LIST = (platform: Platform) => {
  const allPlatformQueries = {
    [Platform.FB]: gql`
      fragment socialComment on FacebookComment {
        ...facebookComment
        parent {
          ...facebookComment
        }
        children(offset: $offsetChildren, limit: $limitChildren, order: ASC) {
          items {
            ...facebookComment
          }
          pageInfo {
            totalCount
          }
        }
      }
      ${FACEBOOK_COMMENT}
    `,
    [Platform.IG]: gql`
      fragment socialComment on InstagramComment {
        ...instagramComment
        parent {
          ...instagramComment
        }
        children(offset: $offsetChildren, limit: $limitChildren, order: ASC) {
          items {
            ...instagramComment
          }
          pageInfo {
            totalCount
          }
        }
      }
      ${INSTAGRAM_COMMENT}
    `,
    [Platform.FBG]: gql`
      fragment socialComment on FacebookGroupComment {
        ...facebookGroupComment
        parent {
          ...facebookGroupComment
        }
        children(offset: $offsetChildren, limit: $limitChildren, order: ASC) {
          items {
            ...facebookGroupComment
          }
          pageInfo {
            totalCount
          }
        }
      }
      ${FACEBOOK_GROUP_COMMENT}
    `,
  };

  return allPlatformQueries[platform];
};

export { SOCIAL_COMMENT_LIST };
