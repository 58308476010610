import * as React from "react";
import { ButtonIcon, DialogCard, Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  onSelectClassifiedComments: (classified: boolean | null) => void;
  disabled: boolean;
}

const SelectCommentsCard: React.FC<Props> = ({ onSelectClassifiedComments, disabled }) => {
  if (disabled) {
    return (
      <ButtonIcon iconName="arrow-down" iconSize="small" style={{ position: "relative", top: 1 }} disabled={disabled} />
    );
  }

  return (
    <DialogCard
      referenceChildren={<ButtonIcon iconName="arrow-down" iconSize="small" style={{ position: "relative", top: 1 }} />}
      tooltipChildren={(closeCard) => (
        <>
          <Typography
            variantName="card-title"
            textColor={Colors.PURPLE}
            style={{ marginBottom: 15, cursor: "pointer", fontWeight: 500 }}
            onClick={() => {
              onSelectClassifiedComments(null);
              closeCard();
            }}
          >
            All
          </Typography>
          <Typography
            variantName="card-title"
            textColor={Colors.PURPLE}
            style={{ marginBottom: 15, cursor: "pointer", fontWeight: 500 }}
            onClick={() => {
              onSelectClassifiedComments(true);
              closeCard();
            }}
          >
            Classified
          </Typography>
          <Typography
            variantName="card-title"
            textColor={Colors.PURPLE}
            style={{ cursor: "pointer", fontWeight: 500 }}
            onClick={() => {
              onSelectClassifiedComments(false);
              closeCard();
            }}
          >
            Unclassified
          </Typography>
        </>
      )}
      position="bottom-start"
    />
  );
};

export { SelectCommentsCard };
