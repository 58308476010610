import * as React from "react";
import { Platform } from "../types/Platform";
import { PublisherId } from "../types/PublisherId";
import { SocialComment } from "../types/SocialComment";
import { usePreviousData } from "../hooks/usePreviousData";

type CommentsFilterContext = {
  publisherId: PublisherId;
  platform: Platform;
  commentsIsCategorized: boolean | null;
  selectedComment: SocialComment | null;
  currentPage: number;
  classified: boolean | null;
  onChangePublisherId: (publisherId: PublisherId) => void;
  onChangePlatform: (platform: Platform) => void;
  onChangeCommentsStatus: (isCategorized: boolean | null) => void;
  onClearFilter: () => void;
  onChangeSelectedComment: (comment: SocialComment | null) => void;
  onChangeCurrentPage: (totalPages: number) => void;
  handleClassify: (classified: boolean | null) => void;
};

const initialState = {
  publisherId: { platformId: Platform.FBG, publisherId: "" },
  platform: Platform.FBG,
  commentsIsCategorized: null,
  selectedComment: null,
  currentPage: 0,
  classified: null,
};

const CommentsFilterContext = React.createContext<CommentsFilterContext>({} as CommentsFilterContext);

const CommentsFilterProvider: React.FC = ({ children }) => {
  const [publisherId, setPublisherId] = React.useState<PublisherId>(initialState.publisherId);
  const [platform, setPlatform] = React.useState<Platform>(initialState.platform);
  const [commentsIsCategorized, setCommentsIsCategorized] = React.useState<boolean | null>(
    initialState.commentsIsCategorized
  );
  const [selectedComment, setSelectedComment] = React.useState<SocialComment | null>(initialState.selectedComment);
  const [currentPage, setCurrentPage] = React.useState<number>(initialState.currentPage);
  const [classified, setClassified] = React.useState<boolean | null>(initialState.classified);

  const prevPublisherId = usePreviousData(publisherId);

  React.useEffect(() => {
    if (prevPublisherId.publisherId !== publisherId.publisherId) {
      setSelectedComment(initialState.selectedComment);
    }
  }, [publisherId]);

  const onChangePublisherId = (publisherId: PublisherId) => setPublisherId(publisherId);

  const onChangeCommentsStatus = (isCategorized: boolean | null) => setCommentsIsCategorized(isCategorized);

  const onChangeSelectedComment = (comment: SocialComment | null) => setSelectedComment(comment);

  const onChangePlatform = (platform: Platform) => {
    setPlatform(platform);
    setSelectedComment(initialState.selectedComment);
  };

  const onClearFilter = () => {
    setCommentsIsCategorized(initialState.commentsIsCategorized);
    setSelectedComment(initialState.selectedComment);
    setPlatform(initialState.platform);
    setCurrentPage(initialState.currentPage);
    setClassified(initialState.classified);
  };

  const onChangeCurrentPage = (totalPages: number) => {
    setCurrentPage((currentPage) => (currentPage === totalPages - 1 ? currentPage : currentPage + 1));
  };

  const handleClassify = (classified: boolean | null) => setClassified(classified);

  return (
    <CommentsFilterContext.Provider
      value={{
        publisherId,
        platform,
        commentsIsCategorized,
        selectedComment,
        currentPage,
        classified,
        onChangePublisherId,
        onChangePlatform,
        onChangeCommentsStatus,
        onChangeSelectedComment,
        onClearFilter,
        onChangeCurrentPage,
        handleClassify,
      }}
    >
      {children}
    </CommentsFilterContext.Provider>
  );
};

export { CommentsFilterContext, CommentsFilterProvider };
