import { DocumentNode } from "graphql";
import { Platform } from "../../types/Platform";
import { PlatformName } from "../../types/PlatformName";
import { GET_FACEBOOK_CONTENT } from "../queries/GetFacebookContent";
import { GET_INSTAGRAM_CONTENT } from "../queries/GetInstagramContent";
import { GET_FACEBOOK_GROUP_CONTENT } from "../queries/GetFacebookGroupContent";

export const getContentQueryByPlatform = (platform: Platform): DocumentNode => {
  const queries = {
    [Platform.FB]: GET_FACEBOOK_CONTENT,
    [Platform.IG]: GET_INSTAGRAM_CONTENT,
    [Platform.FBG]: GET_FACEBOOK_GROUP_CONTENT,
  };

  return queries[platform];
};

export const getPlatformContentName = (platform: Platform): string => {
  return `get${PlatformName[platform]}Content`;
};
