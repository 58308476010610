import * as React from "react";
import styled from "styled-components";
import { Button } from "@freeda/react-components";
import { TransitionStatus } from "react-transition-group/Transition";

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  padding: 20px;
  transition: bottom 0.3s;
  display: flex;
  justify-content: flex-end;

  &.entering {
    bottom: -40px;
  }
  &.entered {
    bottom: -20px;
    @media (max-width: 768px) {
      bottom: 0px;
    }
  }
  &.exiting {
    bottom: -40px;
  }
  &.exited {
    bottom: -40px;
  }
`;

interface Props {
  onOpenModerationProcess: () => void;
  transitionStatus: TransitionStatus;
}

const StartCategorizeMobileButton: React.FC<Props> = ({ transitionStatus, onOpenModerationProcess }) => {
  return (
    <Wrapper className={`${transitionStatus}`}>
      <Button
        style={{ width: "100%" }}
        onClick={onOpenModerationProcess}
        icon={{ iconName: "comments", position: "right" }}
      >
        Start categorize
      </Button>
    </Wrapper>
  );
};

export { StartCategorizeMobileButton };
