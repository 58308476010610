import * as React from "react";
import { SocialComment } from "../../../../types/SocialComment";
import { CommentBox } from "../../../ui/CommentBox";
import { Typography } from "@freeda/react-components";

interface Props {
  parent: SocialComment;
  children: Array<SocialComment>;
  onOpenPostPanel: (parent: SocialComment) => void;
}

const CommentsChildrenPreview: React.FC<Props> = ({ parent, children, onOpenPostPanel }) => {
  const hasChildren = parent.children.items.length > 0;

  return (
    <>
      {children.map((commentChild) => {
        return (
          <CommentBox
            key={commentChild.id.commentId}
            commentType="child"
            comment={commentChild}
            onSelectedComment={() => onOpenPostPanel(commentChild)}
            onModerationComment={() => onOpenPostPanel(commentChild)}
            loadingDelete={false}
          />
        );
      })}

      {hasChildren && (
        <div style={{ marginLeft: 95, marginTop: 8 }} onClick={() => onOpenPostPanel(parent)}>
          <Typography variantName="underline" style={{ cursor: "pointer" }}>
            Load other replies...
          </Typography>
        </div>
      )}
    </>
  );
};

export { CommentsChildrenPreview };
