import * as React from "react";
import { WrapperCard, WrapperText } from "./styles";
import { Typography, CheckBox, ButtonIcon } from "@freeda/react-components";
import { getFormatDate } from "../../../utils/getFormatDate";
import { Colors } from "@freeda/react-components/lib/theme";
import { ActionsCard } from "../../ui/ActionsCard";
import { PostImg } from "../../ui/sharedStyles";
import { SocialComment } from "../../../types/SocialComment";
import { buildSocialCommentInfo, isFbGroupComment } from "../../../utils/buildSocialCommentInfo";
import { buildSocialContentInfo } from "../../../utils/buildSocialContentInfo";
import { ShareCommentDialog } from "../Dialog/ShareCommentDialog";
import { getPostImage } from "../../../utils/getPostImage";
import { generatePathLinkComment } from "../../../utils/generatePathLinkComment";

const placeholderPost = require("../../../assets/post/placeholderPost.jpg");

interface Props {
  comment: SocialComment;
  open: boolean;
  selected: boolean;
  onToggleSelected: () => void;
  onModerateComment: () => void;
}

const CommentCard: React.FC<Props> = ({ comment, open, selected, onToggleSelected, onModerateComment }) => {
  const [hover, setHover] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);

  const { text, createdTime, content, commentId, labels } = buildSocialCommentInfo(comment);
  const { title, caption, messageFbGroup } = buildSocialContentInfo(content);

  const postTitle = title ? `${title}` : "";
  const postCaption = caption ? `${caption}` : "";

  const isFBGpost = isFbGroupComment(comment);

  const commentIsClassified = Boolean(labels && labels.length > 0);

  const textStyles = !commentIsClassified ? { fontWeight: 700 } : {};

  const commentIsHidden = comment.user_hidden;
  const commentIsRemoved = comment.user_deleted || comment.removed;
  const commentIsVisible = !(commentIsHidden || commentIsRemoved);

  const imagePost = getPostImage(content);

  return (
    <>
      {openShareDialog && (
        <ShareCommentDialog open={openShareDialog} onClose={() => setOpenShareDialog(false)} comments={[comment]} />
      )}
      <WrapperCard
        isCategorize={commentIsClassified}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onModerateComment}
        selected={selected}
        open={open}
        id={comment.id.commentId}
      >
        <ActionsCard
          showIcon={hover}
          platform={commentId.platformId}
          iconSection={
            <ButtonIcon
              iconName="open-link"
              style={{ marginRight: 5 }}
              iconColor={Colors.GRIGINO}
              onClick={() => window.open(generatePathLinkComment(commentId))}
            />
          }
        />
        <div
          onClick={(e) => {
            e.stopPropagation();
            onToggleSelected();
          }}
          style={{ padding: 15, display: "flex", alignItems: "center" }}
        >
          <CheckBox checked={selected} onClick={onToggleSelected} stopPropagation={false} />
        </div>
        {isFBGpost && imagePost && (
          <PostImg src={imagePost} style={{ marginLeft: 0 }} onError={(e) => (e.currentTarget.src = placeholderPost)} />
        )}
        {!isFBGpost && <PostImg src={imagePost || placeholderPost} style={{ marginLeft: 0 }} />}
        <WrapperText>
          <Typography variantName="text" style={{ fontSize: 12, cursor: "pointer", ...textStyles }}>
            <>{isFBGpost ? messageFbGroup : `${postTitle} ${postTitle && postCaption ? "-" : ""} ${postCaption}`}</>
          </Typography>
          <Typography
            variantName="text"
            style={{ fontSize: 12, cursor: "pointer", ...textStyles }}
            textColor={Colors.GRIGINO}
          >
            {text}
          </Typography>
        </WrapperText>
        {!commentIsVisible && (
          <Typography
            variantName="caption"
            style={{ position: "absolute", bottom: 6, left: 12, letterSpacing: 0.5, userSelect: "none" }}
            textColor={commentIsRemoved ? Colors.ROSSINO : ""}
          >
            <>
              <span style={{ fontSize: 14 }}>{commentIsRemoved ? "⚠️" : "👻"}</span>
              {commentIsRemoved ? "This comment has been removed" : "This comment has been hidden"}
            </>
          </Typography>
        )}
        <Typography
          variantName="caption"
          style={{ position: "absolute", cursor: "pointer", bottom: 8, right: 8, letterSpacing: 0.5 }}
        >
          {getFormatDate(createdTime)}
        </Typography>
      </WrapperCard>
    </>
  );
};

export { CommentCard };
