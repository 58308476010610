import * as React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { getPlatformIcon } from "../../../utils/getPlatformIcon";
import { buildSocialContentInfo, isFbGroupContent } from "../../../utils/buildSocialContentInfo";
import { Dialog } from "./Dialog";
import { Button, Typography, DividerSection, Select } from "@freeda/react-components";
import { scrollbarVertical, lightScrollbarVertical } from "../../ui/ScrollbarStyles";
import { PlatformIcon, FieldWrapper, PostImg } from "../../ui/sharedStyles";
import { Dialogable } from "../../../types/Dialogable";
import { Colors } from "@freeda/react-components/lib/theme";
import { SocialContent } from "../../../types/SocialContent";
import { ButtonCopyPath } from "../../ui/ButtonCopyPath";
import { getPostImage } from "../../../utils/getPostImage";

const placeholderPost = require("../../../assets/post/placeholderPost.jpg");

const TextArea = styled.textarea<{ isMobile: boolean }>`
  width: 100%;
  margin-top: 7px;
  resize: none;
  padding: 8px;
  color: ${(props) => props.theme.primary};
  height: ${(props) => (props.isMobile ? "100%" : "100px")};
  border: 1px solid ${(props) => props.theme.secondary};

  ${scrollbarVertical};

  &::placeholder {
    font-size: 12px;
    color: ${(props) => props.theme.secondary};
    white-space: nowrap;
  }
`;

const Caption = styled(Typography)`
  height: 50px;
  overflow-y: overlay;
  text-overflow: ellipsis;

  ${lightScrollbarVertical};
`;

type Props = Dialogable & {
  posts: Array<SocialContent>;
};

const SharePostDialog: React.FC<Props> = ({ open, onClose, posts }) => {
  const isMultiShare = posts.length > 1;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { title, caption, id, messageFbGroup } = buildSocialContentInfo(posts[0]);

  const isFBGpost = isFbGroupContent(posts[0]);

  const imagePost = getPostImage(posts[0]);

  const sharePath = `${process.env.REACT_APP_BASE_URL!}/post/${id.publisherId}?platformId=${id.platformId}&contentId=${
    id.contentId
  }`;

  const titleDialog = `Share post${isMultiShare ? "s" : ""}`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={titleDialog}
      buttonSection={
        <>
          {!isMultiShare && <ButtonCopyPath path={sharePath} onClose={onClose} />}
          <Button
            onClick={() => {
              console.log("share post!");
            }}
          >
            Share
          </Button>
        </>
      }
    >
      {!isMultiShare && (
        <>
          <div style={{ display: "flex", width: "100%" }}>
            {isFBGpost && imagePost && (
              <PostImg
                src={imagePost}
                size="large"
                style={{ marginLeft: 0 }}
                onError={(e) => (e.currentTarget.src = placeholderPost)}
              />
            )}
            {!isFBGpost && <PostImg src={imagePost || placeholderPost} size="large" style={{ marginLeft: 0 }} />}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <PlatformIcon src={getPlatformIcon(id.platformId)} alt="" />
              <div style={{ display: "grid", marginTop: 8 }}>
                {isFBGpost && messageFbGroup && (
                  <Caption variantName="text" style={{ fontSize: 12, paddingRight: 8 }} textColor={Colors.GRIGINO}>
                    {messageFbGroup}
                  </Caption>
                )}
                {!isFBGpost && title && (
                  <Typography
                    variantName="text"
                    style={{
                      fontSize: 12,
                      marginBottom: 5,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {title}
                  </Typography>
                )}
                {!isFBGpost && caption && (
                  <Caption variantName="text" textColor={Colors.GRIGINO} style={{ fontSize: 12, paddingRight: 10 }}>
                    {caption}
                  </Caption>
                )}
              </div>
            </div>
          </div>
          <DividerSection style={{ width: "100%", margin: "15px 0px" }} />
        </>
      )}
      <div style={{ width: "100%", height: "100%" }}>
        <FieldWrapper basis="100%" withoutMarginRight={true} style={{ marginBottom: 20 }}>
          <Select
            options={[
              { value: "all", label: "All" },
              { value: "categorized", label: "Categorized" },
              { value: "uncategorized", label: "Uncategorized" },
            ]}
            onChange={() => console.log("change")}
            value={null}
            placeholder="Select a user"
            label="Share with"
          />
        </FieldWrapper>
        <FieldWrapper basis="100%" withoutMarginRight={true}>
          <Typography variantName="label">Add comment</Typography>
          <TextArea placeholder="Enter a comment" isMobile={isMobile} />
        </FieldWrapper>
      </div>
    </Dialog>
  );
};

export { SharePostDialog };
