import styled from "styled-components";
import { zIndex } from "../../utils/zIndex";

const monogramma = require("../../assets/monogramma.svg");

const Monogramma = styled.img.attrs({
  src: monogramma
})`
  position: fixed;
  width: 90px;
  top: 0px;
  right: -31px;
  z-index: ${zIndex.Monogramma};

  @media (max-width: 768px) {
    width: 65px;
    right: -22px;
    top: 3px;
  }
`;

export { Monogramma };
