import * as React from "react";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

import { Typography, ButtonIcon, Dialog, Button, DayPicker } from "@freeda/react-components";

import { Dialogable } from "../../../types/Dialogable";
import { DateRange } from "../../../types/DataRange";

interface Props {
  onApplyFilter: (dateRange: DateRange) => void;
  onChangeDate: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
  from: Date | null;
  to: Date | null;
}

const DateRangeFilterDialog: React.FC<Props & Dialogable> = ({
  open,
  onClose,
  onApplyFilter,
  onChangeDate,
  from,
  to
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const selectedMonthAndYear = from ? moment(from) : null;

  return (
    <Dialog
      openDialog={open}
      onClose={onClose}
      rootIdAttributes="root"
      fullScreen={isMobile}
      style={{
        paddingBottom: 25,
        paddingLeft: 25,
        paddingRight: 25,
        height: !isMobile ? 530 : ""
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 15
        }}
      >
        <Typography variantName="subtitle" textColor="#6B20DA">
          Customised data range
        </Typography>
        <ButtonIcon onClick={onClose} iconName="close" />
      </div>
      <DayPicker
        selectedStartDate={from ? moment(from) : null}
        selectedEndDate={to ? moment(to) : null}
        selectedMonth={selectedMonthAndYear}
        selectedYear={selectedMonthAndYear}
        onChangeDateRange={(startDate, endDate) => onChangeDate(startDate, endDate)}
      />
      <div
        style={{
          display: "flex",
          marginTop: 25,
          alignItems: "center",
          position: "absolute",
          bottom: 30,
          right: 23
        }}
      >
        <Button
          style={{ margin: 0 }}
          disabled={from === null && to === null}
          onClick={() => {
            onApplyFilter({ from, to });
            onClose();
          }}
        >
          Apply date filter
        </Button>
      </div>
    </Dialog>
  );
};

export { DateRangeFilterDialog };
