import * as React from "react";
import { ButtonIcon, Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  onClick: () => void;
  iconColor?: string;
  disabled?: boolean;
}

export const BackButton: React.FC<Props> = ({ iconColor, onClick, disabled }) => {
  return (
    <div onClick={() => !disabled && onClick()} style={{ display: "flex", alignItems: "center" }}>
      <ButtonIcon iconName="arrow-left" iconColor={iconColor || Colors.PURPLE} iconSize="small" disabled={disabled} />
      <Typography
        variantName="text"
        textColor={iconColor || Colors.PURPLE}
        style={{ cursor: "pointer", position: "relative", top: -1, fontWeight: 500 }}
        disabled={disabled}
      >
        Back
      </Typography>
    </div>
  );
};
