import * as React from "react";
import { ProvidersWrapper } from "./providers/ProvidersWrapper";
import { Sidebar } from "./components/layout/Sidebar";
import { Switch } from "react-router";
import { Route, RouteComponentProps, Redirect } from "react-router-dom";
import { ComponentsWrapper } from "./components/ui/ComponentsWrapper";
import { PostPage } from "./components/layout/PostDetails/PostPage";
import { Snackbar } from "./components/ui/Snackbar";
import { CommentPage } from "./components/layout/CommentsModerationProcess/CommentPage";
import { LoginPage } from "./components/pages/LoginPage";
import auth from "./utils/auth";
import PrivateRoute from "./components/PrivateRoute";
import { ModerationPage } from "./components/pages/ModerationPage";
import { CommentsListPage } from "./components/pages/CommentsListPage";
import { PostsListPage } from "./components/pages/PostsListPage";
import { HeaderLogoQuote } from "./components/ui/HeaderLogoQuote";

const App: React.FC = () => {
  return (
    <ProvidersWrapper>
      <Snackbar />
      <Switch>
        <Route
          exact
          path="/login"
          render={() => (auth.isAuthenticated() ? <Redirect to="/" /> : <LoginPage login={auth.login} />)}
        />
        <>
          <ComponentsWrapper>
            <HeaderLogoQuote />
            <PrivateRoute
              exact
              path="/"
              isAuthenticated={auth.isAuthenticated}
              Component={() => <CommentsListPage />}
            />
            <PrivateRoute
              exact
              path="/posts"
              isAuthenticated={auth.isAuthenticated}
              Component={() => <PostsListPage />}
            />
            <PrivateRoute
              exact
              path="/moderation"
              isAuthenticated={auth.isAuthenticated}
              Component={() => <ModerationPage />}
            />
          </ComponentsWrapper>
          <PrivateRoute
            path="/post/:publisherId"
            isAuthenticated={auth.isAuthenticated}
            Component={(props: RouteComponentProps<{ publisherId: string }>) => (
              <PostPage publisherId={props.match.params.publisherId} />
            )}
          />
          <PrivateRoute
            path="/comment/:publisherId"
            isAuthenticated={auth.isAuthenticated}
            Component={(props: RouteComponentProps<{ publisherId: string }>) => (
              <CommentPage publisherId={props.match.params.publisherId} />
            )}
          />
          <Sidebar />
        </>
      </Switch>
    </ProvidersWrapper>
  );
};

export default App;
