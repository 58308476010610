import { SocialContent } from "../types/SocialContent";
import { isFbContent, isFbGroupContent, isIgContent, buildSocialContentInfo } from "./buildSocialContentInfo";
const placeholderPost = require("../assets/post/placeholderPost.jpg");

export const getPostImage = (content: SocialContent): string | null => {
  const isFbPost = isFbContent(content);
  const isFbgPost = isFbGroupContent(content);
  const isIGpost = isIgContent(content);
  const isIgtvPost = isIGpost && content.type === "IGTV";

  const { mediaUrl } = buildSocialContentInfo(content);

  let imgUrl: string | null = null;
  if (isFbPost) {
    imgUrl = mediaUrl || placeholderPost;
  }

  if (isFbgPost) {
    imgUrl = mediaUrl;
  }

  if (isIGpost) {
    // imgUrl = isIgtvPost ? mediaUrl : `${permalink}media`;
    imgUrl = mediaUrl || placeholderPost;
  }

  return imgUrl;
};
