import * as React from "react";
import { PageWrapper, HeaderPage, LeftSection, RightSection } from "../sharedStyles";
import { PlatformSkeleton } from "./styles";
import { CommentsPlaceholder } from "./CommentsPlaceholder";
import { LabellingPlaceholder } from "./LabellingPlaceholder";
import { PostDetailsPlaceholder } from "./PostDetailsPlaceholder";

const ModerationPagePlaceholder: React.FC<{ itemNotFound?: boolean; headerWithFilter?: JSX.Element }> = ({
  itemNotFound,
  headerWithFilter,
}) => {
  return (
    <PageWrapper>
      {headerWithFilter ? (
        headerWithFilter
      ) : (
        <HeaderPage style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <PlatformSkeleton />
        </HeaderPage>
      )}
      <div style={{ height: "calc(100vh - 55px)", position: "relative", zIndex: -1 }}>
        <LeftSection style={{ justifyContent: "start" }}>
          <CommentsPlaceholder itemNotFound={itemNotFound} />
        </LeftSection>
        <RightSection>
          <LabellingPlaceholder />
          <PostDetailsPlaceholder />
        </RightSection>
      </div>
    </PageWrapper>
  );
};

export { ModerationPagePlaceholder };
