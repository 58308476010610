import * as React from "react";

const onArrowKey = (e: KeyboardEvent, onPrev: () => void, onNext: () => void) => {
  if (e.keyCode === 37) {
    onPrev();
  }

  if (e.keyCode === 39) {
    onNext();
  }
};

const useKeyArrow = (onPrev: () => void, onNext: () => void) => {
  React.useEffect(() => {
    const onClickArrow = (e: KeyboardEvent) => {
      onArrowKey(e, onPrev, onNext);
    };

    window.addEventListener("keydown", onClickArrow, true);

    return () => {
      window.removeEventListener("keydown", onClickArrow, true);
    };
  }, [onPrev, onNext]);
};

export { useKeyArrow };
