import * as React from "react";
import { SocialComment } from "../../../../types/SocialComment";
import { ListComments, WrapperListComments } from "../styles";
import { CommentBox } from "../../../ui/CommentBox";
import { ButtonStyled } from "../../../ui/ButtonStyled";
import { useHistory } from "react-router-dom";
import { PostsFilterContext } from "../../../../providers/PostsFilterProvider";
import { Platform } from "../../../../types/Platform";
import { CommentsChildrenPreview } from "./CommentsChildrenPreview";

interface Props {
  comments: Array<SocialComment>;
}

const PostCommentsPreview: React.FC<Props> = ({ comments }) => {
  const history = useHistory();

  const { onChangeSelectedCommentIdByPreview } = React.useContext(PostsFilterContext);

  const { publisherId, contentId, platformId } = comments[0].content.id;

  const openPostPanel = (publisherId: string, platformId: Platform, contentId: string) => {
    history.push(`post/${publisherId}?platformId=${platformId}&contentId=${contentId}`);
  };

  return (
    <WrapperListComments>
      <ListComments style={{ padding: "15px 0px 15px 20px" }}>
        {comments.map((comment) => {
          const childrenList: Array<SocialComment> = comment.children.items;

          return (
            <div
              key={comment.id.commentId}
              style={{ marginBottom: childrenList.length > 0 ? 25 : "", position: "relative", left: -20 }}
              id={comment.id.commentId}
            >
              <CommentBox
                commentType="default"
                comment={comment}
                loadingDelete={false}
                onModerationComment={(_) => {
                  openPostPanel(comment.id.publisherId, comment.id.platformId, comment.id.contentId);
                }}
                onSelectedComment={() => {
                  onChangeSelectedCommentIdByPreview(comment.id.commentId);
                  openPostPanel(comment.id.publisherId, comment.id.platformId, comment.id.contentId);
                }}
              />
              <CommentsChildrenPreview
                children={childrenList}
                parent={comment}
                onOpenPostPanel={(c) => {
                  onChangeSelectedCommentIdByPreview(c.id.commentId);
                  openPostPanel(c.id.publisherId, c.id.platformId, c.id.contentId);
                }}
              />
            </div>
          );
        })}
        <ButtonStyled
          style={{ margin: "0 auto", marginBottom: 25 }}
          onClick={() => {
            onChangeSelectedCommentIdByPreview(comments[comments.length - 1].id.commentId);
            openPostPanel(publisherId, platformId, contentId);
          }}
        >
          See more comments
        </ButtonStyled>
      </ListComments>
    </WrapperListComments>
  );
};
export { PostCommentsPreview };
