import * as React from "react";
import { getPlatformIcon } from "../../../utils/getPlatformIcon";
import { useLocation, useHistory } from "react-router-dom";
import { parse } from "query-string";
import { useQuery } from "@apollo/react-hooks";
import { Platform } from "../../../types/Platform";
import {
  HeaderPage,
  PlatformIcon,
  PageWrapper,
  WrapperSection,
  LeftSection,
  RightSection,
} from "../../ui/sharedStyles";
import { BackButton } from "../../ui/BackButton";
import { PostCommentsModeration } from "./PostCommentsModeration";
import {
  ListCommentsByContentQuery,
  ListCommentsByContentQueryVariables,
} from "../../../types/graphql/ListCommentsByContentQuery";
import { LIST_COMMENTS_BY_CONTENT } from "../../../apollo/queries/ListCommentsByContent";
import { getContentQueryByPlatform, getPlatformContentName } from "../../../apollo/utils/getContentQueryByPlatform";
import { SocialContent } from "../../../types/SocialContent";
import { PostsFilterContext } from "../../../providers/PostsFilterProvider";
import { CommentsPlaceholder } from "../../ui/Placeholder/CommentsPlaceholder";
import { ItemNotFound } from "../../ui/Placeholder/ItemNotFound";
import { getSnackbarErrorHandling } from "../../../utils/getSnackbarErrorHandling";
import { LIMIT_CHILDREN_LIST_COMMENTS, LIMIT_COMMENTS_PER_PAGE } from "../../../utils/limitVariablesQueries";
import { Roles } from "../../Permission";
import { usePreviousData } from "../../../hooks/usePreviousData";
import { LabellingPostDetailsPanel } from "../LabellingPostDetailsPanel";
import { SocialComment } from "../../../types/SocialComment";

interface Props {
  publisherId: string;
}

const PostPage: React.FC<Props> = ({ publisherId }) => {
  const roles = localStorage.getItem("roles") as Roles | null;

  const history = useHistory();
  const { search } = useLocation();
  const queryString = parse(search);
  const isShareLink = history.action === "POP";

  const platformId = queryString.platformId as Platform;
  const contentId = queryString.contentId as string;

  const { onChangeSelectedCommentIdByPreview } = React.useContext(PostsFilterContext);

  const [loadingFetchComments, setLoadingFetchComments] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [selectedComment, setSelectedComment] = React.useState<SocialComment | null>();

  const contentResult = useQuery<{ [getContent: string]: SocialContent }>(getContentQueryByPlatform(platformId), {
    variables: {
      id: {
        platformId,
        publisherId,
        contentId,
      },
      offsetComments: 0,
      limitComments: 1,
    },
    fetchPolicy: "network-only",
  });

  const listCommentsResult = useQuery<ListCommentsByContentQuery, ListCommentsByContentQueryVariables>(
    LIST_COMMENTS_BY_CONTENT(platformId),
    {
      variables: {
        contentId: {
          platformId,
          publisherId,
          contentId,
        },
        offset: 0,
        limit: LIMIT_COMMENTS_PER_PAGE,
        order: "DESC",
        topLevelOnly: true,
        offsetChildren: 0,
        limitChildren: LIMIT_CHILDREN_LIST_COMMENTS,
      },
      fetchPolicy: "network-only",
    }
  );

  const comments = listCommentsResult.data ? listCommentsResult.data.listCommentsByContent.items : [];
  const totalCount = listCommentsResult.data ? listCommentsResult.data.listCommentsByContent.pageInfo.totalCount : 0;
  const totalPages = Math.ceil(totalCount / LIMIT_COMMENTS_PER_PAGE);

  const content =
    !contentResult.loading && contentResult.data ? contentResult.data[getPlatformContentName(platformId)] : null;

  const writerRole = !(roles === Roles.MODERATOR || roles === Roles.LABELER);

  const prevCurrentPage = usePreviousData(currentPage);

  React.useEffect(() => {
    if (currentPage !== prevCurrentPage) {
      const fetchMoreComments = async () => {
        try {
          setLoadingFetchComments(true);
          await listCommentsResult.fetchMore({
            variables: {
              offset: currentPage * LIMIT_COMMENTS_PER_PAGE,
              limit: LIMIT_COMMENTS_PER_PAGE,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev;
              }
              const newCommentsList = Object.assign({}, prev, {
                listCommentsByContent: {
                  ...fetchMoreResult.listCommentsByContent,
                  items: [...prev.listCommentsByContent.items, ...fetchMoreResult.listCommentsByContent.items],
                },
              });
              return newCommentsList;
            },
          });
        } catch (err) {
          console.log(err);
        }
        setLoadingFetchComments(false);
      };

      fetchMoreComments();
    }
  }, [currentPage, prevCurrentPage, listCommentsResult]);

  const disabledFetchMoreButton = currentPage === totalPages - 1 || totalPages === 0;

  const commentsIsAvailable = !listCommentsResult.loading && comments.length > 0;

  return (
    <PageWrapper>
      <HeaderPage>
        {isShareLink ? (
          <div />
        ) : (
          <BackButton
            onClick={() => {
              onChangeSelectedCommentIdByPreview(null);
              history.push("/posts");
            }}
          />
        )}
        <PlatformIcon src={getPlatformIcon(platformId)} alt="" style={{ justifySelf: "center" }} />
      </HeaderPage>
      <WrapperSection>
        <LeftSection style={{ justifyContent: writerRole ? "" : "start" }}>
          {getSnackbarErrorHandling(listCommentsResult)}
          {listCommentsResult.loading && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CommentsPlaceholder />
            </div>
          )}
          {!listCommentsResult.loading && comments.length === 0 && (
            <ItemNotFound context="comments" styles={{ marginTop: 30 }} />
          )}
          {commentsIsAvailable && (
            <PostCommentsModeration
              comments={comments}
              selectedComment={selectedComment || comments[0]}
              onChangeSelectedComment={setSelectedComment}
              disabledNextButton={disabledFetchMoreButton}
              loadingFetchComments={loadingFetchComments}
              commentsPerPage={LIMIT_COMMENTS_PER_PAGE}
              onFetchMoreNextComments={() =>
                setCurrentPage((pageNumber) => (pageNumber === totalPages - 1 ? pageNumber : pageNumber + 1))
              }
            />
          )}
        </LeftSection>
        {commentsIsAvailable && content && (
          <RightSection>
            <LabellingPostDetailsPanel
              selectedCommentId={selectedComment ? selectedComment.id : comments[0].id}
              post={content}
            />
          </RightSection>
        )}
      </WrapperSection>
    </PageWrapper>
  );
};

export { PostPage };
