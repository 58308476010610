import gql from "graphql-tag";
import { COMMENT_ID_FRAGMENT } from "../commentIdFragment";
import { FACEBOOK_CONTENT } from "../content/facebookContent";

const FACEBOOK_COMMENT = gql`
  fragment facebookComment on FacebookComment {
    id {
      ...commentId
    }
    createdTime
    text
    removed
    user_hidden
    user_deleted
    reactions {
      love
      ahah
      like
      sad
      angry
      wow
    }
    labels {
      project
      label
    }
    content {
      ...facebookContent
    }
  }
  ${COMMENT_ID_FRAGMENT}
  ${FACEBOOK_CONTENT}
`;

export { FACEBOOK_COMMENT };
