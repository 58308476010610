import * as React from "react";
import { FilterWrapper } from "../styles";
import { Select, ButtonIcon } from "@freeda/react-components";
import { useMediaQuery } from "react-responsive";
import { CommentsFilterContext } from "../../../../providers/CommentsFilterProvider";
import { SelectWithPublishers } from "../SelectWithPublishers";
import { FieldWrapper } from "../../../ui/sharedStyles";
import { Platform, buildPlatformName } from "../../../../types/Platform";
import { useFetchPublishers } from "../../../../hooks/useFetchPublishers";
import { Colors } from "@freeda/react-components/lib/theme";

const CommentsAdvancedFilter: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { publishers } = useFetchPublishers();

  const {
    publisherId,
    onChangePublisherId,
    platform,
    onChangePlatform,
    classified,
    handleClassify,
    onClearFilter,
  } = React.useContext(CommentsFilterContext);

  React.useEffect(() => {
    const availablePublishers = publishers.filter((p) => p.id.platformId === platform);

    if (availablePublishers.length > 0) {
      onChangePublisherId(availablePublishers[0].id);
    }
  }, [publishers, platform]);

  const platformOptions = Object.values(Platform).map((p) => ({
    label: buildPlatformName(p),
    value: p,
  }));

  const selectedPlatformOption = platformOptions.find((p) => p.value === platform);

  const commentStatusOptions = [
    { value: null, label: "All" },
    { value: true, label: "Classified" },
    { value: false, label: "Unclassified" },
  ];

  const selectedStatusOption = commentStatusOptions.find((cS) => cS.value === classified);

  return (
    <FilterWrapper>
      <FieldWrapper basis={!isMobile ? "190px" : ""} withoutMarginRight>
        <Select
          options={platformOptions}
          onChange={(option: any) => {
            onChangePlatform(option ? option.value : null);
          }}
          value={selectedPlatformOption}
          placeholder="Select platform"
          label="Platform"
          isClearable={false}
        />
      </FieldWrapper>
      <FieldWrapper basis={!isMobile ? "190px" : ""} withoutMarginRight>
        <SelectWithPublishers publisherId={publisherId} onChangePublisherId={onChangePublisherId} platform={platform} />
      </FieldWrapper>
      <FieldWrapper basis={!isMobile ? "190px" : ""} withoutMarginRight>
        <Select
          options={commentStatusOptions}
          onChange={(option: any) => handleClassify(option.value)}
          value={selectedStatusOption}
          placeholder="Select status"
          label="Comments status"
          isClearable={false}
        />
      </FieldWrapper>
      {!isMobile && (
        <ButtonIcon
          iconName="delete"
          onClick={onClearFilter}
          style={{ position: "absolute", top: 5, right: 5 }}
          iconColor={Colors.PURPLE}
        />
      )}
    </FilterWrapper>
  );
};

export { CommentsAdvancedFilter };
