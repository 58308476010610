import * as React from "react";
import { Location } from "history";
import { useLocation } from "react-router-dom";

type LocationContext = { updatedLocation: Location | null };

const LocationContext = React.createContext<LocationContext>({} as LocationContext);

const LocationProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [updatedLocation, setLocation] = React.useState<Location | null>(location);

  React.useEffect(() => {
    setLocation(location);
  }, [location]);

  return <LocationContext.Provider value={{ updatedLocation }}>{children}</LocationContext.Provider>;
};

export { LocationProvider, LocationContext };
