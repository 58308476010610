import * as React from "react";
import { Tooltip, Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { omit, orderBy } from "lodash";
import { SocialReactions, AllReactions } from "../../types/SocialReactions";
import styled from "styled-components";
import { Platform } from "../../types/Platform";

const likeFullIg = require("./../../assets/reactions/like-full-ig.svg");

const love = require("./../../assets/reactions/love.svg");
const like = require("./../../assets/reactions/like.svg");
const wow = require("./../../assets/reactions/wow.svg");
const sad = require("./../../assets/reactions/sad.svg");
const angry = require("./../../assets/reactions/angry.svg");
const ahah = require("./../../assets/reactions/ahah.svg");

const getReactionIcon = (reactionName: string) => {
  const reactions = {
    [AllReactions.love]: love,
    [AllReactions.ahah]: ahah,
    [AllReactions.like]: like,
    [AllReactions.sad]: sad,
    [AllReactions.angry]: angry,
    [AllReactions.wow]: wow
  };

  return reactions[reactionName];
};

export const buildReactions = (
  reactions: Omit<SocialReactions, "__typename"> | { like: number }
): Array<{ reaction: string; value: number }> => {
  if (reactions) {
    const realReactions = omit(reactions, "__typename");
    const keys = Object.keys(realReactions);
    const values = Object.values(realReactions);

    return keys.map((k, i) => ({ reaction: k, value: values[i] })).filter(r => r.value > 0);
  }
  return [];
};

const WrapperReaction = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface Props {
  reactions: SocialReactions | { like: number };
  platform: Platform;
}

const ReactionsTooltip: React.FC<Props> = ({ reactions, platform }) => {
  const existReactions = buildReactions(reactions);

  const totalReactions = existReactions.reduce((acc, next) => {
    return acc + next.value;
  }, 0);

  const orderedFbReactions = orderBy(existReactions, "value", "desc");

  const majorFbReactions = orderedFbReactions.slice(0, 2);

  const isIGpost = platform === Platform.IG;

  if (isIGpost && totalReactions > 0) {
    return (
      <div style={{ display: "flex", alignItems: "center", padding: "2px 6px" }}>
        <img src={likeFullIg} style={{ height: 12 }} alt="" />
        <Typography variantName="text" style={{ color: Colors.GRIGINO, marginLeft: 3, fontWeight: 500, fontSize: 12 }}>
          {String(totalReactions)}
        </Typography>
      </div>
    );
  }

  if (!isIGpost && existReactions.length > 0) {
    return (
      <Tooltip
        referenceChildren={
          <div style={{ display: "flex", alignItems: "center" }}>
            {majorFbReactions.map((r, i) => (
              <img
                key={i}
                src={getReactionIcon(r.reaction)}
                style={{
                  height: 16,
                  position: "relative",
                  zIndex: i === 0 ? 1 : 0,
                  left: i === 0 ? 4 : -3,
                  marginRight: majorFbReactions.length === 1 ? 7 : i === 0 ? 3 : 0
                }}
                alt=""
              />
            ))}
            <Typography
              variantName="text"
              style={{
                color: Colors.GRIGINO,
                transition: "color 0.3s",
                fontWeight: 500,
                fontSize: 12,
                paddingLeft: 2,
                paddingRight: 6
              }}
            >
              {String(totalReactions)}
            </Typography>
          </div>
        }
        tooltipChildren={
          <WrapperReaction>
            {orderedFbReactions.map((r, i) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }} key={i}>
                  <img src={getReactionIcon(r.reaction)} style={{ height: 22 }} alt="" />
                  <Typography variantName="text" style={{ color: Colors.GRIGIO, marginLeft: 8, fontWeight: 500 }}>
                    {String(r.value)}
                  </Typography>
                </div>
              );
            })}
          </WrapperReaction>
        }
        tooltipStyle={{ background: Colors.WHITE, boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px" }}
        position="right"
      />
    );
  }
  return null;
};

export { ReactionsTooltip };
