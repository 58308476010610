import * as React from "react";
import { Drawer, Typography, ListItemText, DividerSection, ButtonIcon, List, Icon } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { emojiList } from "./emoji";
import { CSSTransition } from "react-transition-group";
import { SidebarListItem, WrapperLabel, FirstPart } from "./styles";
import { zIndex } from "../../../utils/zIndex";
import { LogoutButton } from "../../ui/LogoutButton";
import { RouterLink } from "../../ui/RouterLink";
import { getLabelColor } from ".";
import { useLocation } from "react-router-dom";

interface Props {
  open: boolean;
  onChangeOpen: (open: boolean) => void;
  indexEmojiList: number;
}

const SidebarDesktop: React.FC<Props> = ({ open, onChangeOpen, indexEmojiList }) => {
  const { pathname } = useLocation();
  const isModerationClicked = pathname === "/";
  const isPostClicked = pathname === "/posts";

  return (
    <Drawer
      open={open}
      changeOpen={open => onChangeOpen(open)}
      closeWidth={72}
      openWidth={240}
      style={{
        position: "fixed",
        zIndex: zIndex.Sidebar,
        top: 0,
        flex: "1 0 auto",
        left: 0,
        right: "auto"
      }}
    >
      <CSSTransition in={open} timeout={{ enter: 200, exit: 500 }}>
        {status => (
          <>
            <FirstPart>
              <SidebarListItem
                backgroundHover={Colors.PURPLE}
                open={open}
                style={{ height: 80, marginTop: 25, userSelect: "none" }}
              >
                <Typography variantName="title" style={{ fontSize: 30 }}>
                  {emojiList[indexEmojiList]}
                </Typography>
                {open && (
                  <WrapperLabel className={`wrapper-${status}`}>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            variantName="text"
                            textColor={Colors.DARKpurple}
                            style={{ paddingBottom: 2, wordBreak: "break-word" }}
                          >
                            Welcome,
                          </Typography>
                          <Typography
                            variantName="text"
                            textColor={Colors.DARKpurple}
                            style={{
                              marginRight: 15,
                              fontSize: 16,
                              fontWeight: 600,
                              paddingTop: 2,
                              wordBreak: "break-word"
                            }}
                          >
                            {localStorage.getItem("name")!}
                          </Typography>
                        </>
                      }
                    />
                  </WrapperLabel>
                )}
              </SidebarListItem>
              <SidebarListItem open={open} style={{ marginBottom: 10 }}>
                <LogoutButton>
                  <ButtonIcon
                    iconName="logout"
                    iconColor={Colors.DARKpurple}
                    style={{ cursor: "pointer", margin: 0, padding: 0 }}
                  />
                  {open && (
                    <WrapperLabel className={`wrapper-${status}`}>
                      <ListItemText
                        primary={
                          <Typography variantName="text" textColor={Colors.DARKpurple} style={{ cursor: "pointer" }}>
                            Logout
                          </Typography>
                        }
                      />
                    </WrapperLabel>
                  )}
                </LogoutButton>
              </SidebarListItem>
              <DividerSection dividerColor={Colors.WHITE} style={{ width: "calc(100% - 30px)" }} />
            </FirstPart>
            <List>
              <RouterLink to="/">
                <SidebarListItem open={open} style={{ zIndex: 1 }}>
                  <Icon
                    iconName="sidebar-moderation"
                    style={{ fontSize: 38 }}
                    iconColor={getLabelColor(isModerationClicked)}
                  />
                  {open && (
                    <WrapperLabel className={`wrapper-${status}`}>
                      <ListItemText
                        primary={
                          <Typography
                            variantName="text"
                            style={{ cursor: "pointer" }}
                            textColor={getLabelColor(isModerationClicked)}
                          >
                            Comments moderation
                          </Typography>
                        }
                      />
                    </WrapperLabel>
                  )}
                </SidebarListItem>
              </RouterLink>
              <RouterLink to="/posts">
                <SidebarListItem open={open} style={{ zIndex: 1 }}>
                  <Icon iconName="sidebar-posts" style={{ fontSize: 38 }} iconColor={getLabelColor(isPostClicked)} />
                  {open && (
                    <WrapperLabel className={`wrapper-${status}`}>
                      <ListItemText
                        primary={
                          <Typography
                            variantName="text"
                            style={{ cursor: "pointer" }}
                            textColor={getLabelColor(isPostClicked)}
                          >
                            Posts moderation
                          </Typography>
                        }
                      />
                    </WrapperLabel>
                  )}
                </SidebarListItem>
              </RouterLink>
            </List>
          </>
        )}
      </CSSTransition>
    </Drawer>
  );
};

export { SidebarDesktop };
