import * as React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Monogramma } from "../ui/Monogramma";
import { useHistory } from "react-router-dom";
import { Typography, Input, Button, ButtonIcon } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

const freedaLogo = require("../../assets/freeda_logo.png");
const matilde = require("../../assets/matilde.png");
const messagesGallery = require("../../assets/messages.png");

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #6b2ed6;
`;

const FreedaLogo = styled.img`
  position: absolute;
  top: 20px;
  width: 176px;
  z-index: 100;
  right: 0px;

  @media (max-width: 768px) {
    width: 230px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const WrapperLoginBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #ffffff;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

const LoginBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: -webkit-center;
  z-index: 5;
`;

const Messages = styled.img`
  position: fixed;
  height: 100%;
  -webkit-transition: height 0.6s ease;
  transition: height 0.6s ease;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LoginPage: React.FC<{ login: Function }> = ({ login }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const history = useHistory();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [showPassword, setShowPassword] = React.useState<boolean | null>(false);

  const [error, setError] = React.useState<boolean>(false);

  return (
    <PageWrapper>
      {isMobile ? <Monogramma /> : <FreedaLogo src={freedaLogo} />}
      <Messages src={messagesGallery} />
      <Wrapper>
        <WrapperLoginBox>
          <img style={{ position: "absolute", top: -55, width: 110, zIndex: 1 }} src={matilde} alt="Matilde" />
          <LoginBox
            onSubmit={async (e) => {
              e.preventDefault();
              if (showPassword === null) {
                setLoading(true);
                const result = await login(username, password);
                setLoading(false);

                if (result.result === "OK") {
                  history.push("/");
                } else {
                  setError(true);
                }
              }
            }}
          >
            <Typography variantName="title">Welcome!</Typography>
            <Typography
              variantName="text"
              textColor={Colors.GRIGINO}
              style={{ margin: "20px 0px 30px 0px", lineHeight: "19px" }}
            >
              New Freeda's tool designed to moderate and label social comment
            </Typography>
            <Input
              style={{ marginBottom: 35 }}
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: 35 }}>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                isValid={!error}
                errorMessage="Wrong username or password."
                onChange={(e) => setPassword(e.target.value)}
              />
              <ButtonIcon
                iconName={showPassword ? "hidden" : "show"}
                iconSize="small"
                onClick={() => setShowPassword((show) => !show)}
                style={{ position: "absolute", right: 50 }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Button loading={loading} type="submit" onClick={() => setShowPassword(null)}>
                Sign in
              </Button>
            </div>
          </LoginBox>
        </WrapperLoginBox>
      </Wrapper>
    </PageWrapper>
  );
};

export { LoginPage };
