import * as React from "react";
import { WrapperList } from "../../layout/CommentCard/styles";
import styled from "styled-components";
import { SkeletonBox, Label } from "./styles";

const WrapperCard = styled(SkeletonBox)`
  padding: 30px;
  padding-left: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  height: 110px;
  width: 100%;
  transition: background 0.5s;
  cursor: pointer;
`;

export const ListCardsPlaceholder: React.FC = () => {
  const lists = Array(15).fill("");

  return (
    <WrapperList>
      {lists.map((l, i) => (
        <WrapperCard key={i}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label style={{ width: 150 }} />
            <Label style={{ width: 300, marginBottom: 0 }} />
          </div>
        </WrapperCard>
      ))}
    </WrapperList>
  );
};
