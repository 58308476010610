import * as React from "react";
import { LateralLabellingPanel } from "./LabellingPanel";
import { CommentId } from "../../../types/CommentId";
import { PostDetails } from "./PostDetails";
import { Permission, Roles } from "../../Permission";
import { SocialContent } from "../../../types/SocialContent";
import { v4 } from "uuid";

interface Props {
  selectedCommentId: CommentId;
  post: SocialContent;
}

const LabellingPostDetailsPanel: React.FC<Props> = ({ selectedCommentId, post }) => {
  const wrapperId = React.useRef<string>(v4());

  const [openPostPanel, setOpenPostPanel] = React.useState<boolean>(false);

  return (
    <>
      <Permission roles={[Roles.LABELER, Roles.MODERATOR]}>
        <LateralLabellingPanel selectedCommentId={selectedCommentId} openPostPanel={openPostPanel} />
      </Permission>
      <PostDetails post={post} wrapperId={wrapperId.current} handleOpen={setOpenPostPanel} isOpen={openPostPanel} />
    </>
  );
};

export { LabellingPostDetailsPanel };
