import gql from "graphql-tag";

const LIST_PUBLISHERS = gql`
  query listPublishers($country: Country!, $offset: Int!, $limit: Int!) {
    listPublishers(country: $country, offset: $offset, limit: $limit) {
      items {
        id {
          platformId
          publisherId
        }
        description
        country
      }
    }
  }
`;

export { LIST_PUBLISHERS };
