import * as React from "react";
import styled from "styled-components";
import { PostSkeleton, SkeletonLabel } from "./styles";
import { DividerSection } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: calc(100% - 100px);
  align-self: flex-start;
  margin-top: 5px;
`;

const TopPostDetailsPlaceholder: React.FC<{ styles?: React.CSSProperties }> = ({ styles }) => {
  return (
    <div style={{ ...styles }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <PostSkeleton />
        <WrapperText>
          <SkeletonLabel style={{ width: "60%" }} />
          <SkeletonLabel />
        </WrapperText>
      </div>
      <DividerSection style={{ margin: "15px 0px", width: "100%" }} dividerColor={Colors.GRIGINOtransparentLight} />
    </div>
  );
};

export { TopPostDetailsPlaceholder };
