import moment from "moment";
import { FixedDateRange } from "../types/FixedDateRange";
import { DateRange } from "../types/DataRange";

moment.defaultFormat = "YYYY-MM-DDTHH:mm:ss";

export const getDateRange = (fixedDateRange: FixedDateRange): { from: string; to: string } => {
  switch (fixedDateRange) {
    case FixedDateRange.LAST_WEEK:
      return {
        from: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format(),
        to: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format()
      };
    case FixedDateRange.LAST_MONTH:
      return {
        from: moment()
          .subtract(1, "months")
          .startOf("month")
          .format(),
        to: moment()
          .subtract(1, "months")
          .endOf("month")
          .format()
      };
    case FixedDateRange.LAST_28_DAYS:
      return {
        from: moment()
          .subtract(28, "days")
          .startOf("day")
          .format(),
        to: moment()
          .endOf("day")
          .format()
      };
    case FixedDateRange.LAST_YEAR:
      return {
        from: moment()
          .subtract(1, "years")
          .startOf("day")
          .format(),
        to: moment()
          .endOf("day")
          .format()
      };
    case FixedDateRange.LAST_24H:
    default:
      return {
        from: moment()
          .subtract(1, "days")
          .startOf("day")
          .format(),
        to: moment()
          .endOf("day")
          .format()
      };
  }
};

export const getCustomDateRange = (dateRange: DateRange): { since: string | null; until: string | null } => {
  let since: string | null = null;
  let until: string | null = null;
  if (dateRange) {
    if (dateRange !== FixedDateRange.ANY_TIME) {
      if (typeof dateRange === "object") {
        since = dateRange.from
          ? moment(dateRange.from)
              .startOf("day")
              .format()
          : null;
        until = dateRange.to
          ? moment(dateRange.to)
              .endOf("day")
              .format()
          : null;
      } else {
        const range = getDateRange(dateRange);
        since = range.from;
        until = range.to;
      }
    }
  }

  return { since, until };
};
