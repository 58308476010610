import { ModerationAction } from "./types/ModerationAction";

const copyPathSuccess = "Great, link copied correctly! 👯";

const hideCommentSuccess = "Comment hidden correctly! 👻";
const hideCommentError = "Oops, something went wrong when try to hide comment! 😢";

const unhideCommentSuccess = "Comment unhidden correctly! 👀";
const unhideCommentError = "Oops, something went wrong when try to unhide comment! 😢";

const removeCommentSuccess = "Comment removed correctly 🗑";
const removeCommentError = "Oops, something went wrong when try to removed comment! 🤔";

const networkStatus8 =
  "Please verify that Freeda VPN is connected and then refresh page!\n No request is in flight for this query, but one or more errors were detected.";

const labellingCommentSuccess = "The labels have been assigned correctly 🔝";
const labellingCommentError = "Oops, something went wrong when try to assign labels!";

const getCorrectModerationMessages = (action: ModerationAction) => {
  const allMessages = {
    [ModerationAction.DELETE]: {
      success: removeCommentSuccess,
      error: removeCommentError,
    },
    [ModerationAction.HIDE]: {
      success: hideCommentSuccess,
      error: hideCommentError,
    },
    [ModerationAction.UNHIDE]: {
      success: unhideCommentSuccess,
      error: unhideCommentError,
    },
  };

  return allMessages[action];
};

export {
  copyPathSuccess,
  hideCommentSuccess,
  hideCommentError,
  unhideCommentSuccess,
  unhideCommentError,
  removeCommentSuccess,
  removeCommentError,
  networkStatus8,
  getCorrectModerationMessages,
  labellingCommentSuccess,
  labellingCommentError,
};
