import * as React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { getPlatformIcon } from "../../../utils/getPlatformIcon";
import { Dialog } from "./Dialog";
import { Button, Typography, DividerSection, Select } from "@freeda/react-components";
import { scrollbarVertical } from "../../ui/ScrollbarStyles";
import { PlatformIcon, FieldWrapper, PostImg } from "../../ui/sharedStyles";
import { Dialogable } from "../../../types/Dialogable";
import { Colors } from "@freeda/react-components/lib/theme";
import { SocialComment } from "../../../types/SocialComment";
import { buildSocialCommentInfo } from "../../../utils/buildSocialCommentInfo";
import { buildSocialContentInfo, isFbGroupContent } from "../../../utils/buildSocialContentInfo";
import { WrapperText } from "../CommentCard/styles";
import { ButtonCopyPath } from "../../ui/ButtonCopyPath";
import { getPostImage } from "../../../utils/getPostImage";
import { generatePathLinkComment } from "../../../utils/generatePathLinkComment";

const placeholderPost = require("../../../assets/post/placeholderPost.jpg");

const TextArea = styled.textarea<{ isMobile: boolean }>`
  width: 100%;
  margin-top: 7px;
  resize: none;
  padding: 8px;
  color: ${(props) => props.theme.primary};
  height: ${(props) => (props.isMobile ? "100%" : "100px")};
  border: 1px solid ${(props) => props.theme.secondary};

  ${scrollbarVertical};

  &::placeholder {
    font-size: 12px;
    color: ${(props) => props.theme.secondary};
    white-space: nowrap;
  }
`;

type Props = Dialogable & {
  comments: Array<SocialComment>;
};

const ShareCommentDialog: React.FC<Props> = ({ open, onClose, comments }) => {
  const isMultiShare = comments.length > 1;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { text, content, labels, commentId } = buildSocialCommentInfo(comments[0]);
  const { title, caption, messageFbGroup, id } = buildSocialContentInfo(content);

  const postTitle = title ? `${title}` : "";
  const postCaption = caption ? `${caption}` : "";

  const isFBGpost = isFbGroupContent(content);

  const imagePost = getPostImage(content);

  const commentIsClassified = Boolean(labels && labels.length > 0);

  const textStyles = !commentIsClassified ? { fontWeight: 500 } : {};

  const sharePath = generatePathLinkComment(commentId);

  const titleDialog = `Share comment${isMultiShare ? "s" : ""}`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={titleDialog}
      buttonSection={
        <>
          {!isMultiShare && <ButtonCopyPath path={sharePath} onClose={onClose} />}
          <Button
            onClick={() => {
              console.log("share post!");
            }}
          >
            Share
          </Button>
        </>
      }
    >
      {!isMultiShare && (
        <>
          <div style={{ display: "flex", width: "100%" }}>
            {isFBGpost && imagePost && (
              <PostImg
                src={imagePost}
                size="medium"
                style={{ marginLeft: 0 }}
                onError={(e) => (e.currentTarget.src = placeholderPost)}
              />
            )}
            {!isFBGpost && <PostImg src={imagePost || placeholderPost} size="medium" style={{ marginLeft: 0 }} />}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <PlatformIcon src={getPlatformIcon(id.platformId)} alt="" />
              <WrapperText style={{ display: "grid", marginTop: 8 }}>
                <Typography variantName="text" style={{ fontSize: 12, marginBottom: 8, ...textStyles }}>
                  <>{isFBGpost ? messageFbGroup : `${postTitle} - ${postCaption}`}</>
                </Typography>
                <Typography variantName="text" style={{ fontSize: 12, ...textStyles }} textColor={Colors.GRIGINO}>
                  {text}
                </Typography>
              </WrapperText>
            </div>
          </div>
          <DividerSection style={{ width: "100%", margin: "15px 0px" }} />
        </>
      )}
      <div style={{ width: "100%", height: "100%" }}>
        <FieldWrapper basis="100%" withoutMarginRight={true} style={{ marginBottom: 20 }}>
          <Select
            options={[
              { value: "all", label: "All" },
              { value: "categorized", label: "Categorized" },
              { value: "uncategorized", label: "Uncategorized" },
            ]}
            onChange={() => console.log("change")}
            value={null}
            placeholder="Select a user"
            label="Share with"
          />
        </FieldWrapper>
        <FieldWrapper basis="100%" withoutMarginRight={true}>
          <Typography variantName="label">Add comment</Typography>
          <TextArea placeholder="Enter a comment" isMobile={isMobile} />
        </FieldWrapper>
      </div>
    </Dialog>
  );
};

export { ShareCommentDialog };
