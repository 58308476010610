import gql from "graphql-tag";
import { FACEBOOK_GROUP_COMMENT } from "../fragments/comment/facebookGroupComment";
import { FACEBOOK_GROUP_CONTENT } from "../fragments/content/facebookGroupContent";

const GET_FACEBOOK_GROUP_CONTENT = gql`
  query getFacebookGroupContent($id: ContentIdInput!, $offsetComments: Int!, $limitComments: Int!) {
    getFacebookGroupContent(id: $id) {
      ...facebookGroupContent
      comments(offset: $offsetComments, limit: $limitComments, order: DESC) {
        items {
          ...facebookGroupComment
        }
        pageInfo {
          totalCount
        }
      }
    }
  }
  ${FACEBOOK_GROUP_COMMENT}
  ${FACEBOOK_GROUP_CONTENT}
`;

export { GET_FACEBOOK_GROUP_CONTENT };
