export type SocialReactions = {
  love: number;
  ahah: number;
  like: number;
  sad: number;
  angry: number;
  wow: number;
  __typename: string;
};

export enum AllReactions {
  love = "love",
  ahah = "ahah",
  like = "like",
  sad = "sad",
  angry = "angry",
  wow = "wow"
}
