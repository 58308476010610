import * as React from "react";
import { WrapperListFilter, WrapperActions } from "./styles";
import { CheckboxMultiSelect } from "../../ui/CheckboxMultiSelect";
import { Colors } from "@freeda/react-components/lib/theme";
import { SelectCommentsCard } from "../SelectCommentsCard";
import { ButtonIcon, Button } from "@freeda/react-components";
import { HorizDivider } from "../../ui/sharedStyles";
import { useMediaQuery } from "react-responsive";
import { Permission, Roles } from "../../Permission";

interface Props {
  onClickCheck: () => void;
  onSelectClassifiedComments: (categorized: boolean | null) => void;
  checked: boolean;
  selectAll: boolean;
  onOpenShareDialog: () => void;
  onOpenModerationProcess: () => void;
  loadingFetchComments: boolean;
  onOpenLink: () => void;
}

const CommentsListFilter: React.FC<Props> = ({
  onClickCheck,
  checked,
  selectAll,
  onSelectClassifiedComments,
  onOpenShareDialog,
  onOpenModerationProcess,
  loadingFetchComments,
  onOpenLink,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <WrapperListFilter>
      <WrapperActions>
        <CheckboxMultiSelect
          checked={checked}
          onClick={onClickCheck}
          selectAll={selectAll}
          disabled={loadingFetchComments}
        />
        <SelectCommentsCard onSelectClassifiedComments={onSelectClassifiedComments} disabled={loadingFetchComments} />
        {checked && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <HorizDivider style={{ margin: "0px 10px" }} />
            <ButtonIcon
              iconName="open-link"
              style={{ marginRight: 5 }}
              iconColor={Colors.GRIGINO}
              onClick={onOpenLink}
            />
          </div>
        )}
      </WrapperActions>
      <Permission roles={[Roles.LABELER, Roles.MODERATOR]}>
        {!isMobile && (
          <Button
            icon={{ iconName: "comments", position: "right" }}
            onClick={onOpenModerationProcess}
            disabled={loadingFetchComments}
          >
            Start categorize
          </Button>
        )}
      </Permission>
    </WrapperListFilter>
  );
};

export { CommentsListFilter };
