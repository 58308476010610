import gql from "graphql-tag";
import { Platform } from "../../types/Platform";
import { SOCIAL_COMMENT_LIST } from "../fragments/socialCommentListFragment";

const LIST_COMMENTS_BY_CONTENT = (platform: Platform) => gql`
  query listCommentsByContent(
    $contentId: ContentIdInput!
    $offset: Int!
    $limit: Int!
    $order: Order!
    $topLevelOnly: Boolean
    $classified: Boolean
    $offsetChildren: Int!
    $limitChildren: Int!
  ) {
    listCommentsByContent(
      contentId: $contentId
      offset: $offset
      limit: $limit
      order: $order
      topLevelOnly: $topLevelOnly
      classified: $classified
    ) {
      items {
        ...socialComment
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${SOCIAL_COMMENT_LIST(platform)}
`;

export { LIST_COMMENTS_BY_CONTENT };
